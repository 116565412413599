/**
 * Dropdown component renders a customizable dropdown menu.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.selectedOption - The currently selected option.
 * @param {function} props.handleChange - The function to handle the change event.
 * @param {Array} props.options - The array of options to display in the dropdown.
 * @param {string} props.name - The label for the dropdown.
 * @param {string} props.className - Additional class names for the select element.
 *
 * @example
 * // Example usage of SearchableDropdown component
 
   import SearchableDropdown from './searchableDropdown'
    const states = [
            'Delhi',
            'Andhra Pradesh',
            'Arunachal Pradesh',
            'Assam',
            'Bihar',
            'Chhattisgarh',
        ];

    const [selectedState, setSelectedState] = useState(null);

    <SearchableDropdown
            options = {states? states : []} 
            selectedOption = {selectedState}
            noOptionsAvailable = "No state available"
            onChange = {(e) => {
                setSelectedState(e.value)  //note here we have to give e.value instead of e.target.value
            }}
            name = "State"
            placeholder = "Select State"
            isDisabled = {false}
        />
 */


import React from 'react'
import Select from 'react-select'

export default function SearchableDropdown({ className ,selectedOption, noOptionsAvailable,onChange, options, name ,placeholder,isDisabled}) {


    const allOptions = options.map((option) => {
        return { value: option, label: option }
    })

    const selectedOptionForDropdown = selectedOption ? { value: selectedOption, label: selectedOption } : null

    return (
        <>
            <div className= {`d-flex flex-column`} >
                <p style={{ marginBottom: '3px', marginTop: '15px' }}>{name}</p>
                <Select
                    className = {className}
                    style={{height: '20px'}}
                    options={allOptions}
                    value={selectedOptionForDropdown}
                    placeholder={placeholder}
                    onChange={onChange}
                    noOptionsMessage={() => noOptionsAvailable}
                    isDisabled={isDisabled}
                />
            </div>
        </>
    )
}


