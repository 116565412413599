import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Table } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import Webcam from "react-webcam";
import Loader from "../Reusable_Components/loader";
import { useDispatch, useSelector } from 'react-redux';
import { IoMdInformationCircleOutline } from "react-icons/io";
import ImageUpload from "./uploadImage";
import AlertPopUp from "../Reusable_Components/Alert";
import {  commercialHeadGetContractsForDropdownTechnician, technicianSubmitMeterImage, technicianSubmitMeterReadings, technicianUpdateMeterReadings } from "../../Redux/Actions/Actions";
import axios from "../../axios-interceptor"
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import { base_url } from "../../utilities";
import { formatDateView } from "../Reusable_Components/ReusableFunctions";
import { formatNumericInput, handleKeyDownForMeterReadings } from "../Reusable_Components/ReusableFunctions";
import Footer from "../Login/Footer";


const ElectricityMeterReadings = () => {
    const facilityName = localStorage.getItem("selected_facility_name")
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [previewPopUp,setPreviewPopUp] = useState(false)
  const [selectMeterImage,setSelectedMeterImage] = useState('')
  const [isPhotoCaptured ,setIsPhotoCaptured] = useState(false)
  const [capturedImage,setCapturedImage] = useState('')
  const [spaceName,setSpaceName]  = useState('')
  const camRef = useRef(null)
  const [listOfMeterValues,setListOfMeterValues] = useState([])
  const [listOfDgMeterValues,setListOfDgMeterValues] = useState([])
  const [dgReadingToggle,setDgReadingToggle] = useState(false)
  const [documentsUploadData,setDocumentsUploadData] = useState([])
  const [selectedMeterIndex,setSelectedMeterIndex] = useState(0) 
  const [isDgMeter,setIsDgMeter] = useState(false)
  const [cameraMeterName,setCameraMeterName] = useState('')
  const [validSubmit,setValidSubmit] = useState(false)
  const [contractName,setContractName] = useState('')
  const [isLoading,setIsLoading] = useState(true)
  const [confirmMeterReading,setConfirmMeterReading] = useState(123.235)
  const allContracts = useSelector((state) => state.contractsForDropdownTechnician);
  const contractApiLoader = useSelector((state) => state.contractApiLoader);
  const dispatch = useDispatch('');
  const [errorPopUp,setErrorPopUp] = useState(false)
  const [errorPopUpMessage,setErrorPopUpMessage] = useState('')
  const error = useSelector((state) => state.error);
  const isSubmittedMeter = useSelector((state) => state.submittedMeter)
  const [showSuccessModal,setShowSuccessModal] = useState(false)
  const [errorValue, setErrorValue] = useState(null)
  const [showSubmitPopAlert, setSubmitPopAlert] = useState(false)
  const [showUploadPopUp,setShowUploadPopUp] = useState(false)
  const [todayDate,setTodayDate] = useState(()=>{
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate
    // const today = new Date(date);
    // const yesterday = new Date(today);
    // yesterday.setDate(yesterday.getDate() + 1);
    // const yesterdayDate = yesterday.toISOString().split('T')[0];
    // return yesterdayDate
  })
  const [uploadedAlert,setUploadedAlert] = useState(false)
    const [alertShow,setAlertShow] = useState(false)
    const [alertType,setAlertType] = useState('')
    const [alertMessage,setAlertMessage] = useState('')
    const [alertValue,setAlertValue] = useState('')
    const [alertButtons,setAlertButtons] = useState([])
    const [alertAction,setAlertAction] = useState([])

    // ========================error useeffect =================
    useEffect(() => {  
       setErrorValue(error?.response?.data.message);
        if(error?.response?.data.message){
        setAlertShow(true)
        setAlertType('error')
        setAlertMessage('Error')
        setAlertValue(error?.response?.data.message)
        setAlertButtons([{name:'Close',action: ()=>{setAlertShow(false)}}])
        }
        setIsLoading(false);
    }, [error])

    useEffect(()=>{
        if(errorPopUpMessage==='duplicate'||errorPopUpMessage==='overwrite'){
            uploadIamgeFunction()
            setTimeout(()=>setErrorPopUpMessage(''),1000)
        }
    },[errorPopUpMessage])

  useEffect(()=>{
    if(allContracts.length!==0){
        setTimeout(()=>setIsLoading(false),2000)
    }
  },[allContracts])

    useEffect(()=>{
         dispatch(commercialHeadGetContractsForDropdownTechnician({facility_id: localStorage.getItem("selected_facility_id")}))
    },[])
    //after submit meter api success
    useEffect(()=>{
        if(isSubmittedMeter === 'success'){
            setIsLoading(false)
            setAlertShow(true)
        setAlertType('success')
        setAlertMessage('Success')
        setAlertValue(uploadedAlert?'Meter Readings are Updated Successfully':'Meter Readings are Submitted Successfully')
        setAlertButtons([
            {name:'Close',action: ()=>{
            setAlertShow(false)
            window.location.reload()
            }},
            // {name:'Close',action: ()=>{
            // setAlertShow(false)
            // window.location.reload()
            // }}
        ])
        }
    },[isSubmittedMeter])

    //checking all meters are valid are not 
    useEffect(()=>{
        const isValid = listOfMeterValues.every(meter => 
            meter.assigned_name && 
            meter.reading_unit && 
            meter.meter_reading !== '' && 
            meter.meter_image !== ''
        );
        if(dgReadingToggle===true){
           const isDgValid = listOfDgMeterValues.every(meter => 
            meter.assigned_name && 
            meter.reading_unit && 
            meter.meter_reading !== '' && 
            meter.meter_image !== ''
            ); 
            setValidSubmit(isValid && isDgValid);
        }else{
            setValidSubmit(isValid);
        }       
    },[listOfMeterValues,listOfDgMeterValues,dgReadingToggle])

    //mapping data keys from api to meter values
    useEffect(()=>{
        if(dgReadingToggle){
            
            let normalData = [];
            let dgData = [];
            if (Array.isArray(documentsUploadData)) {
                normalData = documentsUploadData.slice(0, listOfMeterValues.length);
                dgData = documentsUploadData?.slice(listOfMeterValues.length);
            } 
            normalData?.forEach(e=>{
                const index = listOfMeterValues?.findIndex(meter=>meter.meter_id===e.id_value)
                if(index!==-1){
                    const updatedMeters = [...listOfMeterValues]
                    updatedMeters[index].image_url = e.key
                    setListOfMeterValues(updatedMeters)
                }
            })
            dgData?.forEach(e=>{
                const index = listOfDgMeterValues?.findIndex(meter=>meter.meter_id===e.id_value)
                if(index!==-1){
                    const updatedMeters = [...listOfDgMeterValues]
                    updatedMeters[index].image_url = e.key
                    setListOfDgMeterValues(updatedMeters)
                }
            })
        }
        else{
        documentsUploadData.forEach(e=>{
            const index = listOfMeterValues.findIndex(meter=>meter.meter_id===e.id_value)
            if(index!==-1){
                const updatedMeters = [...listOfMeterValues]
                updatedMeters[index].image_url = e.key
                setListOfMeterValues(updatedMeters)
            }
        })
        }
        if(uploadedAlert){
            setTimeout(()=>handleUpdate(),2000)
        }else{
        setTimeout(()=>handleSubmit(),2000)
        }
    },[documentsUploadData])

  //for rear camera view 
  const videoConstraints = {
    // Use 'user' for front camera, 'environment' for back camera
    facingMode: 'environment', 
  };

  const  takePicture = () => {
    setCapturedImage(camRef?.current?.getScreenshot());
    setIsPhotoCaptured(true)
  }
  const retakePicture = () => {
    setIsPhotoCaptured(false)
  }

  const getMeters= (id) => {
    //fetching meters based on contract id
    const yesterday = new Date(todayDate);
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayDate = yesterday.toISOString().split('T')[0];

    axios.get(base_url+"facilities/meterReadings/meterReadingsDetails?filter_condition=%7B%22contract_id%22%3A%22"+id+"%22%2C%22reading_date%22%3A%22"+todayDate+"%22%7D")
    .then(response=>{
        if(response?.data?.data[0]==='error'){
            setErrorValue(response?.data?.data[1].message)
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(response?.data?.data[1].message)
            setAlertButtons([
                {name:'Close',action: ()=>{
                setAlertShow(false)
                // window.location.reload()
                }},
            ])
            setIsLoading(false)
        }
        else{const data = response.data.data
        const electricityData = data.filter(e=>e.record_type==='electricity_reading')
        const electricityDatawithImage = electricityData.filter(e=>e.image_url!==null && e.image_url!=='')
        const electricityDataWithoutImage = electricityData.filter(e=>e.image_url===null || e.image_url==='')
        electricityDataWithoutImage?.forEach(element => {
            element.toBeCreated = true
        });
        const dgData = data.filter(e=>e.record_type==='dieselgenerator_reading')
        const dgDatawithImage = dgData.filter(e=>e.image_url!==null && e.image_url!=='')
        const dgDataWithoutImage = dgData.filter(e=>e.image_url===null ||  e.image_url==='')
        dgDataWithoutImage?.forEach(element => {
            element.toBeCreated = true
        });
        const firstData = electricityDatawithImage[0]
        //if data filled for the day
        if(firstData?.reading_date===todayDate && firstData?.meter_reading!==null){
            setUploadedAlert(true)
            const firstDatawithImage = electricityDatawithImage[0]
            // for electricity meter images
            let url = '%7B%22s3_keys%22%3A%5B%22'
            url = url+firstDatawithImage.image_url
            electricityDatawithImage?.slice(1)?.forEach(e => {
                url = url + '%22%2C%22' + e.image_url;
            });
            url = url+'%22%5D%7D'
            axios.get(base_url+'documents?filter_condition='+url)
            .then(response=>{
                if(response?.data?.data[0]==='error'){
                    setErrorValue(response?.data?.data[1].message)
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(response?.data?.data[1].message)
                    setAlertButtons([
                        {name:'Close',action: ()=>{
                        setAlertShow(false)
                        // window.location.reload()
                        }},
                    ])
                    setIsLoading(false)
                }
                else{
                const imageList = response.data.data
                imageList.forEach(async (e,index)=>{
                    if(index<electricityData.length){
                       electricityData[index].meter_image = 'data:image/webp;base64,'+e.file.split(`webpbase64`)[1].slice(0,-1)
                    }
                })
                const newDataForMeterValues = electricityData.map(item => ({ ...item }));
                // Assuming each item has a unique 'id' property

                // Assuming each item has a unique 'id' property
                electricityDataWithoutImage.forEach(e => {
                    e.reading_type = e.meter_type;
                    e.reading_date = todayDate;
                    e.meter_image = '';
                    e.meter_reading = 0.0;
                    e.reading_unit = 'kWh';


                });
                
                newDataForMeterValues.push(...electricityDataWithoutImage);
                const newDataForMeterValues1 = newDataForMeterValues.filter(item=> item.meter_reading !== null);
                // const newDataForDgMeterValues = dgData.map(item => ({ ...item }));
                setListOfMeterValues([...newDataForMeterValues1]);
                // setListOfDgMeterValues([...newDataForDgMeterValues]);
                
            }})
            .catch(error=>{
                setErrorValue(error?.response?.data.message);
                setAlertShow(true)
                setAlertType('error')
                setAlertMessage('Error')
                setAlertValue(error?.response?.data.message)
                setAlertButtons([{name:'Close',action: ()=>{
                    setAlertShow(false)
                    // window.location.reload()
                }}])
                setIsLoading(false)
            })

            // for dg meter images
            const firstDgData = dgData[0]
            if(firstDgData?.meter_reading===null ){
                    dgData.forEach(e=>{
                    e.reading_type = e.meter_type
                    e.reading_date= todayDate
                    e.meter_image=''
                    e.meter_reading = 0.0
                    e.reading_unit='kWh'
                })
                const newDataForDgMeterValues = dgData.map(item => ({ ...item }));
                setListOfDgMeterValues(newDataForDgMeterValues);
                setIsLoading(false) 
            }else{
                const firstDatawithImage = dgDatawithImage[0]
                let url = '%7B%22s3_keys%22%3A%5B%22'
                url = url+firstDatawithImage.image_url
                dgDatawithImage?.slice(1)?.forEach(e => {
                    url = url + '%22%2C%22' + e.image_url;
                });
                url = url+'%22%5D%7D'
                axios.get(base_url+'documents?filter_condition='+url).then(response=>{
                    const imageList = response.data.data
                    imageList.forEach(async (e,index)=>{
                        if(index<dgData.length){
                            dgData[index].meter_image = 'data:image/webp;base64,'+e.file.split(`webpbase64`)[1].slice(0,-1)
                        }
                    })
                    const newDataForDgMeterValues = dgData.map(item => ({ ...item }));
                    dgDataWithoutImage.forEach(e=>{
                        e.reading_type = e.meter_type
                        e.reading_date= todayDate
                        e.meter_image=''
                        e.meter_reading = 0.0
                        e.reading_unit='kWh'
                    }
                    )
                    newDataForDgMeterValues.push(...dgDataWithoutImage)
                    const newDataForMeterValues1 = newDataForDgMeterValues.filter(item=> item.meter_reading !== null);
                    setListOfDgMeterValues([...newDataForMeterValues1]);
                    setIsLoading(false)
                })
                .catch(e=>setErrorValue(e?.response?.data.message))
            }
        }//if data not filled for the day
        else if(firstData?.reading_date===todayDate){
            electricityData.forEach(e=>{
                e.reading_type = e.meter_type
                e.reading_date= todayDate
                e.meter_image=''
                e.meter_reading = 0.0
                e.reading_unit='kWh'
            })
            dgData.forEach(e=>{
                e.reading_type = e.meter_type
                e.reading_date= todayDate
                e.meter_image=''
                e.meter_reading = 0.0
                e.reading_unit='kWh'
            })
            const newDataForMeterValues = electricityData.map(item => ({ ...item }));
            const newDataForDgMeterValues = dgData.map(item => ({ ...item }));
            setListOfMeterValues(newDataForMeterValues);
            setListOfDgMeterValues(newDataForDgMeterValues);
            setIsLoading(false)
        }//if it is not today's date
        else if(firstData?.reading_date===yesterdayDate){
            electricityData.forEach(e=>{
                e.reading_type = e.meter_type
                e.reading_date= todayDate
                e.meter_image=''
                e.reading_unit='kWh'
            })
            dgData.forEach(e=>{
                e.reading_type = e.meter_type
                e.reading_date= todayDate
                e.meter_image=''
                e.reading_unit='kWh'
            })
            const newDataForMeterValues = electricityData.map(item => ({ ...item }));
            const newDataForDgMeterValues = dgData.map(item => ({ ...item }));
            setListOfMeterValues(newDataForMeterValues);
            setListOfDgMeterValues(newDataForDgMeterValues);
            setIsLoading(false)
        }else{
            electricityData.forEach(e=>{
                e.reading_type = e.meter_type
                e.reading_date= todayDate
                e.meter_image=''
                e.meter_reading = 0.0
                e.reading_unit='kWh'
            })
            dgData.forEach(e=>{
                e.reading_type = e.meter_type
                e.reading_date= todayDate
                e.meter_image=''
                e.meter_reading = 0.0
                e.reading_unit='kWh'
            })
            const newDataForMeterValues = electricityData.map(item => ({ ...item }));
            const newDataForDgMeterValues = dgData.map(item => ({ ...item }));
            setListOfMeterValues(newDataForMeterValues);
            setListOfDgMeterValues(newDataForDgMeterValues);
            setIsLoading(false)
        }
    }})
    .catch(error=>{
        setErrorValue(error?.response?.data.message);
        setAlertShow(true)
        setAlertType('error')
        setAlertMessage('Error')
        setAlertValue(error?.response?.data.message)
        setAlertButtons([{name:'Close',action: ()=>{
            setAlertShow(false)
            // window.location.reload()
        }}])
        setIsLoading(false)
    })
  }

  const [selectedContractId, setSelectedContractId] = useState("")

  const changeContract = (id) => {
    const selectedContract = allContracts?.filter(e=>e.contract_id === id)[0] 
    setSelectedContractId(id)
    setSpaceName(selectedContract.space_name)
    setContractName(selectedContract.contract_name)
    setIsLoading(true)
    setListOfMeterValues([])
    setListOfDgMeterValues([])
    setUploadedAlert(false)
    getMeters(id)
  }
// image upload api before submit
  const uploadIamgeFunction = () => {
  ImageUpload(selectedContractId, listOfMeterValues,listOfDgMeterValues,dgReadingToggle,contractName,setErrorPopUp,setDocumentsUploadData,errorPopUpMessage,setErrorValue,setIsLoading,setAlertShow,setAlertType,setAlertMessage,setAlertValue,setAlertButtons)

  }

  const handleSubmit = () => {
        const dataArray = []
        listOfMeterValues.forEach(e=>{
            dataArray.push({
                "meter_id": e.meter_id,
                "meter_reading": e.meter_reading,
                "reading_type": e.reading_type,
                "reading_date": e.reading_date,
                "image_url" : e.image_url,
                "reading_unit" : e.reading_unit, // add this after api changes
            })
        })
            if(dgReadingToggle===true){
                listOfDgMeterValues.forEach(e=>{
                    dataArray.push({
                        "meter_id": e.meter_id,
                        "meter_reading": e.meter_reading,
                        "reading_type": 'dieselgenerator',
                        "reading_date": e.reading_date,
                        "image_url" : e.image_url, 
                        "reading_unit" : e.reading_unit, // add this after api changes
                    })
                })
            }
        if(dataArray.length!==0){
        const data = {"meter_readings_list": [...dataArray]}
        dispatch(technicianSubmitMeterReadings(data))
        setIsLoading(true)
        }
  }

  const handleUpdate = () => {
    const dataArrayUpdate = []
    const dataArrayCreate = []
    listOfMeterValues.forEach(e=>{
        if(e.toBeCreated){
            dataArrayCreate.push({
                "meter_id": e.meter_id,
                "meter_reading": e.meter_reading,
                "reading_type": e.reading_type,
                "reading_date": e.reading_date,
                "image_url" : e.image_url,
                "reading_unit" : e.reading_unit, // add this after api changes
            })
        }else{
        dataArrayUpdate.push({
            "meter_id": e.meter_id,
            "meter_reading": e.meter_reading,
            "reading_type": e.reading_type,
            "reading_date": e.reading_date,
            "image_url" : e.image_url,
            "reading_unit" : e.reading_unit, // add this after api changes
        })
        }
    })
        if(dgReadingToggle===true){
            listOfDgMeterValues.forEach(e=>{
                if(e.toBeCreated){
                    dataArrayCreate.push({
                        "meter_id": e.meter_id,
                        "meter_reading": e.meter_reading,
                        "reading_type": 'dieselgenerator',
                        "reading_date": e.reading_date,
                        "image_url" : e.image_url,
                        "reading_unit" : e.reading_unit, // add this after api changes
                    })
                }else{
                    dataArrayUpdate.push({
                        "meter_id": e.meter_id,
                        "meter_reading": e.meter_reading,
                        "reading_type": 'dieselgenerator',
                        "reading_date": e.reading_date,
                        "image_url" : e.image_url, 
                        "reading_unit" : e.reading_unit, // add this after api changes
                    })
                }
        })
    }
    if(dataArrayUpdate.length!==0){
    const data = {"meter_readings_list": [...dataArrayUpdate]}
    const dataCreate = {"meter_readings_list": [...dataArrayCreate]}
    if(dataCreate.length!==0){
        dispatch(technicianSubmitMeterImage(dataCreate))
    }
    dispatch(technicianUpdateMeterReadings(data))
    setIsLoading(true)
    }
  }
  

    return (
        <>

        <>
                      <NavBarTop />
                      <LeftNavBar />
                    </>
        {/* {error || errorValue ?
            <Alert show={errorValue}  key={"danger"} variant={"danger"}
                style={{position:"absolute", width:"96%", zIndex:"1000", margin:"10px"}}>
                <span style={{display:'flex', justifyContent:'space-between'}}>
                    {errorValue}
                    <img src="./images/icons/Close.svg" style={{height:"20px"}} alt="close" onClick={() => {
                        setErrorValue(null)
                    }}/>
                </span>
                
            </Alert>
            :
            null
        } */}

        <div className="meter-readings">
            <AlertPopUp alertShow={alertShow} alertType={alertType} alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons} />
           <div className="meter-reading-heading">
                <h1>Electricity Meter Readings</h1>
                <Link to={'/technicianDashboard'} className="link-tags"><img src="./images/icons/Close.svg"/></Link>
           </div>
            <div className="meter-reading-body">
                <p className='meter-reading-header'>FACILITY : {facilityName}</p>
                <p className='meter-reading-header2'>Date : {formatDateView(todayDate)}</p>
                <div className="meter-reading-inner-div">
                    <p>Contract Name <span style={{color:"red"}}>*</span></p>
                            <select className="custom-select" onChange={(event)=>{ 
                                changeContract(event.target.value) 
                            }}> 
                                <option hidden>{allContracts?.length > 0 ? 'Select' : 'No Contracts'}</option> 
                                {allContracts?.map((e, index) => (
                                    <option key={index} value={e.contract_id}>
                                        {e.contract_name}
                                    </option>
                                ))}
                            </select>
                </div>
                <div className="meter-reading-inner-div">
                    <p>Space</p>
                    <input value={spaceName} disabled={true}/>
                </div>
                {
                    listOfMeterValues.length !== 0 ?
                <div>
                {uploadedAlert && <p className="input-error-text-1"><IoMdInformationCircleOutline /> Your meter readings are already Uploaded for the day</p>}
                {/* Meter Readings */}
                <Table className="meter-reading-table">
                    <thead className="meter-reading-thead">
                        <tr>
                            <td style={{flexGrow:2}}>Meter Name</td>
                            <td style={{flexGrow:1}}>Reading <span style={{color:"red"}}>*</span></td>
                            <td>image <span style={{color:"red"}}>*</span></td>
                        </tr>
                    </thead>
                    <tbody className="meter-reading-tbody">
                        {listOfMeterValues.map((each,index)=>{
                            return (
                            <tr id={index} key={index}>
                                <td style={{flexGrow:3}}>{each.assigned_name} </td>
                                <td  style={{flexGrow:0}}>
                                    <div className='meter-reading'>
                                        <input className="table-meter-reading" value={each.meter_reading} 
                                          onChange={e=>{
                                            const input = e.target.value;
                                            const formattedInput = formatNumericInput(input, 3);
                                            const updatedReading = [...listOfMeterValues]
                                            updatedReading[index].meter_reading = formattedInput
                                            updatedReading[index].meter_image = ''
                                            updatedReading[index].image_url = ''
                                            setListOfMeterValues(updatedReading)
                                          }}
                                          onKeyDown={handleKeyDownForMeterReadings}
                                          />
                                        <select className="meter-reading-select" 
                                        onChange={(e)=>{
                                            const updatedReading = [...listOfMeterValues]
                                            updatedReading[index].reading_unit = e.target.value
                                            setListOfMeterValues(updatedReading)
                                        }}
                                        value={each.reading_unit}>
                                            {/* <option value='kVA'>kVA</option> */}
                                            <option value='kWh'>kWh</option>
                                            {/* <option value='MWA'>MWA</option> */}
                                        </select>
                                    </div>
                                </td>
                                <td >
                                <div className="d-flex justify-content-center">
                                    {each.meter_image?.length == 0  ?
                                    each.meter_reading==='' ?
                                        <img  src='./images/icons/camera.png' style={{ cursor: 'pointer',opacity:'50%' }}  alt='camera icon'/> 
                                        :
                                        <img  src='./images/icons/camera.png' style={{ cursor: 'pointer' }} 
                                        onClick={()=>{
                                            setSelectedMeterIndex(index)
                                            setCameraMeterName(each.assigned_name)
                                            setIsPhotoCaptured(false)
                                            setCapturedImage('')
                                            setConfirmMeterReading(each.meter_reading)
                                            setIsDgMeter(false)
                                            setShow(true)
                                        }} alt='camera icon'/>
                                     
                                    
                                     : 
                                    <div>
                                        <img  src='./images/icons/captured.svg' style={{ cursor: 'pointer' }} 
                                        alt="captured" 
                                        onClick={()=>{
                                            setSelectedMeterImage(each)
                                            setPreviewPopUp(true)
                                        }}/>
                                        <img src='./images/icons/Close.svg' 
                                         className="close-icon"
                                         onClick={()=>{
                                            const updatedMeters = [...listOfMeterValues]
                                            updatedMeters[index].meter_image = ''
                                            setListOfMeterValues(updatedMeters)
                                         }}
                                         alt="close icon"
                                        />
                                    </div>
                                    }
                                </div>
                                </td>
                            </tr>
                            )
                        })}
                    </tbody>
                </Table>
                <div className="d-flex gap-2">
                    <p className="dg-reading-toggle">DG Reading </p>
                    <label className="switch">
                                        <input type="checkbox" onChange={()=>{
                                            setDgReadingToggle(!dgReadingToggle)
                                        }} checked={dgReadingToggle}/>
                                        <span className="slider round"></span>
                                    </label>
                </div>
                {/* DG meter Readings */}
                {dgReadingToggle && <Table className="meter-reading-table">
                    <thead className="meter-reading-thead">
                        <tr>
                            <td style={{flexGrow:3}}>Meter Name</td>
                            <td style={{flexGrow:1}}>Reading</td>
                            <td>image</td>
                        </tr>
                    </thead>
                    <tbody className="meter-reading-tbody">
                        {listOfDgMeterValues.map((each,index)=>{
                            return (
                            <tr id={index} key={index}>
                                <td style={{flexGrow:3}}>{each.assigned_name} </td>
                                <td  style={{flexGrow:0}}>
                                    <div className='meter-reading'>
                                        <input className="table-meter-reading" value={each.meter_reading} 
                                          onChange={e=>{
                                            const updatedReading = [...listOfDgMeterValues]
                                            const input = e.target.value
                                            const parts = input.replace(/[^0-9.]/g, '').split('.');
                                            const integerPart = parts[0];
                                            const decimalPart = parts.length > 1 ? '.' + parts[1].substring(0, 2) : '';
                                            const formattedInput = formatNumericInput(input, 3);
                                            updatedReading[index].meter_reading = formattedInput
                                            updatedReading[index].meter_image = ''
                                            updatedReading[index].image_url = ''
                                            setListOfDgMeterValues(updatedReading)
                                          }}/>
                                        <select className="meter-reading-select" 
                                        onChange={(e)=>{
                                            const updatedReading = [...listOfDgMeterValues]
                                            updatedReading[index].reading_unit = e.target.value
                                            setListOfMeterValues(updatedReading)
                                        }}
                                        value={each.reading_unit}>
                                            {/* <option value='kVA'>kVA</option> */}
                                            <option value='kWh'>kWh</option>
                                            {/* <option value='MWA'>MWA</option> */}
                                        </select>
                                    </div>
                                </td>
                                <td >
                                <div className="d-flex justify-content-center">
                                    {each.meter_image?.length == 0  ?
                                    each.meter_reading===''  ?
                                        <img  src='./images/icons/camera.png' style={{ cursor: 'pointer',opacity:'50%' }}  alt='camera icon'/> 
                                        :
                                        <img  src='./images/icons/camera.png' style={{ cursor: 'pointer' }} 
                                        onClick={()=>{
                                            setSelectedMeterIndex(index)
                                            setIsPhotoCaptured(false)
                                            setIsDgMeter(true)
                                            setCapturedImage('')
                                            setConfirmMeterReading(each.meter_reading)
                                            setCameraMeterName(each.assigned_name)
                                            setShow(true)
                                        }} alt='camera icon'/>
                                     
                                    
                                     : 
                                    <div>
                                        <img  src='./images/icons/captured.svg' style={{ cursor: 'pointer' }} 
                                        alt="captured" 
                                        onClick={()=>{
                                            setSelectedMeterImage(each)
                                            setPreviewPopUp(true)
                                        }}
                                        />
                                        <img src='./images/icons/Close.svg' 
                                         className="close-icon"
                                         onClick={()=>{
                                            const updatedMeters = [...listOfDgMeterValues]
                                            updatedMeters[index].meter_image = ''
                                            setListOfDgMeterValues(updatedMeters)
                                         }}
                                         alt="close icon"
                                        />
                                    </div>
                                    }
                                </div>
                                </td>
                            </tr>
                            )
                        })}
                    </tbody>
                </Table>}

                {
                    uploadedAlert?
                    <div className="meter-readings-buttons">
                        <Link to={'/technicianDashboard'} className="link-tags meter-readings-cancel">Cancel</Link>
                        <button className="meter-readings-save"
                        disabled={!validSubmit}
                        style={!validSubmit?{opacity:'50%'}:{opacity:'100%'}}
                        onClick={()=>setShowUploadPopUp(true)}
                        >Update</button>
                </div>
                    :
                    <div className="meter-readings-buttons">
                        <Link to={'/technicianDashboard'} className="link-tags meter-readings-cancel">Cancel</Link>
                        <button className="meter-readings-save"
                        disabled={!validSubmit}
                        style={!validSubmit?{opacity:'50%'}:{opacity:'100%'}}
                        onClick={()=>setSubmitPopAlert(true)}
                        >Submit</button>
                </div>
                }
                </div>
                :
                spaceName !== '' && !isLoading?
                <div className="meter-reading-inner-div d-flex justify-content-center mt-3">
                    <p>There no meters for selected contract</p>
                </div>
                :
                <div className="meter-reading-inner-div d-flex justify-content-center mt-3">
                    {allContracts?.length > 0 && <p>Please Select a Contract</p>}
                </div>
                 
            }
            </div>
            {/* Camera pop up */}
            <Modal keyboard={false} backdrop="static" centered show={show} onHide={handleClose} animation={false}>
                <Modal.Header>
                <Modal.Title>Confirm Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>Meter Name : {cameraMeterName}</p>
                        <div className="show-camera">
                           {!isPhotoCaptured && <Webcam className="show-camera-div" ref={camRef} videoConstraints={videoConstraints}/>}
                           {isPhotoCaptured && <img className="show-camera-div-img" src={capturedImage} alt="meter-reading"/>}
                        </div>
                        {isPhotoCaptured ? 
                        <button className="show-camera-button" onClick={()=>retakePicture()}>Retake the image</button> :
                        <button className="show-camera-button" onClick={()=>takePicture()}>take a photo</button> 
                        }
                        <div className="mt-2">
                            <p className="d-flex justify-content-between align-items-center">Confirm Reading : 
                            <input className="" value={confirmMeterReading}
                                    onChange={(e)=>{
                                        const input = e.target.value
                                        const parts = input.replace(/[^0-9.]/g, '').split('.');
                                        const integerPart = parts[0];
                                        const decimalPart = parts.length > 1 ? '.' + parts[1].substring(0, 2) : '';
                                        const formattedInput = integerPart + decimalPart;
                                        setConfirmMeterReading(formattedInput)
                                    }}/></p>
                        </div>
                        <div className="show-camera-buttons">
                            <button onClick={()=>{
                                setShow(false)
                                setIsPhotoCaptured(false)
                                }} className="meter-readings-cancel">Back</button>
                            <button className="meter-readings-save"
                                onClick={()=>{
                                    if(isDgMeter){
                                     const updatedMeters = [...listOfDgMeterValues]
                                     updatedMeters[selectedMeterIndex].meter_image = capturedImage
                                     updatedMeters[selectedMeterIndex].meter_reading= confirmMeterReading
                                     setListOfDgMeterValues(updatedMeters)
                                     setShow(false)
                                    }else{
                                     const updatedMeters = [...listOfMeterValues]
                                     updatedMeters[selectedMeterIndex].meter_image = capturedImage
                                     updatedMeters[selectedMeterIndex].meter_reading= confirmMeterReading
                                     setListOfMeterValues(updatedMeters)
                                     setShow(false)
                                    }
                                }}
                                disabled={capturedImage?.length<=0}
                                style={capturedImage?.length<=0?{opacity:'50%'}:{opacity:'100%'}}
                            >Save</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* image preview pop up */}
            <Modal keyboard={false} backdrop="static" centered show={previewPopUp} onHide={()=>{
                setPreviewPopUp(false)
                setSelectedMeterImage('')
                }} animation={false}>
                <Modal.Header>
                <Modal.Title>Image Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>Meter Name : {selectMeterImage?.assigned_name}</p>
                        <div className="show-camera">
                           <img className="show-camera-div-img" src={selectMeterImage?.meter_image} alt="meter-reading"/>
                        </div>
                        
                        <div className="mt-2">
                            <p className="d-flex justify-content-between align-items-center">Confirmed Reading : 
                            {selectMeterImage?.meter_reading}</p>
                        </div>
                        <div className="show-camera-buttons">
                            <button onClick={()=>{
                                setPreviewPopUp(false)
                                setSelectedMeterImage('')
                                }} className="meter-readings-cancel">Back</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {isLoading && <Loader /> }
            {contractApiLoader && <Loader />}
            {/* success popup */}
            {/* <Modal keyboard={false} backdrop="static" show ={showSuccessModal} onHide={()=>setShowSuccessModal(false)} centered className="popper-modal">
                <Modal.Body className="text-center mt-4">
                    <img src= "./images/icons/popper.svg" alt="Party Popper" />
                    <h5>Success!</h5>
                    {uploadedAlert ? <p>Meter Readings are Updated for Contract <b>{contractName}</b></p>
                    :
                    <p className="mb-1">Meter Readings are Submitted for Contract <b>{contractName}</b></p>
                    }
                    
                </Modal.Body>
                <Modal.Footer className="footer d-flex justify-content-center">
                    <Button variant ="secondary" className="secondary" onClick = {()=>{
                        setShowSuccessModal(false)
                        window.location.reload()
                    }} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal> */}
            <Modal keyboard={false} backdrop="static" 
            show ={errorPopUp} 
            onHide={()=>setShowSuccessModal(false)} centered className="popper-modal">
                <Modal.Body className="text-center mt-4">
                    {/* <img src= "./images/icons/popper.svg" alt="Party Popper" /> */}
                    <h5>Warning!</h5>
                    <p className="mb-1">You already have this meter Readings</p>
                </Modal.Body>
                <Modal.Footer className="footer d-flex justify-content-center">
                    <Button variant ="secondary" className="secondary" onClick = {()=>{
                        setErrorPopUp(false)
                        // window.location.reload()
                    }} >
                        Cancel
                    </Button>
                    <Button variant ="secondary" className="secondary" onClick = {()=>{
                       setErrorPopUpMessage('duplicate')
                    }} >
                        Duplicate
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* submit Popup */}
            <Modal keyboard={false} backdrop="static" 
            shouldCloseOnOverlayClick={false}
            show={showSubmitPopAlert} onHide={()=>{setSubmitPopAlert(false)}} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <h5>{`Are you sure you want to Submit?`}</h5>
                </Modal.Body>
                <Modal.Footer className="footer">
                    <Button variant="secondary" className="confirmation" onClick={() => {setSubmitPopAlert(false)}}>Cancel</Button>
                    <Button variant="secondary" className="confirmation-left" onClick={() => {
                        uploadIamgeFunction()
                        setSubmitPopAlert(false)
                    }
                    }>Proceed</Button>
                </Modal.Footer>
            </Modal>
            {/* update popup */}
            <Modal keyboard={false} backdrop="static" 
            show={showUploadPopUp} 
            shouldCloseOnOverlayClick={false}
            onHide={()=>{setShowUploadPopUp(false)}} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <h5>{`Are you sure you want to Update?`}</h5>
                </Modal.Body>
                <Modal.Footer className="footer">
                    <Button variant="secondary" className="confirmation" onClick={() => {setShowUploadPopUp(false)}}>Cancel</Button>
                    <Button variant="secondary" className="confirmation-left" onClick={() => {
                        uploadIamgeFunction()
                        setShowUploadPopUp(false)
                    }
                    }>Proceed</Button>
                </Modal.Footer>
            </Modal>

               
        </div>
        <Footer/>
    </>
    )
}

export default ElectricityMeterReadings
