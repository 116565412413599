import React, { useEffect, useState } from "react";
import { GoSearch } from "react-icons/go";
import { Button, Placeholder, Spinner, Tab, Tabs } from 'react-bootstrap';
import { Stack, Table, Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import { format, set } from 'date-fns';
import { GET_ALL_INVOICES_ERROR, SET_PAGINATION_COUNT_TO, REGENERATE_INVOICE_DATA_SUCCESS, handlePaginationLoaderTrue, getAllInvoices, getAllPartnerCompanies, putInvoiceData, PUT_INVOICE_DATA_SUCCESS, regenerateAnInvoice, commercialHeadGetBillingEntities, commercialHeadGetCompleteContractsForDropdown, handleDocumentLoaderTrue, commercialHeadUploadDocument, createNewMiscInvoice, PUT_ADD_MISC_INVOICE_SUCCESS, PUT_ADD_MISC_INVOICE_FAILURE, submitToGSTSubmissionPortal, sendToCustomerAsEmail, PUT_GST_SUBMIT_SUCCESS, editAMiscInvoice, PUT_EDIT_MISC_INVOICE_SUCCESS, CONTRACT_API_LOADER_TRIGGER_TRUE } from "../../Redux/Actions/Actions";
import { useDispatch, useSelector } from "react-redux";
import FormLoader from "../Reusable_Components/FromLoader";
import { MdErrorOutline } from "react-icons/md";
import Pagination from "../Reusable_Components/Pagination";
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import axios from "../../axios-interceptor"
import AlertPopUp from "../Reusable_Components/Alert";
import { base_url } from "../../utilities";
import { RECORDS_PER_PAGE } from "../../Config";
import PdfViewer from "../Reusable_Components/pdfviewer";
import { formatToIndianRupee, convertCurrencyToWords, timeElapsed, validateValue } from "../Reusable_Components/ReusableFunctions";
import QRCode from "react-qr-code";
import { formatDateView } from "../Reusable_Components/ReusableFunctions";
import Footer from '../Login/Footer';
import UploadComponent from "../Reusable_Components/UploadComponent";
import Select from 'react-select';


export const SET_LOADER = "SET_LOADER"

const FinanceManagerInvoice = () => {
    const [sample, setSample] = useState()
    const [totalInvoices, setTotalInvoices] = useState()
    const [invoiceSelected, setinvoiceSelected] = useState('')
    const [searchText, setSearchText] = useState("")
    const [asc, setAsC] = useState(false);
    const [desc, setDesc] = useState(false);
    const [filteredData, setFilteredData] = useState("")
    const [invoiceType, setInvoiceType] = useState("draft")
    const [igstToggle, setIgstToggle] = useState(false)
    const [paymentToggle, setPaymentToggle] = useState(false)
    const [termsToggle, setTermsToggle] = useState(false)
    const [userNotesToggle, setUserNotesToggle] = useState(false)
    const [biilingPeriodFilter, setBillingPeriodFilter] = useState(new Date())

    const [currentPage, setCurrentPage] = useState(1)
    const [invoiceTableData, setInvoiceTableData] = useState([])
    const [currentTime, setCurrentTime] = useState(new Date())
    const [invoiceStatus, setInvoiceStatus] = useState([])
    const [preview, setPreview] = useState(false)
    const [miscDueDate, setMiscDueDate] = useState("")
    const [miscService, setMiscService] = useState("")
    const [miscDescription, setMiscDescription] = useState("")
    const [miscBasicAmount, setMiscBasicAmount] = useState(0)
    const [miscIgst, setMiscIgst] = useState(18);
    const [miscCsgtSgst, setMiscCgstSgst] = useState(9)
    const [slfShow, setSlfShow] = useState(false);
    const [userNotes, setUserNotes] = useState("")
    const [userNotesError, setUserNotesError] = useState("")
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(5)

    const dispatch = useDispatch()
    const invoicesList = useSelector(state => state.invoicesList?.data)
    const invoicesListError = useSelector(state => state.invoicesListError)
    const [invoiceListErrorFlag, setInvoiceListErrorFlag] = useState(false)
    const loading = useSelector(state => state.loading)
    const paginationCount = useSelector(state => state.paginationCount)
    const editInvoiceSubmisssion = useSelector(state => state.editInvoiceSubmisssion)
    const partnerCompaniesList = useSelector(state => state.partnerCompaniesList)
    const partnerCompaniesListError = useSelector(state => state.partnerCompaniesListError)
    const generateInvoiceStatus = useSelector(state => state.generateInvoiceStatus)
    const gstsubmmisiondata = useSelector(state => state.gstsubmmisiondata)
    const error = useSelector(state => state.error)
    const [isLoading, setIsLoading] = useState(false)
    const [facilityName, setFacilityName] = useState('')

    const handleClose = () => setSlfShow(false);
    const handleShow = () => setSlfShow(true);
    const [sortOrder, setSortOrder] = useState(false);

    //invoice data fields
    const [companyName, setCompanyName] = useState("")
    const [companyAddress, setCompanyAddress] = useState("")
    const [companyCIN, setCompanyCIN] = useState("")
    const [companyGST, setCompanyGST] = useState("")
    const [companyPhone, setCompanyPhone] = useState("")
    const [invoiceDetails, setInvoiceDetails] = useState([])
    const [companyLogo, setCompanyLogo] = useState("")
    const [contractNo, setContractNo] = useState("")
    const [customerName, setcustomerName] = useState("")
    const [customerAddress, setcustomerAddress] = useState("")
    const [customerStateCode, setcustomerStateCode] = useState("")
    const [customerGSTIN, setcustomerGSTIN] = useState("")
    const [customerCIN, setcustomerCIN] = useState("")
    const [customerPlaceOfSupply, setcustomerPlaceOfSupply] = useState("")
    const [customerPAN, setcustomerPAN] = useState("")
    const [IRN, setIRN] = useState("")
    const [ackNo, setAckNo] = useState("")
    const [ackDate, setAckDate] = useState("")
    const [invoiceNo, setInvoiceNo] = useState("")
    const [parnterState, setPartnerState] = useState("")
    const [partnerStateCode, setPartnerStateCode] = useState("")
    const [partnerPAN, setPartnerPAN] = useState("")
    const [partnerCIN, setPartnerCIN] = useState("")
    const [partnerBillDate, setPartnerBillDate] = useState("")
    const [shippingAddress, setShippingAddress] = useState('')
    const [partnerDueDate, setPartnerDueDate] = useState("")
    const [basicAmount, setBasicAmount] = useState(0)
    const [totalSgst, setTotalSgst] = useState(0)
    const [totalIgst, setTotalIgst] = useState(0)
    const [totalCgst, setTotalCgst] = useState(0)
    const [TotalAmount, setTotalAmount] = useState(0)
    const [totalAmountInWords, setTotalAmountInWords] = useState('');
    //for alert pop up
    const [alertShow, setAlertShow] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertValue, setAlertValue] = useState('')
    const [alertButtons, setAlertButtons] = useState([])
    const [alertAction, setAlertAction] = useState([])
    //edit invoice data
    const [streetAddress, setStreetAddress] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [pincode, setPincode] = useState("")
    const [toggleShippingAddress, setToggleShippingAddress] = useState(false)
    const [approvalType, setApprovalType] = useState("")
    const [listOfPartnerCompanies, setListOfPartnerCompanies] = useState([])
    const [pdfDataForPayments, setpdfDataForPayments] = useState(null);
    const [pdfDataForTerms, setpdfDataForTerms] = useState(null);
    const totalRecordsAc = useSelector((state) => state.invoicesList?.info?.total_no_of_records);
    const currentPaginationPageAc = useSelector((state) => state.invoicesList?.info?.page_number);
    const [shippingAddressToggle, setShippingAddressToggle] = useState(false)
    const [shippingCompanyName, setShippingCompanyName] = useState('');
    const [shippingCompanyAddress, setShippingCompanyAddress] = useState('');
    const [QrCode, setQrCode] = useState('');
    const [invoiceStatusTo, setInvoiceStatusTo] = useState('')
    const [invoiceId, setInvoiceId] = useState('')
    const [slfInvoiceName, setSlfInvoiceName] = useState('')
    const [rejectReason, setRejectReason] = useState('')
    const [rejectReasonError, setRejectReasonError] = useState('')
    const [rejectModal, setRejectModal] = useState(false)
    const [showComputationDetails, setShowComputationDetails] = useState(false) //has to be changes to false when backend is updated
    const [showPaymentDetails, setShowPaymentDetails] = useState(false) //has to be changes to false when backend is updated
    const [showTermsDetails, setShowTermsDetails] = useState(false) //has to be changes to false when backend is updated
    const [downloadPath, setDownloadPath] = useState('')



    const dummyListOfContacts = [
        {
            name: "Rahul Deshpande",
            designation: "CEO",
            sendEmail: true
        }
        ,
        {
            name: "Mrinal",
            designation: "Manager",
            sendEmail: true
        }
        ,
        {
            name: "Ankita Agarwal",
            designation: "Manager",
            sendEmail: true
        }
        ,
        {
            name: "Rahul Sharma",
            designation: "Accountant",
            sendEmail: true
        }
        ,
    ]

    const taxPercentageOptions = [0, 5, 12, 18, 28]
    //for misc invoice generation
    const [miscInvoiceTable, setMiscInvoiceTable] = useState([{ service: "", hsn: "", hsnError: false, hsnLoader: false, hsnMsg: '', basicAmount: 0.0, cgst: 9.0, sgst: 9.0, igst: 18.0, gross: 0.0 }])
    const [miscListOfContacts, setMiscListOfContacts] = useState([])
    const billingEntities = useSelector((state) => state.billingEntities);
    const contractsForDropdown = useSelector((state) => state.contractsForDropdown);
    const contractApiLoader = useSelector((state) => state.contractApiLoader);
    const facilitiesListForNav = useSelector((state) => state.facilitiesListForNav);
    const newInvoiceNumber = useSelector((state) => state.newInvoiceNumber);
    const [miscIgstOptions, setMiscIgstOptions] = useState([...taxPercentageOptions])
    const [contractsDropdown, setContractsDropdown] = useState([])
    const [billingEntityDropdown, setBillingEntityDropdown] = useState(null)
    const [billingEntityOptions, setBillingEntityOptions] = useState([])
    const [miscPlaceOfSupply, setMiscPlaceOfSupply] = useState(null);
    const [miscContractName, setMiscContractName] = useState(null)
    const [editMiscInvoiceClicked, setEditMiscInvoiceClicked] = useState(false)
    const [miscInvoiceServiceIntifier, setMiscInvoiceServiceIntifier] = useState('')
    const [miscSelectedContacts, setMiscSelectedContacts] = useState(true)
    const [uploadedFiles, setUploadedFiles] = useState();
    const [miscTotalBasicAmount, setMiscTotalBasicAmount] = useState(0.0)
    const [miscTotalTaxAmount, setMiscTotalTaxAmount] = useState(0.0)
    const [miscCgstSgstOptions, setMiscCgstSgstOptions] = useState(() => {
        const options = []
        taxPercentageOptions.forEach(each => {
            options.push(parseFloat(each / 2))
        })
        return options
    })


    const handleEditMiscInvoice = () => {
        setEditMiscInvoiceClicked(true)
        dispatch(commercialHeadGetBillingEntities())
        dispatch(commercialHeadGetCompleteContractsForDropdown(localStorage.getItem('selected_facility_id')))

        const tableData = invoiceDetails.map(each => {
            setMiscInvoiceServiceIntifier(each.service_identifier)
            return {
                service: each.service_name_in_invoice,
                hsn: each.hsn,
                basicAmount: each.net_amount,
                cgst: parseFloat(each.cgst_rate),
                sgst: parseFloat(each.sgst_rate),
                igst: parseFloat(each.igst_rate),
                gross: each.net_amount + (each.net_amount / 100) * (each.cgst_rate + each.sgst_rate)
            }
        })
        setMiscInvoiceTable([...tableData])
        // setMiscTotalTaxAmount(totalTaxAmount)
        const invoiceTable = [...tableData]
        let totalTaxAmount = 0
        if (igstToggle) {
            totalTaxAmount = invoiceTable.reduce((acc, each) => acc + parseFloat(each.basicAmount / 100) * (parseFloat(each.igst)), 0)
        }
        else {
            totalTaxAmount = invoiceTable.reduce((acc, each) => acc + parseFloat(each.basicAmount / 100) * (parseFloat(each.cgst) + parseFloat(each.sgst)), 0)
        }
        setMiscTotalTaxAmount(totalTaxAmount)
        setMiscTotalBasicAmount(basicAmount)
        setCurrentPage(3)
    }

    useEffect(() => {
        if (contractsForDropdown) {
            const contracts = contractsForDropdown.map((each) => {
                return {
                    label: each.contract_name,
                    value: each.contract_id +',' + each.contract_type
                }
            })
            setContractsDropdown(contracts)
            if (editMiscInvoiceClicked) {
                const contract = contractsForDropdown.find(each => each.contract_number === contractNo)
                setMiscContractName({
                    label: contract?.contract_name,
                    value: contract?.contract_id + ',' + contract?.contract_type
                })
                getContractDetails(contract?.contract_id+','+contract?.contract_type)
                // const placeOfSupply = stateOptions?.find(each => each.value === customerPlaceOfSupply)
                const placeOfSupply = {
                    label: customerPlaceOfSupply,
                    value: customerPlaceOfSupply
                }
                setMiscPlaceOfSupply(placeOfSupply)
            }
        }
    }, [contractsForDropdown])

    useEffect(() => {
        // success message for gst submission
        if (gstsubmmisiondata) {
            setAlertShow(true)
            setAlertType('success')
            setAlertMessage('Success')
            setAlertValue(<>
                <p className="mb-3">Invoice <span style={{ fontWeight: 'bold' }}>{invoiceNo}</span> Submitted to GST Portal Successfully</p>
            </>)
            setAlertButtons([
                {
                    name: 'view Invoice', variant: 'outline-secondary', action: () => {
                        setAlertShow(false)
                        handleViewButton(invoiceNo, 'gstsubmitted', 'miscellaneous')
                        dispatch({
                            type: PUT_GST_SUBMIT_SUCCESS,
                            payload: null
                        })
                    }
                },
                {
                    name: 'Close', action: () => {
                        setAlertShow(false)
                        setCurrentPage(1);
                        backFunction();
                        backPagination()
                        dispatch({
                            type: PUT_GST_SUBMIT_SUCCESS,
                            payload: null
                        })
                    }
                },
            ])
        } 
    }, [gstsubmmisiondata])

    useEffect(() => {
        if (billingEntities) {
            const billingEntitiesOptions = billingEntities.map((each) => {
                return {
                    label: each?.company_name,
                    value: each?.partner_company_id
                }
            })
            setBillingEntityOptions(billingEntitiesOptions)
            if (editMiscInvoiceClicked) {
                const billingEntity = billingEntities.find(each => each.company_name === companyName)
                setBillingEntityDropdown({
                    label: billingEntity?.company_name,
                    value: billingEntity?.partner_company_id
                })
            }
        }
    }, [billingEntities])

    useEffect(() => {
        if (newInvoiceNumber && !editMiscInvoiceClicked) {
            setAlertShow(true)
            setAlertType('success')
            setAlertMessage('Success')
            setAlertValue(<>
                <p className="mb-3">Invoice <span style={{ fontWeight: 'bold' }}>{newInvoiceNumber}</span> Generated Successfully</p>
            </>)
            setAlertButtons([
                {
                    name: 'View Invoice', variant: 'outline-secondary', action: () => {
                        setAlertShow(false)
                        handleViewButton(newInvoiceNumber, 'draft', 'miscellaneous')
                        dispatch({
                            type: PUT_ADD_MISC_INVOICE_SUCCESS,
                            payload: null
                        })
                    }
                },
                {
                    name: 'Close', action: () => {
                        setAlertShow(false)
                        setCurrentPage(1);
                        backFunction();
                        backPagination()
                        dispatch({
                            type: PUT_ADD_MISC_INVOICE_SUCCESS,
                            payload: null
                        })
                    }
                },
            ])
        }
        else{
            if(newInvoiceNumber && editMiscInvoiceClicked){
            setAlertShow(true)
            setAlertType('success')
            setAlertMessage('Success')
            setAlertValue(<>
                <p className="mb-3">Invoice <span style={{ fontWeight: 'bold' }}>{invoiceNo}</span> Updated Successfully</p>
            </>)
            setAlertButtons([
                {
                    name: 'View Invoice', variant: 'outline-secondary', action: () => {
                        setAlertShow(false)
                        handleViewButton(invoiceNo, 'draft', 'miscellaneous')
                        dispatch({
                            type: PUT_ADD_MISC_INVOICE_SUCCESS,
                            payload: null
                        })
                    }
                },
                {
                    name: 'Close', action: () => {
                        setAlertShow(false)
                        setCurrentPage(1);
                        backFunction();
                        backPagination()
                        dispatch({
                            type: PUT_ADD_MISC_INVOICE_SUCCESS,
                            payload: null
                        })
                    }
                },
            ])
        }
    }
    }, [newInvoiceNumber])


    useEffect(() => {
        if (error) {
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error ')
            setAlertValue(error)
            setAlertButtons([{
                name: 'Close', action: () => {
                    setAlertShow(false)
                    dispatch({
                        type: PUT_ADD_MISC_INVOICE_FAILURE,
                        payload: null
                    })
                }
            }])
        }
        setIsLoading(false);
    }, [error]);

    useEffect(() => {
        if (billingEntityDropdown && miscPlaceOfSupply) {
            setIsLoading(true)
            const billingEntity = billingEntities.find(each => each.partner_company_id === billingEntityDropdown.value)
            const placeOfSupply = miscPlaceOfSupply.value
            // api call to get the tax rates
            // invoices/transactionType
            const data = {
                "gst_number": billingEntity?.gst_number,
                "state": placeOfSupply
            }
            // gst_number = 29AAACW0000A1ZP & state=Karnataka
            axios.get(base_url + "invoices/transactionType?gst_number=" + billingEntity?.gst_number + "&state=" + placeOfSupply)
                .then((response) => {
                    const taxRates = response.data.data?.data
                    if (taxRates.igst === true) {
                        setIgstToggle(true)
                        const cgstInTable = miscInvoiceTable.some(each => each.cgst)
                        if (cgstInTable) {
                            const tableData = miscInvoiceTable.map(each => {
                                return {
                                    ...each,
                                    igst: each.cgst + each.sgst
                                }
                            })
                            setMiscInvoiceTable(tableData)
                        }
                    } else {
                        setIgstToggle(false)
                        const igstInTable = miscInvoiceTable.some(each => each.igst)
                        if (igstInTable) {
                            const tableData = miscInvoiceTable.map(each => {
                                return {
                                    ...each,
                                    cgst: each.igst / 2,
                                    sgst: each.igst / 2
                                }
                            })
                            setMiscInvoiceTable(tableData)
                        }
                    }
                    setIsLoading(false)
                })
                .catch((error) => {
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error ')
                    setAlertValue(error?.response?.data.message)
                    setAlertButtons([{
                        name: 'Close', action: () => {
                            setAlertShow(false)
                        }
                    }])
                    setIsLoading(false)
                })
        }
    }, [billingEntityDropdown, miscPlaceOfSupply])


    const submitToGSTPortal = (invoiceNumber) => {
        // endpoint - https://q73ion9zv2.execute-api.us-east-2.amazonaws.com/dev/api/invoices/submitToGSTPortal
// body - {"invoice_number": "SRST2425-001174"}
    
        dispatch(submitToGSTSubmissionPortal({invoice_number: invoiceNumber}))
}

const sendToCustomer = (invoiceNumber) => {
    setIsLoading(true)
    // dispatch(sendToCustomerAsEmail({invoice_number: invoiceNumber}))
    axios
    .post(base_url + "invoices/dispatchEmail", {invoice_number: invoiceNumber})
    .then((response) => {
        if(response.data.status === "success") {
            setIsLoading(false)
            setAlertShow(true)
            setAlertType('success')
            setAlertMessage('Success')
            setAlertValue(<>
                <p className="mb-3">Invoice <span style={{ fontWeight: 'bold' }}>{invoiceNumber}</span> Sent to Customer Successfully</p>
            </>)
            setAlertButtons([
                {
                    name: 'View Invoice', variant: 'outline-secondary', action: () => {
                        setAlertShow(false)
                        handleViewButton(invoiceNumber, 'emailed', 'miscellaneous')
                    }
                },
                {
                    name: 'Close', action: () => {
                        setAlertShow(false)
                        setCurrentPage(1);
                        backFunction();
                        backPagination()
                    }
                },
            ])
        }
    })
    .catch((error) => {
        setIsLoading(false)
        setAlertShow(true)
        setAlertType('error')
        setAlertMessage('Error ')
        setAlertValue(error?.response?.data.message)
        setAlertButtons([{
            name: 'Close', action: () => {
                setAlertShow(false)
            }
        }])
    })
}

    const calculateTotalBasicAmount = (table) => {
        const invoiceTable = table ? table : miscInvoiceTable
        const totalBasicAmount = invoiceTable.reduce((acc, each) => acc + parseFloat(each.basicAmount), 0)
        setMiscTotalBasicAmount(totalBasicAmount)
    }

    const calculateTotalTaxAmount = (table) => {
        const invoiceTable = table ? table : miscInvoiceTable
        let totalTaxAmount = 0
        if (igstToggle) {
            totalTaxAmount = invoiceTable.reduce((acc, each) => acc + parseFloat(each.basicAmount / 100) * (parseFloat(each.igst)), 0)
        }
        else {
            totalTaxAmount = invoiceTable.reduce((acc, each) => acc + parseFloat(each.basicAmount / 100) * (parseFloat(each.cgst) + parseFloat(each.sgst)), 0)
        }
        setMiscTotalTaxAmount(totalTaxAmount)
    }

    const generateInvoiceButtonClicked = () => {
        dispatch(commercialHeadGetBillingEntities())
        dispatch(commercialHeadGetCompleteContractsForDropdown(localStorage.getItem('selected_facility_id')))
    }

    const getContractDetails = (data) => {
        const contractNumber = data.split(',')[0]
        const contactType = data.split(',')[1]
        let url = ''
        if(contactType === 'FMS'){
            url = 'customers/fmsContracts'
        }else{
            url = 'customers/contracts'
        }
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios.get(base_url + url,{
            params: {
                filter_condition: JSON.stringify({contract_id: contractNumber})
            }
        })
            .then((response) => {
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false,
                });
                const data = response.data.data
                if(!editMiscInvoiceClicked ){
                const billingEntityId = data?.billing_entity
                //selecting place of supply
                const facilityId = data?.facility_id
                const facility = facilitiesListForNav.find(each => each.facility_id === facilityId)
                setMiscPlaceOfSupply({
                    label: facility?.state,
                    value: facility?.state
                })
                //getting facility name from local
                setFacilityName(facility?.name)
                //search and map billing entity
                const billingEntity = billingEntities.find(each => each.partner_company_id === billingEntityId)
                if (billingEntity) {
                    setBillingEntityDropdown({
                        label: billingEntity.company_name,
                        value: billingEntity.partner_company_id
                    })
                    if(billingEntity.logo){
                        getImage(billingEntity.logo)
                    }else{
                        setCompanyLogo(null)
                    }
                    setCompanyName(billingEntity.company_name)
                    const address = billingEntity?.registered_address
                    setCompanyAddress(`${address?.street_address_1}, ${address?.street_address_2}, ${address?.city}, ${address?.state}, ${address?.postal_code}`)
                    setPartnerState(address?.state)
                    setPartnerStateCode(billingEntity?.gst_number?.slice(0, 2))
                    setCompanyGST(billingEntity?.gst_number)
                    setPartnerPAN(billingEntity?.pan_number)
                    setCompanyCIN(billingEntity?.cin)
                }
                
                setContractNo(data?.contract_number)
                setcustomerName(data?.company_name)
                //set service Identifier
                setMiscInvoiceServiceIntifier(`${data?.space_name}, ${localStorage.getItem('selected_facility_name')}`)
                // setMiscPlaceOfSupply(data?.pos_state)
                setcustomerPlaceOfSupply(facility?.state)
                // billing adress 
                const billingAddress = data?.billing_address
                setcustomerAddress(` ${billingAddress?.street_address_1}, ${billingAddress?.street_address_2}, ${billingAddress?.city}, ${billingAddress?.state}, ${billingAddress?.postal_code}`)
                // shipping address
                const shippingAddress = data?.shipping_address
                if (shippingAddress) {
                    setStreetAddress(`${shippingAddress?.street_address_1}, ${shippingAddress?.street_address_2}`)
                    setCity(shippingAddress?.city)
                    setState(shippingAddress?.state)
                    setPincode(shippingAddress?.postal_code)
                    setToggleShippingAddress(true)
                }
                setcustomerGSTIN(data?.gst_number)
                setcustomerPAN(data?.pan_number)
                setcustomerCIN(data?.cin)
                setcustomerStateCode(data?.gst_number?.slice(0, 2))
                }

                const listOfContacts = data?.contacts?.map(each => {
                    return {
                        name: each.name,
                        contact_id: each.contact_id,
                        designation: each.designation,
                        sendEmail: true
                    }
                })
                setMiscListOfContacts(listOfContacts)


            })
            .catch((error) => {
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false,
                });
                setAlertShow(true)
                setAlertType('error')
                setAlertMessage('Error ')
                setAlertValue(error?.response?.data.message)
                setAlertButtons([{
                    name: 'Close', action: () => {
                        setAlertShow(false)
                    }
                }])
            })
    }

    const getContractDetailsForEdit = (data) => {
        const contractNumber = data.split(',')[0]
        const contactType = data.split(',')[1]
        let url = ''
        if(contactType === 'FMS'){
            url = 'customers/fmsContracts'
        }else{
            url = 'customers/contracts'
        }
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios.get(base_url + url ,{
            params: {
                filter_condition: JSON.stringify({contract_id: contractNumber})
            }
        })
            .then((response) => {
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false,
                });
                const data = response.data.data
                
                const billingEntityId = data?.billing_entity
                //selecting place of supply
                const facilityId = data?.facility_id
                const facility = facilitiesListForNav.find(each => each.facility_id === facilityId)
                setMiscPlaceOfSupply({
                    label: facility?.state,
                    value: facility?.state
                })
                //getting facility name from local
                setFacilityName(facility?.name)
                //search and map billing entity
                const billingEntity = billingEntities.find(each => each.partner_company_id === billingEntityId)
                if (billingEntity) {
                    setBillingEntityDropdown({
                        label: billingEntity.company_name,
                        value: billingEntity.partner_company_id
                    })
                    if(billingEntity.logo){
                        getImage(billingEntity.logo)
                    }else{
                        setCompanyLogo(null)
                    }
                    setCompanyName(billingEntity.company_name)
                    const address = billingEntity?.registered_address
                    setCompanyAddress(`${address?.street_address_1}, ${address?.street_address_2}, ${address?.city}, ${address?.state}, ${address?.postal_code}`)
                    setPartnerState(address?.state)
                    setPartnerStateCode(billingEntity?.gst_number?.slice(0, 2))
                    setCompanyGST(billingEntity?.gst_number)
                    setPartnerPAN(billingEntity?.pan_number)
                    setCompanyCIN(billingEntity?.cin)
                }
                
                setContractNo(data?.contract_number)
                setcustomerName(data?.company_name)
                //set service Identifier
                setMiscInvoiceServiceIntifier(`${data?.space_name}, ${localStorage.getItem('selected_facility_name')}`)
                // setMiscPlaceOfSupply(data?.pos_state)
                setcustomerPlaceOfSupply(facility?.state)
                // billing adress 
                const billingAddress = data?.billing_address
                setcustomerAddress(` ${billingAddress?.street_address_1}, ${billingAddress?.street_address_2}, ${billingAddress?.city}, ${billingAddress?.state}, ${billingAddress?.postal_code}`)
                // shipping address
                const shippingAddress = data?.shipping_address
                if (shippingAddress) {
                    setStreetAddress(`${shippingAddress?.street_address_1}, ${shippingAddress?.street_address_2}`)
                    setCity(shippingAddress?.city)
                    setState(shippingAddress?.state)
                    setPincode(shippingAddress?.postal_code)
                    setToggleShippingAddress(true)
                }
                setcustomerGSTIN(data?.gst_number)
                setcustomerPAN(data?.pan_number)
                setcustomerCIN(data?.cin)
                setcustomerStateCode(data?.gst_number?.slice(0, 2))
                

                const listOfContacts = data?.contacts?.map(each => {
                    return {
                        name: each.name,
                        contact_id: each.contact_id,
                        designation: each.designation,
                        sendEmail: true
                    }
                })
                setMiscListOfContacts(listOfContacts)


            })
            .catch((error) => {
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false,
                });
                setAlertShow(true)
                setAlertType('error')
                setAlertMessage('Error ')
                setAlertValue(error?.response?.data.message)
                setAlertButtons([{
                    name: 'Close', action: () => {
                        setAlertShow(false)
                    }
                }])
            })
    }

    const addMiscInvoice = () => {
        const tableDetails = miscInvoiceTable?.map(each => {
            if (igstToggle) {
                return {
                    service_name_in_invoice: each.service,
                    hsn_code: each.hsn,
                    net_amount: parseFloat(each.basicAmount),
                    igst_rate: parseInt(each.igst),
                }
            } else {
                return {
                    service_name_in_invoice: each.service,
                    hsn_code: each.hsn,
                    net_amount: parseFloat(each.basicAmount),
                    cgst_rate: parseFloat(each.cgst),
                    sgst_rate: parseFloat(each.sgst),
                }
            }
        })
        let listOfContacts = []
        miscListOfContacts?.forEach(each => {
            if (each.sendEmail) {
                listOfContacts.push(each?.contact_id)
            }
        })
        const data = {
            place_of_supply: miscPlaceOfSupply?.value,
            invoice_details: tableDetails,
            billing_entity: billingEntityDropdown?.value,
            contract_name: miscContractName?.label,
            misc_list_of_contacts: listOfContacts,
        }
        dispatch(createNewMiscInvoice(data))
    }

    const editMiscInvoice = () => {
        const tableDetails = miscInvoiceTable?.map(each => {
            if (igstToggle) {
                return {
                    service_name_in_invoice: each.service,
                    hsn_code: each.hsn?.toString(),
                    net_amount: parseFloat(each.basicAmount),
                    igst_rate: parseInt(each.igst),
                }
            } else {
                return {
                    service_name_in_invoice: each.service,
                    hsn_code: each.hsn?.toString(),
                    net_amount: parseFloat(each.basicAmount),
                    cgst_rate: parseFloat(each.cgst),
                    sgst_rate: parseFloat(each.sgst),
                }
            }
        })
        let listOfContacts = []
        miscListOfContacts?.forEach(each => {
            if (each.sendEmail) {
                listOfContacts.push(each?.contact_id)
            }
        })
        const data = {
            invoice_number: invoiceNo,
            place_of_supply: miscPlaceOfSupply?.value,
            invoice_details: tableDetails,
            billing_entity: billingEntityDropdown?.value,
            contract_name: miscContractName?.label,
            misc_list_of_contacts: listOfContacts,
        }
        dispatch(editAMiscInvoice(data))
    }

    const handleFilesSelected = (files) => {
        const processedFiles = [];
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = function () {
                const base64FileContent = reader.result.split(",")[1];
                // const required_id = selectedDraftContract && selectedDraftContract.draft_id
                const processedFile = {
                    file_content: base64FileContent,
                    object_type: "invoice_documents",
                    file_description: file.description,
                    file_type: file.file.type.split("/")[1],
                    file_name: file.file.name,
                    // draft_id:required_id,
                    contract_name: miscContractName && miscContractName.label,
                    file_size: file.file.size,
                    flag: file.flag,
                    user_id: localStorage.getItem("username"),
                };
                processedFiles.push(processedFile);
                if (processedFiles.length === files.length) {
                    setUploadedFiles(processedFiles);
                }
            };

            reader.readAsDataURL(file.file);
        });
    };
    const handleDocumentUpload = (index) => {
        dispatch(handleDocumentLoaderTrue())
        dispatch(commercialHeadUploadDocument(
            {
                files: [uploadedFiles[index]]
            }
        ))
    }

    const addMiscInvoiceValidation = () => {
        let isValid = billingEntityDropdown
            && miscContractName && miscPlaceOfSupply
            && miscInvoiceTable.length > 0 && miscInvoiceTable.every(each => each.service && each.hsn && each.basicAmount && !each.hsnError)
            && miscListOfContacts.length > 0 && miscListOfContacts.some(each => each.sendEmail)
        return isValid
    }



    const states = [
        'Delhi',
        'Andhra Pradesh',
        'Arunachal Pradesh',
        'Assam',
        'Bihar',
        'Chhattisgarh',
        'Goa',
        'Gujarat',
        'Haryana',
        'Himachal Pradesh',
        'Jharkhand',
        'Karnataka',
        'Kerala',
        'Madhya Pradesh',
        'Maharashtra',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Nagaland',
        'Odisha',
        'Punjab',
        'Rajasthan',
        'Sikkim',
        'Tamil Nadu',
        'Telangana',
        'Tripura',
        'Uttar Pradesh',
        'Uttarakhand',
        'West Bengal'
    ];

    const stateOptions = states.map(state => ({
        label: state,
        value: state
    }));

    const typesOfInvoices = [
        {
            name: "Awaiting FM approval",
            id: "draft"
        },
        {
            name: "Awaiting GST submission",
            id: "approved"
        },
        {
            name: "GST submitted Invoices",
            id: "gstsubmitted"
        },
        {
            name: 'PDF Generated',
            id: 'pdfgenerated'
        },
        {
            name: 'Sent to Customer',
            id: 'emailed'
        },

        {
            name: "On-Hold Invoices",
            id: "onhold"
        },
        // {
        //     name : "Generated Invoices",
        //     id : "generated"
        // },
        {
            name: "Rejected Invoices",
            id: "rejected"
        },


    ]

    const slfDetails = [
        {
            year: 2024,
            space: "Unit 11",
            license_fee: "10,000",
            escation: 10,
            start_date: "12/12/2023",
            end_date: "12/12/2024",
            locking_period: "7 days",
            Payement: "7 Days"
        },
        {
            year: 2024,
            space: "Unit 11",
            license_fee: "10,000",
            escation: 10,
            start_date: "12/12/2023",
            end_date: "12/12/2024",
            locking_period: "7 days",
            Payement: "7 Days"
        },
        {
            year: 2024,
            space: "Unit 11",
            license_fee: "10,000",
            escation: 10,
            start_date: "12/12/2023",
            end_date: "12/12/2024",
            locking_period: "7 days",
            Payement: "7 Days"
        },
        {
            year: 2024,
            space: "Unit 11",
            license_fee: "10,000",
            escation: 10,
            start_date: "12/12/2023",
            end_date: "12/12/2024",
            locking_period: "7 days",
            Payement: "7 Days"
        },
        {
            year: 2024,
            space: "Unit 11",
            license_fee: "10,000",
            escation: 10,
            start_date: "12/12/2023",
            end_date: "12/12/2024",
            locking_period: "7 days",
            Payement: "7 Days"
        }
    ]

    useEffect(() => {
        if (invoicesListError) {
            setInvoiceListErrorFlag(true)
        }
    }, [invoicesListError])

    useEffect(() => {
        setListOfPartnerCompanies(partnerCompaniesList)
    }, [partnerCompaniesList])

    useEffect(() => {
        if (partnerCompaniesListError) {
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error ')
            setAlertValue(partnerCompaniesListError)
            setAlertButtons([{
                name: 'Close', action: () => {
                    setAlertShow(false)
                }
            }])
        }
        setIsLoading(false);
    }, [partnerCompaniesListError])

    useEffect(() => {
        if (generateInvoiceStatus === "success") {
            handleViewButton(invoiceNo, 'draft', invoiceType)
        }
    }, [generateInvoiceStatus])

    const filterFuntion = () => {
        const filterId = invoiceType === "pending" ? "approved" : invoiceType
        let data = invoicesList.filter(each => each.status === filterId)
        if (invoiceType === "pending") {
            data = data.filter(each => each.approval_status == "No")
            //    setFilteredData(filteredData)
        }
        else if (invoiceType === "approved") {
            data = data.filter(each => each.approval_status == "Yes")
            // setFilteredData(filteredData)
        }
        if (invoiceSelected !== "") {
            data = data.filter(each => each.invoice_type === invoiceSelected)
        }
        const text = searchText.toLowerCase();
        if (text) {
            data = data.filter((each) => {
                return each.invoice_number.toLowerCase().includes(text) ||
                    each.company_name.toLowerCase().includes(text)
            });
        }
        // if (biilingPeriodFilter !== "") {
        //     data = data.filter(each => each.billing_period === format(biilingPeriodFilter, 'MMM yyyy'))
        // }
        setTotalInvoices(data.length)
        setFilteredData(data)

    }


    useEffect(() => {
        applyFilter()
    }, [])

    useEffect(() => {

        if (invoicesList) {
            setFilteredData(invoicesList)
        }
        else {
            setFilteredData([])
        }
    }, [invoicesList])


    useEffect(() => {
        if (editInvoiceSubmisssion) {
            let approvalTypeCapital = approvalType
            approvalTypeCapital = approvalTypeCapital[0]?.toLocaleUpperCase() + approvalTypeCapital?.slice(1)
            setAlertShow(true)
            setAlertType('success')
            setAlertMessage('Success')
            setAlertValue(<>
                {invoiceStatusTo === "approved" && <><b>{approvalTypeCapital}</b> Invoice <b>{invoiceNo}</b> has been approved</>}
                {invoiceStatusTo === "rejected" && <><b>{approvalTypeCapital}</b> Invoice <b>{invoiceNo}</b> has been rejected</>}
                {invoiceStatusTo === "onhold" && <><b>{approvalTypeCapital}</b> Invoice <b>{invoiceNo}</b> has been put on hold</>}
            </>)
            setAlertButtons([{
                name: 'Close', action: () => {
                    setAlertShow(false)
                    // window.location.reload() //going to current page one with same filtered data
                    backFunction()
                    applyFilter()
                }
            }])
        }
        setIsLoading(false)
    }, [editInvoiceSubmisssion])


    useEffect(() => {
        if (error?.response?.data.message) {
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error ' + invoiceType)
            setAlertValue(error?.response?.data.message)
            setAlertButtons([{ name: 'Close', action: () => { setAlertShow(false) } }])
        }
        setIsLoading(false);
    }, [error]);

    const applyFilter = () => {
        const month = format(biilingPeriodFilter, 'MMMM yyyy');
        const fullYear = format(biilingPeriodFilter, 'yyyy');
        const monthNumber = new Date(month + ' 1, ' + fullYear).getMonth() + 1;
        dispatch({
            type: SET_PAGINATION_COUNT_TO,
            payload: 1
        })
        dispatch(getAllInvoices({ page: 1 && 1, limit: RECORDS_PER_PAGE, facility_id: localStorage.getItem("selected_facility_id"), invoiceType, monthNumber, fullYear, searchText, invoiceSelected }))
        setInvoiceListErrorFlag(false)
    }
    const backPagination = () => {
        const month = format(biilingPeriodFilter, 'MMMM yyyy');
        const fullYear = format(biilingPeriodFilter, 'yyyy');
        const monthNumber = new Date(month + ' 1, ' + fullYear).getMonth() + 1;
        dispatch({
            type: SET_PAGINATION_COUNT_TO,
            payload: 1
        })
        dispatch(getAllInvoices({ page: currentPaginationPageAc, limit: RECORDS_PER_PAGE, facility_id: localStorage.getItem("selected_facility_id"), invoiceType, monthNumber, fullYear, searchText, invoiceSelected }))
        setInvoiceListErrorFlag(false)
    }

    const changeDateFormat = () => {
        const date = format(biilingPeriodFilter, 'MMM yyyy')
        return date

    }

    const handledPaginationNextClicked = () => {
        const month = format(biilingPeriodFilter, 'MMMM yyyy');
        const fullYear = format(biilingPeriodFilter, 'yyyy');
        const monthNumber = new Date(month + ' 1, ' + fullYear).getMonth() + 1;
        handlePaginationLoaderTrue()
        setInvoiceListErrorFlag(false)
        dispatch(getAllInvoices({ invoiceType, facility_id: localStorage.getItem("selected_facility_id"), monthNumber, invoiceSelected, fullYear, serach_text: searchText, limit: RECORDS_PER_PAGE, page: currentPaginationPageAc + 1 }))
    }

    const handledPaginationPrevClicked = () => {
        const month = format(biilingPeriodFilter, 'MMMM yyyy');
        const fullYear = format(biilingPeriodFilter, 'yyyy');
        const monthNumber = new Date(month + ' 1, ' + fullYear).getMonth() + 1;
        setInvoiceListErrorFlag(false)
        dispatch(getAllInvoices({ invoiceType, facility_id: localStorage.getItem("selected_facility_id"), monthNumber, invoiceSelected, fullYear, serach_text: searchText, limit: RECORDS_PER_PAGE, page: currentPaginationPageAc - 1 }))

    }


    function formatAddressWithCapitalization(address) {
        const addressComponents = address?.split(';;');
        const formattedAddress = addressComponents
            .map(component => component
                .toLowerCase()
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
            ).join(', ');
        return formattedAddress;
    }


    const handleDownload = () => {
        setIsLoading(true);
        axios.get(`${base_url}documents/invoiceDocuments`, {
            params: {
                filter_condition: JSON.stringify({
                    s3_keys: [downloadPath]
                })
            },
        }
        ) // Get JSON response
            .then((response) => {
                let data = response.data?.data[0]?.invoice; // Assuming the base64-encoded PDF is inside this path
                if (!data) {
                    return;
                }
                // Remove b' prefix and trailing '
                data = data.replace(/^b'|^b"|['"]$/g, '');
                // Create a data URL with base64 content
                const link = document.createElement('a');
                link.href = `data:application/pdf;base64,${data}`;
                link.setAttribute('download', 'invoice.pdf'); // Set the file name for download
                document.body.appendChild(link); // Append the link to the DOM
                link.click(); // Programmatically click the link to trigger the download
                link.remove(); // Remove the link after triggering download
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                if (error) {
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error ')
                    setAlertValue(error?.response?.data.message)
                    setAlertButtons([{
                        name: 'Close', action: () => {
                            setAlertShow(false)
                            dispatch({
                                type: PUT_ADD_MISC_INVOICE_FAILURE,
                                payload: null
                            })
                        }
                    }])
                }
                setIsLoading(false);
            });
    };
    const handleGeneratePdf = () => {
        setIsLoading(true);
        axios.post(`${base_url}invoices/generatePdf`, {
            invoice_number: invoiceNo
        }
        ) // Get JSON response
            .then((response) => {
                if(response.data.status === 'success'){
                    setAlertShow(true)
                    setAlertType('success')
                    setAlertMessage('Success')
                    setAlertValue(
                        <p className="mb-3">Invoice <span style={{ fontWeight: 'bold' }}>{invoiceNo}</span> Generated PDF Successfully</p>
                    )
                    setAlertButtons([
                        {
                            name: 'view Invoice', variant: 'outline-secondary', action: () => {
                                setAlertShow(false)
                                handleViewButton(invoiceNo, 'pdfgenerated', 'miscellaneous')
                            }
                        },
                        {
                        name: 'Close', action: () => {
                            setAlertShow(false)
                            setIsLoading(false);
                            setCurrentPage(1);
                            backFunction();
                            backPagination()
                            dispatch({
                                type: PUT_GST_SUBMIT_SUCCESS,
                                payload: null
                            })
                            }
                    }])
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                if (error) {
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error ')
                    setAlertValue(error?.response?.data.message)
                    setAlertButtons([{
                        name: 'Close', action: () => {
                            setAlertShow(false)
                            dispatch({
                                type: PUT_ADD_MISC_INVOICE_FAILURE,
                                payload: null
                            })
                        }
                    }])
                }
                setIsLoading(false);
            });
    };



    const getDocuments1 = (footer) => {
        const payementDoc = footer?.payment_terms
        const termsDoc = footer?.terms_and_conditions
        let url = '%7B%22s3_keys%22%3A%5B%22'
        if (payementDoc && termsDoc) {
            url = url + payementDoc + '%22%2C%22' + termsDoc + '%22%5D%7D'
        }
        else if (payementDoc) {
            url = url + payementDoc + '%22%5D%7D'
        }
        else if (termsDoc) {
            url = url + termsDoc + '%22%5D%7D'
        }
        if (url !== '%7B%22s3_keys%22%3A%5B%22') {
            dispatch({
                type: SET_LOADER,
                payload: true
            })
            axios.get(`${base_url}documents?filter_condition=${url}`)
                .then(response => {
                    if (response?.data?.data[0] === 'error') {
                        setAlertShow(true)
                        setAlertType('error')
                        setAlertMessage('Error')
                        setAlertValue(response?.data?.data[1].message)
                        setAlertButtons([
                            {
                                name: 'Close', action: () => {
                                    setAlertShow(false)
                                    // window.location.reload()
                                }
                            },
                        ])
                        dispatch({
                            type: SET_LOADER,
                            payload: false
                        })
                    }
                    else {
                        const documentData = response.data.data
                        if (payementDoc && termsDoc) {
                            const trimmedData1 = documentData[0].file?.slice(2, -1)
                            const trimmedData2 = documentData[1].file?.slice(2, -1)
                            setpdfDataForPayments(trimmedData1)
                            setpdfDataForTerms(trimmedData2)
                            setPaymentToggle(true)
                            setTermsToggle(true)
                        }
                        else if (payementDoc) {
                            const trimmedData = documentData[0].file?.slice(2, -1)
                            setpdfDataForPayments(trimmedData)
                            setPaymentToggle(true)
                        }
                        else if (termsDoc) {
                            const trimmedData = documentData[0].file?.slice(2, -1)
                            setpdfDataForTerms(trimmedData)
                            setTermsToggle(true)
                        }
                        dispatch({
                            type: SET_LOADER,
                            payload: false
                        })
                    }
                })
                .catch(error => {
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(error?.response?.data.message)
                    setAlertButtons([
                        {
                            name: 'Close', action: () => {
                                setAlertShow(false)
                            }
                        },
                    ])
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                })
        }

    }

    const getDocuments = (footer) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        const payementDoc = footer?.payment_terms
        const termsDoc = footer?.terms_and_conditions
        if (payementDoc) {
            let url = '%7B%22s3_keys%22%3A%5B%22' + payementDoc + '%22%5D%7D'
            axios.get(`${base_url}documents?filter_condition=${url}`)
                .then(response => {
                    const documentData = response.data.data[0].file
                    const trimmedData = documentData.slice(2, -1)
                    setpdfDataForPayments(trimmedData)
                    setPaymentToggle(true)

                })
                .catch(error => {
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(error?.response?.data.message)
                    setAlertButtons([
                        {
                            name: 'Close', action: () => {
                                setAlertShow(false)
                                // window.location.reload()
                            }
                        },
                    ])
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
                )
        }
        if (termsDoc) {
            setIsLoading(true)
            let url = '%7B%22s3_keys%22%3A%5B%22' + termsDoc + '%22%5D%7D'
            axios.get(`${base_url}documents?filter_condition=${url}`)
                .then(response => {
                    const documentData = response.data.data[0].file
                    const trimmedData = documentData.slice(2, -1)
                    setTermsToggle(true)
                    setpdfDataForTerms(trimmedData)
                    setIsLoading(false)
                })
                .catch(error => {
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(error?.response?.data.message)
                    setAlertButtons([
                        {
                            name: 'Close', action: () => {
                                setAlertShow(false)
                                // window.location.reload()
                            }
                        },
                    ])
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
                )
        }
    }

    const getImage = (logo) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.get(`${base_url}documents?filter_condition=%7B%22s3_keys%22%3A%5B%22${logo}%22%5D%7D`)
            .then(response => {
                const documentData = response.data.data[0].file
                const trimmedData = 'data:image/webp;base64,' + documentData.slice(2, -1)
                if (trimmedData) {
                    setCompanyLogo(trimmedData)
                }
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
            .catch(error => {
                setCompanyLogo(null)
                setAlertShow(true)
                setAlertType('error')
                setAlertMessage('Error')
                setAlertValue(error?.response?.data.message)
                setAlertButtons([
                    {
                        name: 'Close', action: () => {
                            setAlertShow(false)
                        }
                    },
                ])
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            }
            )
    }

    const [serviceNameInInvoice, setServiceNameInInvoice] = useState('')


    const handleViewButton = (id, status, type) => {
        setIsLoading(true)
        setInvoiceStatus(status)
        const url = `${base_url}invoices/get_invoice?invoice_number=${id}`
        axios.get(url)
            .then(response => {
                const invoiceData = response.data.data
                const invoiceMaster = invoiceData.invoice_master
                const invoiceDetails = invoiceData.invoice_details
                const invoiceFooter = invoiceData.invoice_footer
                const invoiceHeader = invoiceData?.invoice_header
                if (invoiceFooter.payment_terms || invoiceFooter.terms_and_conditions) {
                    getDocuments1(invoiceFooter)
                }
                if (invoiceHeader?.partner_logo) {
                    getImage(invoiceHeader?.partner_logo)
                }else{
                    setCompanyLogo(null)
                }
                let isIgst
                invoiceDetails.some(e => e.igst > 0 ? isIgst = true : isIgst = false)
                if (isIgst) {
                    setIgstToggle(true)
                    // const totalTaxAmount = invoiceDetails.reduce((acc, each) => acc + parseFloat(each.igst), 0)
                    // setTotalTaxAmount(invoiceMaster?.total_tax_amount)
                    setTotalIgst(invoiceMaster?.total_igst)
                } else {
                    // const totalTaxAmount = invoiceDetails.reduce((acc, each) => acc + parseFloat(each.cgst) + parseFloat(each.sgst), 0)
                    setTotalSgst(invoiceMaster?.total_sgst)
                    setTotalCgst(invoiceMaster?.total_cgst)
                }
                const detailsBasicAmount = invoiceDetails.reduce((acc, each) => acc + parseFloat(each.net_amount), 0)
                const totalAmountInWords = convertCurrencyToWords(invoiceMaster.gross_amount)
                const addressComponents = invoiceHeader?.partner_address.split(';;');
                const formattedAddress = formatAddressWithCapitalization(invoiceHeader?.partner_address)
                const state = addressComponents.length > 1 ? addressComponents[addressComponents.length - 2] : '';
                const stateVariable = state;
                const addressComponentsC = invoiceHeader?.customer_address.split(';;');
                const formattedAddressC = invoiceHeader?.billing_address ? formatAddressWithCapitalization(invoiceHeader?.billing_address) : ''
                const stateC = addressComponentsC.length > 1 ? addressComponentsC[addressComponentsC.length - 3] : '';
                const stateVariableC = stateC;
                // const shippingAddress = invoiceHeader?.shipping_address
                // if (shippingAddress) {
                //     const shipppingData = shippingAddress.split(';;')
                //     setStreetAddress(formatAddressWithCapitalization(shipppingData[0]))
                //     setCity(formatAddressWithCapitalization(shipppingData[1]))
                //     setState(formatAddressWithCapitalization(shipppingData[2]))
                //     setPincode(formatAddressWithCapitalization(shipppingData[3]))
                //     setToggleShippingAddress(true)
                // }
                if (invoiceMaster?.show_computation_details === 1) {
                    setShowComputationDetails(true)
                }
                if (invoiceFooter?.show_pterms === 1) {
                    setShowPaymentDetails(true)
                }
                if (invoiceFooter?.show_tandc === 1) {
                    setShowTermsDetails(true)
                }
                setServiceNameInInvoice(invoiceMaster?.service_name_in_invoice)
                setDownloadPath(invoiceMaster?.pdf_path_on_s3)
                setCompanyName(invoiceMaster.company_name)
                setFacilityName(invoiceMaster?.facility_name)
                setInvoiceId(invoiceMaster?.invoice_id)
                setSlfInvoiceName(invoiceDetails?.service_name_in_invoice)
                setInvoiceDetails([...invoiceDetails])
                setCompanyCIN(invoiceHeader?.partner_cin)
                setCompanyName(invoiceHeader?.partner_name?.toUpperCase())
                setQrCode(invoiceHeader?.qr_code)
                setcustomerCIN(invoiceHeader?.customer_cin)
                setCompanyGST(invoiceHeader?.partner_gstin)
                setCompanyPhone(invoiceHeader?.partner_phone)
                setCompanyAddress(invoiceHeader?.partner_address)
                // setCompanyLogo(invoiceHeader?.partner_logo)
                setcustomerName(invoiceHeader?.customer_name)
                setContractNo(invoiceMaster?.contract_number)
                setcustomerGSTIN(invoiceHeader?.customer_gstin)
                setcustomerPAN(invoiceHeader?.customer_pan)
                setIRN(invoiceHeader?.irn_number)
                setAckNo(invoiceHeader?.gst_portal_ack_no)
                setAckDate(formatDateView(invoiceHeader?.gst_portal_ack_date))
                setCompanyAddress(formattedAddress);
                setcustomerAddress(formattedAddressC)
                setcustomerPlaceOfSupply(invoiceMaster?.pos_state)
                setPartnerBillDate(formatDateView(invoiceHeader?.issue_date))
                setPartnerDueDate(formatDateView(invoiceMaster?.payment_due_date))
                setPartnerState(stateVariable)
                setPartnerPAN(invoiceHeader?.partner_pan)
                setPartnerCIN(invoiceHeader?.partner_cin)
                setInvoiceNo(invoiceHeader?.invoice_number)
                setPartnerStateCode(invoiceHeader?.partner_gstin.substring(0, 2))
                setcustomerStateCode(invoiceHeader?.customer_gstin.substring(0, 2))
                setBasicAmount(invoiceMaster?.total_basic_amount)
                setTotalAmount(invoiceMaster.gross_amount)
                setTotalAmountInWords(invoiceMaster?.amount_in_words);
                setTimeout(() => {
                    setIsLoading(false)
                })
                setToggleShippingAddress(true)
                let companyAddress = invoiceHeader?.partner_address?.replace(/;;/g, ', ');
                let customerAddress = invoiceHeader?.customer_address?.replace(/;;/g, ', ');
                let billingAddress = invoiceHeader?.billing_address?.replace(/;;/g, ', ');
                let shippingAddress = invoiceHeader?.shipping_address?.replace(/;;/g, ', ');
                setCompanyAddress(companyAddress)
                setcustomerAddress(billingAddress)
                setShippingAddress(shippingAddress)
            })
            .catch(error => {
                setAlertShow(true)
                setAlertType('error')
                setAlertMessage('Error')
                setAlertValue(error?.response?.data.message)
                setAlertButtons([{
                    name: 'Close', action: () => {
                        setAlertShow(false)
                    }
                }])
                setIsLoading(false)
            })

        if (type === "electricity") {
            setCurrentPage(2)
        } else if (type === "water") {
            setCurrentPage(4)
        } else if (type === "slf") {
            setCurrentPage(5)
        } else if (type === "fms") {
            setCurrentPage(6)
        }
        else if (type === "miscellaneous") {
            setCurrentPage(7)
        }

    }

    const checkSendForApprovalValidation = () => {
        if (toggleShippingAddress) {
            // return streetAddress && city && state && pincode && streetAddress !== "" && city !== "" && state !== "" && pincode !== "" && pincode.length === 6
            return true
        } else {
            return true
        }
    }

    const sendForApproval = () => {
        setIsLoading(true)
        const body = {
            "invoice_number": invoiceNo,
            "invoice_id": invoiceId,
            // "bill_from": companyName,
            // "payment_terms": paymentToggle,
            // "terms_and_conditions": termsToggle,
            // "notes": userNotes,
            "status": "approved"
        }
        // if (toggleShippingAddress) {
        //     body.shipping_address = {
        //         "street_address": streetAddress,
        //         "city": city,
        //         "state": state,
        //         "postal_code": pincode
        //     }
        // }
        dispatch({
            type: PUT_INVOICE_DATA_SUCCESS,
            payload: null
        })
        setInvoiceStatusTo('approved')
        dispatch(putInvoiceData(body))
    }

    const rejectAnInvoice = () => {
        const body = {
            "invoice_number": invoiceNo,
            "invoice_id": invoiceId,
            'rejected_reason': rejectReason,
            "status": "rejected"
        }
        dispatch({
            type: PUT_INVOICE_DATA_SUCCESS,
            payload: null
        })
        setInvoiceStatusTo('rejected')
        dispatch(putInvoiceData(body))
    }

    const holdInvoice = () => {
        const body = {
            "invoice_number": invoiceNo,
            "invoice_id": invoiceId,
            "status": "onhold"
        }
        dispatch({
            type: PUT_INVOICE_DATA_SUCCESS,
            payload: null
        })
        setInvoiceStatusTo('onhold')
        dispatch(putInvoiceData(body))
    }

    const handleReject = () => {
        setRejectModal(true)
    }
    const handleHold = () => {
        setAlertShow(true)
        setAlertType('question')
        setAlertMessage('')
        setAlertValue('Do you want to put this invoice On-hold?')
        setAlertButtons([{ name: 'Close', action: () => { setAlertShow(false) } },
        {
            name: 'Proceed', action: () => {
                setAlertShow(false)
                holdInvoice()
            }
        }
        ])
    }
    const handleRegenerate = () => {
        setAlertShow(true)
        setAlertType('question')
        setAlertMessage('')
        setAlertValue('Do you want to regenerate this invoice?')
        setAlertButtons([{ name: 'Close', action: () => { setAlertShow(false) } },
        {
            name: 'Proceed', action: () => {
                setAlertShow(false)
                regenerateInvoice()
            }
        }
        ])
    }
    const regenerateInvoice = () => {
        const body = {
            "invoice_number": invoiceNo,
        }
        dispatch({
            type: REGENERATE_INVOICE_DATA_SUCCESS,
            payload: null
        })
        setInvoiceStatusTo('draft')
        dispatch(regenerateAnInvoice(body))
    }

    const editInvoice = () => {
        setAlertShow(true)
        setAlertType('question')
        setAlertMessage('')
        setAlertValue('Do you approve this invoice?')
        setAlertButtons([{ name: 'Close', action: () => { setAlertShow(false) } },
        {
            name: 'Proceed', action: () => {
                setAlertShow(false)
                sendForApproval()
            }
        }
        ])
    }


    const numberComponent = (value) => {
        let x = value?.toString();
        let afterPoint = '';
        if (x?.indexOf('.') > 0)
            afterPoint = x?.substring(x?.indexOf('.'), x?.length);
        x = Math.floor(x);
        x = x?.toString();
        let lastThree = x?.substring(x.length - 3);
        let otherNumbers = x?.substring(0, x.length - 3);
        if (otherNumbers !== '')
            lastThree = ',' + lastThree;
        let res = otherNumbers?.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint;
        return res;
    };

    const formatterComponent = (value) => {
        const nFormat = new Intl.NumberFormat()
        const formattedNumber = nFormat.format(value)
        return formattedNumber
    }

    const handleMiscDueDateChange = (e) => {
        setMiscDueDate(e.target.value)
    }

    function numberToIndianWords(number) {
        const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
        const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
        const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

        if (number === 0) {
            return 'zero';
        }

        function convertLessThanThousand(num) {
            let str = '';
            if (num >= 100) {
                str += ones[Math.floor(num / 100)] + ' hundred ';
                num %= 100;
            }
            if (num >= 20) {
                str += tens[Math.floor(num / 10)] + ' ';
                num %= 10;
            }
            if (num > 0) {
                if (num < 10) {
                    str += ones[num];
                } else {
                    str += teens[num - 10];
                }
            }
            return str.trim();
        }

        function convert(num) {
            let str = '';
            if (num >= 10000000) {
                str += convert(Math.floor(num / 10000000)) + ' crore ';
                num %= 10000000;
            }
            if (num >= 100000) {
                str += convertLessThanThousand(Math.floor(num / 100000)) + ' lakh ';
                num %= 100000;
            }
            if (num >= 1000) {
                str += convertLessThanThousand(Math.floor(num / 1000)) + ' thousand ';
                num %= 1000;
            }
            if (num > 0) {
                str += convertLessThanThousand(num);
            }
            return str.trim();
        }

        const numArray = number?.toString().split('.');
        const wholeNumberPart = parseInt(numArray[0]);
        const decimalPart = parseInt(numArray[1] || 0);

        let result = convert(wholeNumberPart);
        if (decimalPart !== 0) {
            result += ' and ' + convert(decimalPart) + " paisa only ";
        }

        function capitalizeFirstLetter(str) {
            return str.charAt(0)?.toUpperCase() + str.slice(1);
        }

        return capitalizeFirstLetter(result);
    }

    //sorting functions 
    const sortInvoiceNo = () => {
        const sortedData = filteredData?.sort((a, b) => {
            if (sortOrder) {
                return a.invoice_number?.localeCompare(b.invoice_number);
            } else {
                return b.invoice_number?.localeCompare(a.invoice_number);
            }
        })
        setFilteredData([...sortedData]);
        setSortOrder(!sortOrder);
    }

    const sortInvoiceType = () => {
        const sortedData = filteredData?.sort((a, b) => {
            if (sortOrder) {
                return a.invoice_type?.localeCompare(b.invoice_type);
            } else {
                return b.invoice_type?.localeCompare(a.invoice_type);
            }
        })
        setFilteredData([...sortedData]);
        setSortOrder(!sortOrder);
    }

    const sortCompanyName = () => {
        const sortedData = filteredData?.sort((a, b) => {
            if (sortOrder) {
                return a.user_name?.localeCompare(b.user_name);
            } else {
                return b.user_name?.localeCompare(a.user_name);
            }
        })
        setFilteredData([...sortedData]);
        setSortOrder(!sortOrder);
    }

    const sortBillingPeriod = () => {
        const sortedData = filteredData?.sort((a, b) => {
            if (sortOrder) {
                return a.billing_period?.localeCompare(b.billing_period);
            } else {
                return b.billing_period?.localeCompare(a.billing_period);
            }
        })
        setFilteredData([...sortedData]);
        setSortOrder(!sortOrder);
    }

    const sortPreviousInvoiceAmount = () => {
        const sortedData = filteredData?.sort((a, b) => {
            if (sortOrder) {
                return parseFloat(a.previous_amount) - parseFloat(b.previous_amount);
            } else {
                return parseFloat(b.previous_amount) - parseFloat(a.previous_amount);
            }
        })
        setFilteredData([...sortedData]);
        setSortOrder(!sortOrder);
    }

    const sortCurrentInvoiceAmount = () => {
        const sortedData = filteredData?.sort((a, b) => {
            if (sortOrder) {
                return parseFloat(a.amount) - parseFloat(b.amount);
            } else {
                return parseFloat(b.amount) - parseFloat(a.amount);
            }
        })
        setFilteredData([...sortedData]);
        setSortOrder(!sortOrder);
    }

    const sortUpdatedBy = () => {
        const sortedData = filteredData?.sort((a, b) => {
            if (sortOrder) {
                return a.updated_by?.localeCompare(b.updated_by);
            } else {
                return b.updated_by?.localeCompare(a.updated_by);
            }
        })
        setFilteredData([...sortedData]);
        setSortOrder(!sortOrder);
    }

    const sortUpdatedOn = () => {
        const sortedData = filteredData?.sort((a, b) => {
            if (sortOrder) {
                return a.updated_on?.localeCompare(b.updated_on);
            } else {
                return b.updated_on?.localeCompare(a.updated_on);
            }
        })
        setFilteredData([...sortedData]);
        setSortOrder(!sortOrder);
    }

    const sortHeldBy = () => {
        const sortedData = filteredData?.sort((a, b) => {
            if (sortOrder) {
                return a.held_by?.localeCompare(b.held_by);
            } else {
                return b.held_by?.localeCompare(a.held_by);
            }
        })
        setFilteredData([...sortedData]);
        setSortOrder(!sortOrder);
    }

    const sortRejectedBy = () => {
        const sortedData = filteredData?.sort((a, b) => {
            if (sortOrder) {
                return a.rejected_by?.localeCompare(b.rejected_by);
            } else {
                return b.rejected_by?.localeCompare(a.rejected_by);
            }
        })
        setFilteredData([...sortedData]);
        setSortOrder(!sortOrder);
    }

    const handleShippingAddressToggle = () => {
        setShippingAddressToggle(!shippingAddressToggle);
    };
    const handleShippingCompanyNameChange = (event) => {
        setShippingCompanyName(event.target.value);
    };

    const handleShippingCompanyAddressChange = (event) => {
        setShippingCompanyAddress(event.target.value);
    };


    const handleSearchSubmit = (searchValue) => {
        if (validateValue(searchValue)) {
            setSearchText(searchValue);
        }
    };

    const backFunction = () => {
        setCurrentPage(1)
        setServiceNameInInvoice('')
        setInvoiceId('')
        setPreview(false)
        setIgstToggle(false)
        setPaymentToggle(false)
        setTermsToggle(false)
        setUserNotesToggle(false)
        setUserNotes("")
        setUserNotesError("")
        setShippingAddressToggle(false)
        setShippingCompanyName("")
        setShippingCompanyAddress("")
        setMiscService("")
        setMiscDescription("")
        setMiscBasicAmount(null)
        setMiscDueDate("")
        setCompanyName("")
        setInvoiceDetails([])
        setCompanyCIN("")
        setCompanyGST("")
        setCompanyPhone("")
        setCompanyAddress("")
        setCompanyLogo("")
        setcustomerName("")
        setContractNo("")
        setcustomerGSTIN("")
        setcustomerPAN("")
        setIRN("")
        setAckNo("")
        setAckDate("")
        setCompanyAddress("")
        setcustomerAddress("")
        setcustomerPlaceOfSupply("")
        setPartnerBillDate("")
        setPartnerDueDate("")
        setPartnerState("")
        setPartnerPAN("")
        setPartnerCIN("")
        setInvoiceNo("")
        setPartnerStateCode("")
        setcustomerStateCode("")
        setBasicAmount("")
        setTotalAmount("")
        setTotalAmountInWords("")
        setTotalCgst(0)
        setTotalSgst(0)
        setTotalIgst(0)
        // setInvoiceStatus("")
        setToggleShippingAddress(false)
        setStreetAddress("")
        setCity("")
        setState("")
        setPincode("")
        setApprovalType('')
        setInvoiceStatusTo('')
        setpdfDataForPayments('')
        setpdfDataForTerms('')
        setcustomerCIN('')
        setRejectReason('')
        setShowComputationDetails(false)
        setShowPaymentDetails(false)
        setShowTermsDetails(false)

        //for misc invoice 
        setMiscInvoiceTable([{ service: "", hsn: "", hsnError: false, hsnLoader: false, hsnMsg: '', basicAmount: 0.0, cgst: 9.0, sgst: 9.0, igst: 18.0, gross: 0.0 }])
        setMiscListOfContacts([])
        setMiscIgstOptions([...taxPercentageOptions])
        setContractsDropdown([])
        setBillingEntityDropdown(null)
        setBillingEntityOptions([])
        setMiscPlaceOfSupply(null)
        setMiscContractName(null)
        setMiscTotalBasicAmount(0.0)
        setMiscTotalTaxAmount(0.0)
        setMiscCgstSgstOptions(() => {
            const options = []
            taxPercentageOptions.forEach(each => {
                options.push(parseFloat(each / 2))
            })
            return options
        })
        setUploadedFiles([])
        setEditMiscInvoiceClicked(false)
        setCompanyAddress('')
        setcustomerAddress('')
        setShippingAddress('')
        setDownloadPath('')
        setQrCode('')
    }

    function getMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber - 1); // Adjust monthNumber for zero-based indexing
        return date.toLocaleString('default', { month: 'long' });
    }




    return (
        <>
            <>
                <NavBarTop />
                <LeftNavBar />
            </>
            <div>
                {invoicesListError && <Modal keyboard={false} backdrop="static" show={invoicesListError} onHide={() => {
                    dispatch({
                        type: GET_ALL_INVOICES_ERROR,
                        payload: null
                    })
                }} centered>
                    <Modal.Header><div style={{ width: '100%' }} className='d-flex justify-content-center align-items-center gap-2'><MdErrorOutline color='red' style={{ height: "25px", width: "25px" }} /><span className='fs-3'>Error</span></div></Modal.Header>
                    <Modal.Body className="text-center"><h5>{invoicesListError}</h5></Modal.Body>
                    <Modal.Footer className="footer d-flex justify-content-center">
                        <Button variant="secondary" onClick={() => {
                            dispatch({
                                type: GET_ALL_INVOICES_ERROR,
                                payload: null
                            })
                            // navigate('/formbuilder', { state: { name: "Fields" } })
                        }}
                        >Close</Button>
                    </Modal.Footer>
                </Modal>}
                <AlertPopUp alertShow={alertShow} alertType={alertType} alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons} />
                {currentPage === 1 && <div className='invoice_container'>
                    <div className='fm-invoice-con'>
                        <div className='fm-invoice-heading-con  mb-1'>
                            <h3 className="invoice-heading">Invoices</h3>
                            {/* <p className="fin_man_meter_label pt-2"><span>{invoicesList?.length}</span> Total</p> */}
                        </div>
                        <button className="invoice_btn" style={{ color: 'white' }}
                            onClick={() => {
                                setCurrentPage(3)
                                generateInvoiceButtonClicked()
                            }}>Generate Misc. Invoice</button>
                            {/* genearate a misc invoice */}
                    </div>
                    <div className='fm-invoice-con-filters'>
                        <div style={{ width: '180px' }} className="fin_man_billing_period">
                            <p style={{ marginBottom: '5px' }}>Invoice Type:&nbsp;</p>
                            <select
                                value={invoiceSelected}
                                onChange={(e) => {
                                    setinvoiceSelected(e.target.value);
                                }}
                                className="invoice-select-date"
                                style={{ width: "100%", height: "2.5rem" }}
                            >
                                <option value={""}>All Invoices</option>
                                <option value="water">Water</option>
                                <option value="electricity">Electricity</option>
                                <option value="slf">SLF</option>
                                <option value="fms">FMS</option>
                                <option value="miscellaneous">Miscellaneous</option>
                                { }
                            </select>
                        </div>
                        <div className="fin_man_billing_period">
                            <p style={{ marginBottom: '5px' }}>Billing Period:&nbsp;</p>
                            <DatePicker
                                style={{ width: "100%", height: "2.5rem !important" }}
                                className="invoice-select-date"
                                placeholderText="Select Period"
                                selected={biilingPeriodFilter}
                                onChange={(date) => setBillingPeriodFilter(date)}
                                showMonthYearPicker
                                dateFormat="MMM yyyy"
                                customInput={<input value={biilingPeriodFilter ? changeDateFormat() : ''} />}
                            />

                        </div>
                        <div className='d-flex align-items-center gap-3 mb-3'>
                            <div className='fin_man_meter_search_bar_container'>
                                <input style={{ marginTop: '20px', height: '2.5rem' }} type='search' placeholder='Search' value={searchText}
                                    onChange={(e) => handleSearchSubmit(e.target.value)}
                                    className='fin_man_meter_search_bar' />
                                <GoSearch
                                    // onClick={() => searchTextFilter()} 
                                    style={{ marginTop: '17px' }} className='fin_man_meter_search_bar_icon C-pointer' />
                            </div>

                        </div>
                        <button className="invoice_btn" style={{ marginTop: '7px', color: 'white', height: '20px !important' }} onClick={() => {
                            applyFilter()
                        }}>Apply</button>
                        <button className="invoice_btn" style={{ marginTop: '7px', color: 'white', height: '20px !important' }} onClick={() => {
                            setSearchText('')
                            setinvoiceSelected('')
                            setBillingPeriodFilter(new Date())
                            const search = ''
                            const selectedInvoice = ''
                            const month = format(new Date(), 'MMMM yyyy');
                            const fullYear = format(new Date(), 'yyyy');
                            const monthNumber = new Date(month + ' 1, ' + fullYear).getMonth() + 1;
                            setInvoiceListErrorFlag(false)
                            dispatch(getAllInvoices({ page: 1 && 1, facility_id: localStorage.getItem("selected_facility_id"), limit: RECORDS_PER_PAGE, invoiceType, monthNumber, fullYear, search, selectedInvoice }))
                        }}>Clear</button>
                    </div>
                    <Stack direction="horizontal" className=" invoice-type-div flex-wrap" gap={3}>
                        {typesOfInvoices.map((i, index) => {
                            return (
                                <div key={index} className="invoice-type-card" onClick={() => {
                                    setInvoiceType(i.id)
                                    const month = format(biilingPeriodFilter, 'MMMM yyyy');
                                    const fullYear = format(biilingPeriodFilter, 'yyyy');
                                    const monthNumber = new Date(month + ' 1, ' + fullYear).getMonth() + 1;
                                    dispatch({
                                        type: SET_PAGINATION_COUNT_TO,
                                        payload: 1
                                    })
                                    const invoiceType = i.id
                                    setInvoiceListErrorFlag(false)
                                    dispatch(getAllInvoices({ page: 1 && 1, limit: RECORDS_PER_PAGE, facility_id: localStorage.getItem("selected_facility_id"), invoiceType, monthNumber, fullYear, searchText, invoiceSelected }))

                                }}>
                                    <p className={i.id === invoiceType ? "selected-invoice-type C-pointer" : "C-pointer"}>{i.name}</p>
                                </div>
                            )
                        })
                        }
                    </Stack>
                    <div className="fm-invoice-search-con">
                        <div className='fm-invoice-search-bar'>
                            <input type='search' placeholder='Search' value={searchText} onChange={e => {
                                setSearchText(e.target.value)
                            }} className='fin_man_meter_search_bar' />
                            <GoSearch />
                        </div>
                        <div>
                            <select className="fm-invoice-select-type" onChange={(e) => {
                                // onchangeInvoiceType(e.target.value)
                                setInvoiceType(e.target.value)
                            }}>
                                {typesOfInvoices?.map((i, index) => {
                                    return (
                                        <option key={index}
                                            className="invoice-type-card-small"
                                            value={i.id}
                                        >
                                            {i.name}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className='table_container_finance_manager'>
                        <table>
                            <thead>
                                <tr className='fin_man_table_head text-center'>
                                    <th className="C-pointer" onClick={() => sortInvoiceNo()}>Invoice No <img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className="C-pointer" onClick={() => sortInvoiceType()}>Invoice Type <img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className="C-pointer" onClick={() => sortCompanyName()}>Company Name <img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className="C-pointer" onClick={() => sortBillingPeriod()}>Billing period <img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className="C-pointer" onClick={() => sortPreviousInvoiceAmount()} style={{ width: '110px' }}>Previous invoice amount (INR) <img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className="C-pointer" onClick={() => sortCurrentInvoiceAmount()} style={{ width: '110px' }}>Current Invoice amount (INR) <img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    {invoiceType === "On-Hold" && <th className="C-pointer" onClick={() => sortHeldBy()}>Held by <img src="./images/icons/sort.svg" alt="edit-icon" /></th>}
                                    {invoiceType === "rejected" && <th className="C-pointer" onClick={() => sortRejectedBy()}>Rejected by <img src="./images/icons/sort.svg" alt="edit-icon" /></th>}
                                    {(invoiceType === "On-Hold" || invoiceType === "rejected") && <th style={{ width: '230px' }}>Reason</th>}
                                    <th className="C-pointer" onClick={() => sortUpdatedBy()}>Updated By <img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th className="C-pointer" onClick={() => sortUpdatedOn()}>Updated On <img src="./images/icons/sort.svg" alt="edit-icon" /></th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            {filteredData === null ?
                                <tbody className='fin_man_table_body'>
                                    <tr className='text-center'>
                                        <td colSpan='11'>No invoices</td>
                                    </tr>
                                </tbody>
                                :
                                <>
                                    {filteredData.length > 0 ? (

                                        <tbody className='fin_man_table_body'>
                                            {filteredData && filteredData?.map((each, index) => {
                                                return (
                                                    <tr key={index} className={`table_row ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                                                        <td className='table_text_right'>{each.invoice_number}</td>
                                                        <td>{each.invoice_type?.toUpperCase()}</td>
                                                        <td>{each.company_name}</td>
                                                        <td >{getMonthName(each.invoice_month) + " " + each.invoice_year}</td>
                                                        <td className='table_text_right'>{each.previous_amount ? <> {each.previous_amount} </> : "-"}</td>
                                                        <td className='table_text_right'>{each.gross_amount ? <> {numberComponent(each.gross_amount)} </> : "-"}</td>
                                                        {invoiceType === "On-Hold" && <td>{each.held_by}</td>}
                                                        {invoiceType === "rejected" && <td>{each.rejected_by}</td>}
                                                        {(invoiceType === "rejected") &&
                                                            <td style={{
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                maxWidth: '200px' // adjust this value as per your requirement
                                                            }}
                                                                title={each.rejected_reason}>
                                                                {/* Invoice is rejected due to improper data */}
                                                                {each.rejected_reason}
                                                            </td>
                                                        }
                                                        {(invoiceType === "On-Hold") &&
                                                            <td style={{
                                                                // whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                // textOverflow: 'ellipsis',
                                                                maxWidth: '200px' // adjust this value as per your requirement
                                                            }}
                                                                title="Invoice is kept On-Hold by Admin">
                                                                Invoice is kept On-Hold by Admin
                                                            </td>
                                                        }
                                                        <td >{each?.updated_by?.toUpperCase()}</td>
                                                        {<td title={each?.updated_at} className='table_text_right C-pointer'>{timeElapsed(each?.updated_at)}</td>}
                                                        <td className='text-decoration-underline text-center' role='button' onClick={() => handleViewButton(each.invoice_number, each.status, each.invoice_type)}>
                                                            <img src="./images/icons/ViewEYE.svg" title="view" onClick={() => handleViewButton(each.invoice_number, each.status, each.invoice_type)} alt="view imag" />
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                        </tbody>
                                    )
                                        :
                                        (

                                            <tbody className='fin_man_table_body'>
                                                <tr className='text-center'>
                                                    <td colSpan='11'>{invoiceListErrorFlag ? 'Error in fetching invoices' : 'No invoices'}</td>
                                                    {/* <td colSpan='11'>{(error!==null) && 'Error while'}</td> */}
                                                </tr>
                                            </tbody>

                                        )
                                    }
                                </>
                            }
                        </table>
                    </div>
                    <Pagination
                        handledPaginationNextClicked={handledPaginationNextClicked}
                        handledPaginationPrevClicked={handledPaginationPrevClicked}
                        isApplyClicked
                        totalRecords={totalRecordsAc}
                        recordsPerPage={RECORDS_PER_PAGE}
                        currentPaginationPage={currentPaginationPageAc}
                    />

                </div>
                }
                {/* electricity invoice */}
                {currentPage === 2 && <div className="invoice_preview_container">

                    <div className="invoice-preview-con">

                        {/* <div className="invoice-preview-scrollable" style={{ margin: preview || (invoiceStatus !== "rejected" && invoiceStatus!=='onhold' && invoiceStatus!== "draft") ? 'auto' : '' }}> */}
                        <div className="invoice-preview-scrollable" style={{ margin: 'auto' }}>
                            <div className="invoice-preview-card">
                                <div className="invoice-preview-card-details">
                                    {companyLogo && <div className="invoice-logo-placeholder">
                                        <div>
                                            <img style={{ width: '15rem', height: '5rem' }} src={companyLogo} alt="company logo" />
                                        </div>
                                    </div>
                                    }
                                    <div className="d-flex justify-content-between w-100">
                                        <div className="invoice-header-address">
                                            <h5>{companyName?.toUpperCase()}</h5>
                                            <span ><b>Reg.Office:</b> {companyAddress}</span>
                                        </div>
                                        {
                                            QrCode?.length > 0 ?
                                                <QRCode style={{ marginTop: '-50px' }} value={QrCode} size={170} />
                                                :
                                                <div className="invoice-bar-code">
                                                    {invoiceStatus === "rejected" ? (
                                                        <p className="rejected-text">Rejected</p>
                                                    ) : (
                                                        <p className="qr-code-text">QR Code</p>
                                                    )}
                                                </div>
                                        }

                                    </div>
                                    <h5 style={{ textAlign: "center", margin: '-15px', width: '100%', fontWeight: 'bold' }}>Tax Invoice</h5>
                                    <h6 style={{ textAlign: "center", margin: '-15px', width: '100%', fontWeight: 'bold' }}>({serviceNameInInvoice})</h6>

                                    <div className="invoice-preview-inner-card-div">
                                        <div className="invoice-preview-inner-card">
                                            <div style={{ width: '100%' }}>
                                                <p>
                                                    <b style={{ fontSize: "18px" }}>Buyer </b>
                                                    <br /> <b>{customerName}</b><br />
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>Billed To</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerAddress}</span>
                                                    </div>
                                                    {toggleShippingAddress && <div className="invoice-left-data">
                                                        <span className="key"><b>Shipped To</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value">  {shippingAddress}</span>
                                                    </div>}
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>State Code</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerStateCode}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>GSTIN</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerGSTIN}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>PAN</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerPAN}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>CIN</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerCIN}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>Place Of Supply</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerPlaceOfSupply}</span>
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="invoice-address-right">
                                            <div >
                                                <br />
                                                <p> <b>Contract No   </b> </p>
                                                <p><b>Invoice No</b> </p>
                                                <p><b>State</b></p>
                                                <p><b>State Code</b> </p>
                                                <p><b>GSTIN</b></p>
                                                <p><b>PAN</b></p>
                                                <p><b>CIN</b></p>
                                                <br />
                                                <p><b>Invoice Date</b></p>
                                                <p><b>Due Date</b></p>

                                            </div>
                                            <div style={{ marginLeft: "20px" }}>
                                                <br />
                                                <p>: {contractNo}</p>
                                                <p>: {invoiceNo}</p>
                                                <p>: {parnterState}</p>
                                                <p>: {partnerStateCode}</p>
                                                <p>: {companyGST}</p>
                                                <p>: {partnerPAN}</p>
                                                <p>: {companyCIN}</p>

                                                <br />
                                                <p>: {ackDate}</p>
                                                <p>: {partnerDueDate}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="invoice-preview-card-details-bottom">
                                    <div className="invoice-preview-inner-card-2">
                                        <p><span><b>IRN :</b> </span>{IRN}<br />
                                        </p>
                                    </div>
                                </div>

                                <div className="invoice-preview-table-container">
                                    <Table className="invoice-preview-table">
                                        <thead >
                                            <tr >
                                                <th colSpan={1} rowSpan={2}><b>Particulars of Services</b></th>
                                                <th colSpan={1} rowSpan={2}><b>SAC / HSN</b></th>
                                                <th colSpan={2}><b>Bill Period / Reading</b></th>
                                                {showComputationDetails && <th colSpan={1}><b>Total Units</b></th>}
                                                {showComputationDetails && <th colSpan={1}><b>Rate</b></th>}
                                                {/* <th colSpan={1}><b>Meter Rent</b></th> */}
                                                <th colSpan={1}><b>Basic Amount</b></th>
                                                {!igstToggle && <th colSpan={2}><b>CGST</b></th>}
                                                {!igstToggle && <th colSpan={2}><b>SGST</b></th>}
                                                {igstToggle && <th colSpan={2}><b>IGST</b></th>}
                                                <th colSpan={1}><b>Gross</b></th>
                                            </tr>
                                            <tr>
                                                <th>From / Op. Reading</th>
                                                <th>To / Cl. Reading</th>

                                                {showComputationDetails && <th>kWh</th>}
                                                {showComputationDetails && <th>
                                                    (INR / kWh)</th>}
                                                {/* <th>Amount (INR)</th> */}
                                                <th>Amount (INR)</th>
                                                {!igstToggle && <th>Rate (%)</th>}
                                                {!igstToggle && <th>Amount (INR)</th>}
                                                {!igstToggle && <th>Rate (%)</th>}
                                                {!igstToggle && <th>Amount (INR)</th>}
                                                {igstToggle && <th>Rate (%)</th>}
                                                {igstToggle && <th>Amount (INR)</th>}
                                                <th>Amount (INR)</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {invoiceDetails?.map((item, index) => (
                                                <tr key={index}>
                                                    <th className="text-start">{item?.service_name_in_invoice?.toUpperCase()},<br />
                                                        {item?.service_identifier}
                                                    </th>
                                                    <th className="text-start">{item?.hsn}</th>
                                                    <th style={{ textAlign: 'end' }}>
                                                        <div>
                                                            <p style={{ marginBottom: '-5px' }}>{formatDateView(item.from_date)}
                                                                <hr />
                                                                {formatToIndianRupee(item.initial_reading)}
                                                            </p>
                                                        </div>
                                                    </th>
                                                    <th style={{ textAlign: 'end' }}><div>
                                                        <p style={{ marginBottom: '-5px' }}>{formatDateView(item.to_date)}
                                                            <hr />
                                                            {formatToIndianRupee(item.final_reading)}
                                                        </p>
                                                    </div></th>
                                                    {showComputationDetails && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.consumption)}</th>}
                                                    {showComputationDetails && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.rate_per_unit)}</th>}
                                                    {/* <th>-</th> */}
                                                    <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.net_amount)}</th>
                                                    {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.cgst_rate)}</th>}
                                                    {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.cgst)}</th>}
                                                    {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.sgst_rate)}</th>}
                                                    {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.sgst)}</th>}
                                                    {igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.igst_rate)}</th>}
                                                    {igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.igst)}</th>}
                                                    <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.gross_amount)}</th>
                                                </tr>
                                            ))}
                                            {/* Grand Total */}
                                            <tr >
                                                {/* service description */}
                                                <th style={{ fontWeight: 'bold', fontSize: 'small' }} className="text-start">
                                                    Grand Total
                                                </th>
                                                {/* sac/hsn */}
                                                <th className="text-start">
                                                </th>
                                                <th className="text-start">
                                                </th>
                                                <th className="text-start">
                                                </th>
                                                <th className="text-start">
                                                </th>
                                                <th className="text-start">
                                                </th>
                                                {/* basic amount */}
                                                <th className="text-end">
                                                    {formatToIndianRupee(basicAmount)}
                                                </th>
                                                {/* sgst rate*/}
                                                {!igstToggle && <th>

                                                </th>}
                                                {/* sgst amount*/}
                                                {!igstToggle && <th className="text-end">
                                                    {formatToIndianRupee(totalSgst)}
                                                </th>}
                                                {/* cgst rate*/}
                                                {!igstToggle && <th>

                                                </th>}
                                                {/* cgst amount*/}
                                                {!igstToggle && <th className="text-end">
                                                    {formatToIndianRupee(totalCgst)}
                                                </th>}
                                                {/* igst rate*/}
                                                {igstToggle && <th>

                                                </th>}
                                                {/* igst amount*/}
                                                {igstToggle && <th className="text-end">{formatToIndianRupee(totalIgst)}</th>}
                                                {/* gross amount */}
                                                <th className="text-end">
                                                    {formatToIndianRupee(TotalAmount)}
                                                    {/* parseFloat(each.basicAmount) + parseFloat((each.basicAmount / 100) * each.igst)) : formatToIndianRupee(parseFloat(each.basicAmount) + parseFloat((each.basicAmount / 100) * each.cgst) + parseFloat((each.basicAmount / 100) * each.sgst))} */}
                                                </th>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <div className="invoice-preview-total-con">
                                        <div className="invoice-preview-total-con-left">
                                            <span>
                                                <b>Total Invoice Amount In Words (Rupees) : </b>
                                            </span>
                                            <p >{totalAmountInWords}
                                            </p>
                                        </div>
                                        <div className="invoice-preview-total d-flex">
                                            <p className="invoice-preview-total-key" >Total : </p>
                                            {/* <span className="diagonal-line"></span> */}
                                            {/* <span></span> */}
                                            <p className="invoice-preview-total-value" >&nbsp;
                                                <img src='./images/icons/rupee.svg' alt="Rupee Symbol" />&nbsp;{formatToIndianRupee(TotalAmount)}</p>
                                        </div>
                                    </div>
                                    {userNotesToggle && <div className="invoice-user-notes-div">
                                        <p className="invoice-user-notes">User Notes</p>
                                        <p>{userNotes}</p>
                                    </div>}
                                </div>
                                <div className="signature">
                                    <p></p>
                                    <p>sign here</p>
                                </div>

                            </div>
                            {/* {!preview && !(invoiceStatus !== "rejected" && invoiceStatus!=='onhold' && invoiceStatus!== "draft") && (<div className="chat-feature">
                                <div className="chatbox">
                                    <div className="chat-user">
                                        <p className="name">User-name</p>
                                        <p>13-02-24 15:25</p>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor <span>@fin-man</span>, consectetur adipiscing elit. Maecenas vulputate,
                                        leo eu bibendum sollicitudin, risus velit posuere libero, vitae aliquet turpis
                                        sem eu massa. Proin vitae consequat ligula. Duis erat massa, imperdiet at turpis ac,
                                        luctus aliquet sapien. Nullam
                                    </p>
                                </div>
                                <div className="chatbox">
                                    <div className="chat-user">
                                        <p className="name">CEO</p>
                                        <p>13-02-24 15:25</p>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor <span>@fin-man</span>, consectetur adipiscing elit. Maecenas vulputate,
                                        leo eu bibendum sollicitudin, risus velit posuere libero, vitae aliquet turpis
                                        sem eu massa. Proin vitae consequat ligula. Duis erat massa, imperdiet at turpis ac,
                                        luctus aliquet sapien. Nullam
                                    </p>
                                </div>
                            </div>
                            )} */}
                            {/* to show payments and terms */}
                            {(paymentToggle && pdfDataForPayments && showPaymentDetails) && <PdfViewer base64Pdf={pdfDataForPayments} />}
                            {(termsToggle && pdfDataForTerms && showTermsDetails) && <PdfViewer base64Pdf={pdfDataForTerms} />}
                        </div>
                    </div>
                    {!preview && <div className="invoice-preview-buttons">
                        {downloadPath && <button onClick={() => {
                            handleDownload()
                        }}
                            disabled={downloadPath === '' || downloadPath === null} style={{ opacity: downloadPath ? '100%' : '50%' }}>Download</button>}
                        {(invoiceStatus === "draft" || invoiceStatus === "onhold" || invoiceStatus === "rejected") && <button onClick={() => {
                            handleRegenerate()
                            setApprovalType('Electricity')
                        }} >Regenerate</button>}
                        <button onClick={() => { setCurrentPage(1); setPreview(false); backFunction(); backPagination() }}> Back</button>
                        {(invoiceStatus === "draft" || invoiceStatus === "onhold" || invoiceStatus === "approved") && <button onClick={() => {
                            handleReject()
                            setApprovalType('Electricity')
                        }} >Reject</button>}
                        {(invoiceStatus === "draft" || invoiceStatus === "rejected" || invoiceStatus === "approved") && <button onClick={() => {
                            handleHold()
                            setApprovalType('Electricity')
                        }} >Hold</button>}
                        {!(invoiceStatus !== "rejected" && invoiceStatus !== 'onhold' && invoiceStatus !== "draft") && <button
                            onClick={() => {
                                editInvoice()
                                setApprovalType('Electricity')
                            }
                            }
                            style={{ opacity: checkSendForApprovalValidation() ? '100%' : '50%' }} disabled={!checkSendForApprovalValidation()}>Approve</button>}

                    </div>}
                    {preview && <div className="invoice-preview-buttons">
                        <button onClick={() => setPreview(false)}> Back</button>
                    </div>}

                </div>
                }
                {/* generated misc invoice */}
                {currentPage === 3 && <div className="invoice_preview_container">

                    <div className="invoice-preview-con">
                        {!preview && <div className="fm-invoice-setting-con">
                            <h1 className="invoice-setting-header">Invoice Details</h1>
                            <p style={{ marginBottom: '3px', marginTop: '15px' }}>Contract Name</p>
                            <Select
                                onChange={(e) => {
                                    setMiscContractName(e)
                                    if(editMiscInvoiceClicked){
                                        getContractDetailsForEdit(e.value)
                                    }else{
                                    getContractDetails(e.value)
                                    }
                                }}
                                options={contractsDropdown}
                                placeholder="Select Contract"
                                value={miscContractName}
                                noOptionsMessage={() => "No Contracts Available"}
                            />

                            <p style={{ marginBottom: '3px', marginTop: '15px' }}>Billing Entity</p>
                            <Select
                                onChange={(e) => {
                                    setBillingEntityDropdown(e)
                                    const billingEntity = billingEntities.find(each => each.partner_company_id === e.value)
                                    if (billingEntity) {
                                        setBillingEntityDropdown({
                                            label: billingEntity.company_name,
                                            value: billingEntity.partner_company_id
                                        })
                                        if(billingEntity.logo){
                                            getImage(billingEntity.logo)
                                        }else{
                                            setCompanyLogo(null)
                                        }
                                        setCompanyName(billingEntity.company_name)
                                        const address = billingEntity?.registered_address
                                        setCompanyAddress(`${address?.street_address_1}, ${address?.street_address_2}, ${address?.city}, ${address?.state}, ${address?.postal_code}`)
                                        setPartnerState(address?.state)
                                        setPartnerStateCode(billingEntity?.gst_number?.slice(0, 2))
                                        setCompanyGST(billingEntity?.gst_number)
                                        setPartnerPAN(billingEntity?.pan_number)
                                        setCompanyCIN(billingEntity?.cin)
                                    }
                                    // getContractDetails(e.value)
                                }}
                                options={billingEntityOptions}
                                placeholder="Select Billing Entity"
                                value={billingEntityDropdown}
                                isDisabled={miscContractName === null || miscContractName === ""}
                            />

                            <p style={{ marginBottom: '3px', marginTop: '15px' }}>Place Of Supply</p>
                            <Select
                                options={stateOptions}
                                value={miscPlaceOfSupply}
                                placeholder="Select Place Of Supply"
                                onChange={e => {
                                    setMiscPlaceOfSupply(e)
                                    setcustomerPlaceOfSupply(e.value)
                                }}
                                noOptionsMessage={() => "No States Available"}
                                isDisabled={miscContractName === null || miscContractName === ""}
                            />

                            <p style={{ marginBottom: '3px', marginTop: '15px', marginBottom: '15px' }}>List Of Contacts</p>
                            <div className="d-flex flex-column gap-1 ">
                                {miscListOfContacts.length > 0 ?
                                    <>
                                        {miscListOfContacts?.map((contact, index) => {
                                            return (
                                                <div className="">
                                                    <div className="d-flex align-items-start gap-2"
                                                        style={{ marginTop: '-10px' }}
                                                    >
                                                        <input type="checkbox" checked={contact?.sendEmail} onChange={() => {
                                                            let temp = [...miscListOfContacts]
                                                            temp[index].sendEmail = !temp[index].sendEmail
                                                            setMiscListOfContacts([...temp])
                                                            const atleastOneSelected = temp.some((contact) => contact.sendEmail === true)
                                                            setMiscSelectedContacts(atleastOneSelected)
                                                        }} />
                                                        <p style={{ marginTop: '-7px', color: '#000' }} key={index} value={contact}>{contact?.name}{contact?.designation ? `, ${contact?.designation}` : ''}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {!miscSelectedContacts && <p style={{ fontSize: 'small', marginTop: '-10px', color: 'red' }}>Please select atleast one contact</p>}
                                    </>
                                    :
                                    <p style={{ fontSize: 'small', marginTop: '-10px' }}>{miscContractName?.length > 0 ? 'No Contacts Available' : 'select a contract'}</p>}

                            </div>

                            {/* <button>Generate Invioce</button> */}
                        </div>}
                        <div className="invoice-preview-scrollable" style={{ width: preview ? '100vw' : '' }}>
                            <div className="invoice-preview-card">
                                <div className="invoice-preview-card-details">
                                    {companyLogo ? <div className="invoice-logo-placeholder">
                                        <div>
                                            <img style={{ width: '15rem', height: '5rem' }} src={companyLogo} alt="company logo" />
                                        </div>
                                    </div> :
                                        <p style={{ marginTop: '10px' }}></p>}
                                    <div className="d-flex justify-content-between w-100">
                                        <div className="invoice-header-address text-center" style={{marginTop:"-50px"}}>
                                            <h5>{companyName?.toUpperCase()}</h5>
                                            <span ><b>Reg.Office:</b> {companyAddress}</span>
                                        </div>
                                        {
                                            QrCode?.length > 0 ?
                                                <QRCode style={{ marginTop: '-70px' }} value={QrCode} size={170} />
                                                :
                                                <div className="invoice-bar-code" style={{ marginTop: '-70px' }}>
                                                    {invoiceStatus === "rejected" ? (
                                                        <p className="rejected-text">Rejected</p>
                                                    ) : (
                                                        <p className="qr-code-text">QR Code</p>
                                                    )}
                                                </div>
                                        }

                                    </div>
                                    <h5 style={{ textAlign: "center", margin: '-15px', width: '100%', fontWeight: 'bold' }}>Tax Invoice</h5>
                                    {/* <h6 style={{ textAlign: "center", margin: '-15px', width: '100%', fontWeight: 'bold' }}>{miscInvoiceTable[0]?.service ?<> ({miscInvoiceTable[0]?.service}) </>: ''}</h6> */}

                                    <div className="invoice-preview-inner-card-div">
                                        <div className="invoice-preview-inner-card">
                                            <div style={{ width: '100%' }}>
                                                <p>
                                                    <b style={{ fontSize: "18px" }}>Buyer </b>
                                                    <br /> <b>{customerName}</b><br />
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>Billed To</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerAddress}</span>
                                                    </div>
                                                    {toggleShippingAddress && <div className="invoice-left-data">
                                                        <span className="key"><b>Shipped To</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value">
                                                            {streetAddress ? 
                                                            <>
                                                            {streetAddress}{streetAddress ? ", " : ""} {city}{city ? ", " : ""} {state}{state ? ", " : ""} {pincode}
                                                            </>
                                                            :
                                                            <>
                                                            {shippingAddress}
                                                            </>
                                                            }
                                                        </span>
                                                    </div>}
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>State Code</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerStateCode}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>GSTIN</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerGSTIN}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>PAN</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerPAN}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>CIN</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerCIN}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>Place Of Supply</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerPlaceOfSupply}</span>
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="invoice-address-right">
                                            <div >
                                                <br />
                                                <p> <b>Contract No   </b> </p>
                                                <p><b>Invoice No</b> </p>
                                                <p><b>State</b></p>
                                                <p><b>State Code</b> </p>
                                                <p><b>GSTIN</b></p>
                                                <p><b>PAN</b></p>
                                                <p><b>CIN</b></p>
                                                <br />
                                                <p><b>Invoice Date</b></p>
                                                <p><b>Due Date</b></p>

                                            </div>
                                            <div style={{ marginLeft: "20px" }}>
                                                <br />
                                                <p>: {contractNo}</p>
                                                <p>: {editMiscInvoiceClicked ? invoiceNo : 'Generated at creation'}</p>
                                                <p>: {parnterState}</p>
                                                <p>: {partnerStateCode}</p>
                                                <p>: {companyGST}</p>
                                                <p>: {partnerPAN}</p>
                                                <p>: {companyCIN}</p>

                                                <br />
                                                <p>: {ackDate}</p>
                                                <p>: {partnerDueDate}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="invoice-preview-card-details-bottom">
                                    <div className="invoice-preview-inner-card-2">
                                        <p><span><b>IRN :</b> </span>{IRN}<br /></p>
                                    </div>
                                </div>
                                <div className="invoice-preview-table-container">
                                    <Table className="invoice-preview-table">
                                        <thead>
                                            <tr>
                                                <th colSpan={5} style={{ width: "220px" }}>Service Description</th>
                                                <th  >SAC/HSN</th>
                                                {/* <th colSpan={2}></th>
                                            <th colSpan={1}></th>
                                            <th colSpan={1}></th> */}
                                                <th colSpan={1}>Basic Amount</th>
                                                {!igstToggle && <th colSpan={2}>SGST</th>}
                                                {!igstToggle && <th colSpan={2}>CGST</th>}
                                                {igstToggle && <th colSpan={2}>IGST</th>}
                                                <th colSpan={1}>GROSS</th>
                                            </tr>
                                            <tr>
                                                <th colSpan={5}></th>
                                                <th ></th>
                                                {/* <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th> */}
                                                <th colSpan={1}></th>
                                                {!igstToggle && <th>Rate %</th>}
                                                {!igstToggle && <th>Amount (INR)</th>}
                                                {!igstToggle && <th>Rate %</th>}
                                                {!igstToggle && <th>Amount (INR)</th>}
                                                {igstToggle && <th>Rate %</th>}
                                                {igstToggle && <th>Amount</th>}
                                                <th>Amount (INR)</th>
                                            </tr>
                                        </thead>
                                        <tbody className="misc-invoice-tbody">
                                            {miscInvoiceTable?.map((each, index) => (
                                                <tr >
                                                    {/* service description */}
                                                    <th colSpan={5} style={{ color: 'gray', fontWeight: 'bold' }} className="text-start">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter Service Name"
                                                        value={each.service}
                                                        onChange={(e) => {
                                                            let input = e.target.value;

                                                            // Allow space only if there is a preceding character
                                                            if (/^\s/.test(input)) {
                                                                input = input.trimStart(); // Prevent starting with space
                                                            }

                                                            if (/\s{2,}/.test(input)) {
                                                                input = input.replace(/\s{2,}/g, ' '); // Prevent consecutive spaces
                                                            }

                                                            // Update the state
                                                            let temp = [...miscInvoiceTable];
                                                            temp[index].service = input;
                                                            setMiscInvoiceTable(temp);
                                                        }}
                                                        onBlur={(e) => {
                                                            // Trim spaces when the user leaves the field
                                                            const trimmedValue = e.target.value.trim();
                                                            let temp = [...miscInvoiceTable];
                                                            temp[index].service = trimmedValue;
                                                            setMiscInvoiceTable(temp);
                                                        }}
                                                        style={{
                                                            paddingLeft: '0px',
                                                            fontWeight: 'normal',
                                                            backgroundColor: each.service.length === 0 ? '#deeff5' : 'transparent',
                                                        }}
                                                    />

                                                        {miscInvoiceServiceIntifier}
                                                    </th>
                                                    {/* sac/hsn */}
                                                    <th className="text-start">
                                                        <div style={{
                                                            // width: '8rem',
                                                            margin: 0,
                                                        }}>
                                                            <input type="text" value={each.hsn} onChange={(e) => {
                                                                let temp = [...miscInvoiceTable]
                                                                const input = e.target.value
                                                                const formattedInput = input.replace(/[^0-9]/g, '')
                                                                const trimmedInput = formattedInput.trim();
                                                                if (trimmedInput.length <= 8) {
                                                                    temp[index].hsn = trimmedInput
                                                                }
                                                                if ((trimmedInput.length < 6 && trimmedInput.length > 0) || (trimmedInput.length === 7 && trimmedInput.length > 0)) {
                                                                    temp[index].hsnError = true
                                                                    temp[index].hsnLoader = false
                                                                    temp[index].hsnMsg = ''
                                                                } else {
                                                                    temp[index].hsnError = false
                                                                    if (trimmedInput.length !== 0) {
                                                                        temp[index].hsnLoader = true
                                                                        // lol bellow one not working
                                                                        setTimeout(() => {
                                                                            temp[index].hsnLoader = false
                                                                            temp[index].hsnMsg = 'HSN Description'
                                                                        }, 2000)
                                                                    }
                                                                }
                                                                setMiscInvoiceTable(temp)
                                                            }}
                                                                placeholder="Enter HSN"
                                                                style={{
                                                                    width: '100%',
                                                                    paddingLeft: '0px',
                                                                    fontWeight: 'normal',
                                                                    backgroundColor: each.hsn.length === 0 ? '#deeff5' : 'transparent',
                                                                }}
                                                            />
                                                            {/* add this if hsn code is there */}
                                                            {/* {each.hsnLoader && <Spinner style={{ height: "15px", width: "15px" }} animation="border" />} */}
                                                        </div>
                                                        {/* <br /> */}
                                                        {each.hsnError && <span style={{ color: 'red', width: '40% !important' }} >Invalid HSN code</span>}
                                                        {/* {!each.hsnError && <span style={{ color: 'red', width: '40% !important' }} >{each?.hsnMsg}</span>} */}
                                                    </th>
                                                    {/* basic amount */}
                                                    <th>
                                                        <input type="text" style={{ textAlign: 'end' }} value={each.basicAmount} onChange={(e) => {
                                                            let temp = [...miscInvoiceTable];
                                                            const input = e.target.value;
                                                            const formattedInput = input.replace(/[^0-9.]/g, '');
                                                            const trimmedInput = formattedInput.trim();

                                                            // Check if the input has more than 2 decimal places
                                                            const decimalIndex = trimmedInput.indexOf('.');
                                                            if (decimalIndex !== -1 && trimmedInput.length - decimalIndex - 1 > 2) {
                                                                return; // Ignore the input if it has more than 2 decimal places
                                                            }

                                                            // Check if the input is a positive number
                                                            if (parseFloat(trimmedInput) < 0) {
                                                                return; // Ignore the input if it is negative
                                                            }

                                                            temp[index].basicAmount = trimmedInput;
                                                            if (trimmedInput.length === 0) {
                                                                temp[index].basicAmount = 0.0
                                                            }
                                                            setMiscInvoiceTable(temp);
                                                            calculateTotalBasicAmount();
                                                            calculateTotalTaxAmount();
                                                        }} />
                                                    </th>
                                                    {/* sgst rate*/}
                                                    {!igstToggle && <th>
                                                        <select value={each.sgst} onChange={(e) => {
                                                            let temp = [...miscInvoiceTable]
                                                            temp[index].sgst = e.target.value
                                                            temp[index].cgst = e.target.value
                                                            setMiscInvoiceTable(temp)
                                                            calculateTotalTaxAmount()
                                                        }}>
                                                            {miscCgstSgstOptions.map((option, index) => {
                                                                return (
                                                                    <option key={index} value={option}>{option}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </th>}
                                                    {/* sgst amount*/}
                                                    {!igstToggle && (
                                                        <th className="text-end">
                                                            {formatToIndianRupee(((each.basicAmount / 100) * each.sgst).toFixed(2))}
                                                        </th>
                                                        )}
                                                    {/* cgst rate*/}
                                                    {!igstToggle && <th>
                                                        <select value={each.cgst} onChange={(e) => {
                                                            let temp = [...miscInvoiceTable]
                                                            temp[index].sgst = e.target.value
                                                            temp[index].cgst = e.target.value
                                                            setMiscInvoiceTable(temp)
                                                            calculateTotalTaxAmount()
                                                        }}>
                                                            {miscCgstSgstOptions.map((option, index) => {
                                                                return (
                                                                    <option key={index} value={option}>{option}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </th>}
                                                    {/* cgst amount*/}
                                                    {!igstToggle && (
                                                        <th className="text-end">
                                                            {formatToIndianRupee(((each.basicAmount / 100) * each.cgst).toFixed(2))}
                                                        </th>
                                                        )}
                                                    {/* igst rate*/}
                                                    {igstToggle && <th>
                                                        <select value={each.igst} onChange={(e) => {
                                                            let temp = [...miscInvoiceTable]
                                                            temp[index].igst = e.target.value
                                                            setMiscInvoiceTable(temp)
                                                            calculateTotalTaxAmount()
                                                        }}>
                                                            {miscIgstOptions.map((option, index) => {
                                                                return (
                                                                    <option key={index} value={option}>{option}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </th>}
                                                    {/* igst amount*/}
                                                    {igstToggle && (
                                                        <th className="text-end">
                                                            {formatToIndianRupee(((each.basicAmount / 100) * each.igst).toFixed(2))}
                                                        </th>
                                                        )}
                                                    {/* gross amount */}
                                                    <th className="text-end">
                                                        {igstToggle
                                                            ? formatToIndianRupee(
                                                                (parseFloat(each.basicAmount) + parseFloat((each.basicAmount / 100) * each.igst)).toFixed(2)
                                                            )
                                                            : formatToIndianRupee(
                                                                (parseFloat(each.basicAmount) +
                                                                parseFloat((each.basicAmount / 100) * each.cgst) +
                                                                parseFloat((each.basicAmount / 100) * each.sgst)).toFixed(2)
                                                            )}
                                                        </th>
                                                </tr>)
                                            )}
                                            {/* Grand Total */}
                                            <tr >
                                                {/* service description */}
                                                <th colSpan={5} style={{ fontWeight: 'bold', fontSize: 'small' }} className="text-start">
                                                    Grand Total
                                                </th>
                                                {/* sac/hsn */}
                                                <th className="text-start">
                                                </th>
                                                {/* basic amount */}
                                                <th className="text-end">
                                                    {formatToIndianRupee(miscTotalBasicAmount)}
                                                </th>
                                                {/* sgst rate*/}
                                                {!igstToggle && <th>

                                                </th>}
                                                {/* sgst amount*/}
                                                {!igstToggle && <th className="text-end">
                                                    {formatToIndianRupee((miscTotalTaxAmount / 2).toFixed(2))}
                                                </th>}
                                                {/* cgst rate*/}
                                                {!igstToggle && <th>

                                                </th>}
                                                {/* cgst amount*/}
                                                {!igstToggle && <th className="text-end">
                                                    {formatToIndianRupee((miscTotalTaxAmount / 2).toFixed(2))}
                                                </th>}
                                                {/* igst rate*/}
                                                {igstToggle && <th>

                                                </th>}
                                                {/* igst amount*/}
                                                {igstToggle && <th className="text-end">{formatToIndianRupee(miscTotalTaxAmount.toFixed(2))}</th>}
                                                {/* gross amount */}
                                                <th className="text-end">
                                                {formatToIndianRupee((parseFloat(miscTotalBasicAmount) + parseFloat(miscTotalTaxAmount)).toFixed(2))}
                                                    {/* parseFloat(each.basicAmount) + parseFloat((each.basicAmount / 100) * each.igst)) : formatToIndianRupee(parseFloat(each.basicAmount) + parseFloat((each.basicAmount / 100) * each.cgst) + parseFloat((each.basicAmount / 100) * each.sgst))} */}
                                                </th>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <div style={{ display: "flex", justifyContent: "end", marginTop: "-13px", marginBottom: '30px' }}>
                                        <p style={{ marginRight: '10px', textDecoration: 'underline', fontSize: '12px', marginTop: '1px' }}>Add a row : </p>
                                        {
                                            // <img style={{ height: "18px", marginRight: "10px" ,color:'blue'}}
                                            //     onClick={()=>{
                                            //         let temp = [...miscInvoiceTable]
                                            //         temp.push({service:"",hsn:"",basicAmount:0.0,cgst:9.0,sgst:9.0,igst:18.0,gross:0.0})
                                            //         setMiscInvoiceTable(temp)
                                            //     }}
                                            //     src="./images/icons/PlusaddItem.svg" alt="add" 
                                            //     title="Add row below"
                                            //     />
                                            <FiPlusCircle
                                                style={{ height: '20px', width: '20px', marginRight: '10px', color: 'blue' }}
                                                onClick={() => {
                                                    let temp = [...miscInvoiceTable]
                                                    temp.push({ service: "", hsn: "", hsnError: false, hsnLoader: false, hsnMsg: '', basicAmount: 0.0, cgst: 9.0, sgst: 9.0, igst: 18.0, gross: 0.0 })
                                                    setMiscInvoiceTable(temp)
                                                }}
                                                alt="add"
                                                className="C-pointer"
                                                title="Add row below"
                                            />

                                        }
                                        {
                                            miscInvoiceTable.length >= 2 ?
                                                // <img style={{ height: "18px" }} src="./images/icons/MinusremoveItem.svg"
                                                // onClick={() => {
                                                //     let temp = [...miscInvoiceTable]
                                                //     temp.pop()
                                                //     setMiscInvoiceTable(temp)
                                                //     calculateTotalBasicAmount(temp)
                                                //     calculateTotalTaxAmount(temp)
                                                // }}
                                                // alt="remove" 
                                                // title="Delete last row"
                                                // />
                                                <FiMinusCircle
                                                    style={{ height: '20px', width: '20px', color: 'blue' }}
                                                    onClick={() => {
                                                        let temp = [...miscInvoiceTable]
                                                        temp.pop()
                                                        setMiscInvoiceTable(temp)
                                                        calculateTotalBasicAmount(temp)
                                                        calculateTotalTaxAmount(temp)
                                                    }}
                                                    className="C-pointer"
                                                    alt="remove"
                                                    title="Delete last row"
                                                />
                                                : null
                                        }

                                    </div>
                                    {/* <div className="invoice-preview-total-section">
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div style={{ display: "flex", flexDirection: "column", marginRight: '50px' }}>
                                                <p>Basic Amount:</p>
                                                <p>Total Tax:</p>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
                                                <p><img src='./images/icons/rupee.svg' alt="Rupee Symbol" />
                                                    {formatToIndianRupee(miscTotalBasicAmount)}
                                                </p>

                                                <p><img src='./images/icons/rupee.svg' alt="Rupee Symbol" />{formatToIndianRupee(miscTotalTaxAmount)}</p>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="invoice-preview-total-con">
                                        <div className="invoice-preview-total-con-left">
                                            <span>
                                                <b>Total Invoice Amount In Words (Rupees) : </b>
                                            </span>
                                            <p >{convertCurrencyToWords((parseFloat(miscTotalBasicAmount) + parseFloat(miscTotalTaxAmount)).toFixed(2))} {convertCurrencyToWords(parseFloat(miscTotalBasicAmount) + parseFloat(miscTotalTaxAmount).toFixed(2)) ? '' : ''}
                                            </p>
                                        </div>
                                        <div className="invoice-preview-total d-flex">
                                            <p className="invoice-preview-total-key" >Total : </p>
                                            <p className="invoice-preview-total-value" >&nbsp;
                                                <img src='./images/icons/rupee.svg' alt="Rupee Symbol" />&nbsp;{formatToIndianRupee((parseFloat(miscTotalBasicAmount) + parseFloat(miscTotalTaxAmount)).toFixed(2))}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* document upload */}
                            {/* <div className="responsiveInputSection" >
                                    <div>
                                        <UploadComponent
                                            onFilesSelected={handleFilesSelected}
                                            uploadMethod="single"
                                            uploadedFileData={commercialHeadGetDocumentsForContract}
                                            uploadActionClicked={handleDocumentUpload}
                                            // deleteSpecificFileMain={confirmDeleteDocument}
                                            maxNumberOfFiles={1}
                                            // handleErrorFromDocument={handleErrorFromDocument}
                                            idType={"contract"}
                                            acceptedFileTypes={".pdf, .jpeg, .jpg, .png"}
                                        />
                                    </div>
                                    
                                </div> */}

                            {/* {!preview && <div className="chat-feature">
                                <div className="chatbox">
                                    <div className="chat-user">
                                        <p className="name">User-name</p>
                                        <p>13-02-24 15:25</p>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor <span>@fin-man</span>, consectetur adipiscing elit. Maecenas vulputate,
                                        leo eu bibendum sollicitudin, risus velit posuere libero, vitae aliquet turpis
                                        sem eu massa. Proin vitae consequat ligula. Duis erat massa, imperdiet at turpis ac,
                                        luctus aliquet sapien. Nullam
                                    </p>
                                </div>
                                <div className="chatbox">
                                    <div className="chat-user">
                                        <p className="name">CEO</p>
                                        <p>13-02-24 15:25</p>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor <span>@fin-man</span>, consectetur adipiscing elit. Maecenas vulputate,
                                        leo eu bibendum sollicitudin, risus velit posuere libero, vitae aliquet turpis
                                        sem eu massa. Proin vitae consequat ligula. Duis erat massa, imperdiet at turpis ac,
                                        luctus aliquet sapien. Nullam
                                    </p>
                                </div>
                            </div>} */}
                        </div>
                    </div>
                    {!preview && <div className="invoice-preview-buttons">
                        <button onClick={() => { setCurrentPage(1); backFunction(); backPagination() }}> Back</button>
                        {editMiscInvoiceClicked ? 
                        <button style={{ opacity: addMiscInvoiceValidation() ? '100%' : '50%' }}
                        disabled={addMiscInvoiceValidation() ? false : true}
                        onClick={() => { editMiscInvoice() }}>Regenerate</button>
                        :
                        <button style={{ opacity: addMiscInvoiceValidation() ? '100%' : '50%' }}
                            disabled={addMiscInvoiceValidation() ? false : true}
                            onClick={() => { addMiscInvoice() }}>Generate</button>}
                    </div>}
                    {preview && <div className="invoice-preview-buttons">
                        <button onClick={() => setPreview(false)}> Back</button>
                    </div>}
                </div>
                }
                {/*  misc invoice */}
                {currentPage === 7 && <div className="invoice_preview_container">

                    <div className="invoice-preview-con">
                        <div className="invoice-preview-scrollable" style={{ margin: 'auto' }}>
                            <div className="invoice-preview-card">
                                <div className="invoice-preview-card-details">
                                    {companyLogo && <div className="invoice-logo-placeholder">
                                        <div>
                                            <img style={{ width: '15rem', height: '5rem' }} src={companyLogo} alt="company logo" />
                                        </div>
                                    </div>
                                    }
                                    <div className="d-flex justify-content-between w-100">
                                        <div className="invoice-header-address">
                                            <h5>{companyName?.toUpperCase()}</h5>
                                            <span ><b>Reg.Office:</b> {companyAddress}</span>
                                        </div>
                                        {
                                            QrCode?.length > 0 ?
                                                <QRCode style={{ marginTop: '-70px' }} value={QrCode} size={170} />
                                                :
                                                <div className="invoice-bar-code" style={{ marginTop: companyLogo ? '-100px' : '0px', }}>
                                                    {invoiceStatus === "rejected" ? (
                                                        <p className="rejected-text">Rejected</p>
                                                    ) : (
                                                        <p className="qr-code-text">QR Code</p>
                                                    )}
                                                </div>
                                        }

                                    </div>
                                    <h5 style={{ textAlign: "center", margin: '-15px', width: '100%', fontWeight: 'bold' }}>Tax Invoice</h5>
                                    {/* <h6 style={{ textAlign: "center", margin: '-15px', width: '100%', fontWeight: 'bold' }}>({serviceNameInInvoice})</h6> */}

                                    <div className="invoice-preview-inner-card-div">
                                        <div className="invoice-preview-inner-card">
                                            <div style={{ width: '100%' }}>
                                                <p>
                                                    <b style={{ fontSize: "18px" }}>Buyer </b>
                                                    <br /> <b>{customerName}</b><br />
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>Billed To</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerAddress}</span>
                                                    </div>
                                                    {toggleShippingAddress && <div className="invoice-left-data">
                                                        <span className="key"><b>Shipped To</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value">{shippingAddress}                                                        </span>
                                                    </div>}
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>State Code</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerStateCode}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>GSTIN</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerGSTIN}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>PAN</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerPAN}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>CIN</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerCIN}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>Place Of Supply</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerPlaceOfSupply}</span>
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="invoice-address-right">
                                            <div >
                                                <br />
                                                <p> <b>Contract No   </b> </p>
                                                <p><b>Invoice No</b> </p>
                                                <p><b>State</b></p>
                                                <p><b>State Code</b> </p>
                                                <p><b>GSTIN</b></p>
                                                <p><b>PAN</b></p>
                                                <p><b>CIN</b></p>
                                                <br />
                                                <p><b>Invoice Date</b></p>
                                                <p><b>Due Date</b></p>

                                            </div>
                                            <div style={{ marginLeft: "20px" }}>
                                                <br />
                                                <p>: {contractNo}</p>
                                                <p>: {invoiceNo}</p>
                                                <p>: {parnterState}</p>
                                                <p>: {partnerStateCode}</p>
                                                <p>: {companyGST}</p>
                                                <p>: {partnerPAN}</p>
                                                <p>: {companyCIN}</p>

                                                <br />
                                                <p>: {ackDate}</p>
                                                <p>: {partnerDueDate}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="invoice-preview-card-details-bottom">
                                    <div className="invoice-preview-inner-card-2">
                                        <p><span><b>IRN :</b> </span>{IRN}<br />
                                        </p>
                                    </div>
                                </div>

                                <div className="invoice-preview-table-container">
                                    <Table className="invoice-preview-table">
                                        <thead>
                                            <tr>
                                                <th colSpan={1} rowSpan={2}><b>Particulars of Services</b></th>
                                                <th colSpan={1} rowSpan={2}><b>SAC / HSN</b></th>
                                                <th colSpan={1}><b>Basic Amount</b></th>
                                                {!igstToggle && <th colSpan={2}><b>CGST</b></th>}
                                                {!igstToggle && <th colSpan={2}><b>SGST</b></th>}
                                                {igstToggle && <th colSpan={2}><b>IGST</b></th>}
                                                <th colSpan={1}><b>GROSS</b></th>
                                            </tr>
                                            <tr>
                                                <th>Amount <br />(INR)</th>
                                                {!igstToggle && <th>Rate <br /> (%)</th>}
                                                {!igstToggle && <th>Amount <br />(INR)</th>}
                                                {!igstToggle && <th>Rate <br />(%)</th>}
                                                {!igstToggle && <th>Amount<br /> (INR)</th>}
                                                {igstToggle && <th>Rate <br />(%)</th>}
                                                {igstToggle && <th>Amount <br />(INR)</th>}
                                                <th>Amount <br />(INR)</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {invoiceDetails?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <th className="text-start">{item?.service_name_in_invoice?.toUpperCase()},<br />
                                                            {item?.service_identifier}
                                                        </th>
                                                        <th className="text-start">{item?.hsn}</th>
                                                        <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.net_amount)}</th>

                                                        {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.cgst_rate)}</th>}
                                                        {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.cgst)}</th>}
                                                        {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.sgst_rate)}</th>}
                                                        {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.sgst)}</th>}
                                                        {igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.igst_rate)}</th>}
                                                        {igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.igst)}</th>}
                                                        <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.gross_amount)}</th>
                                                    </tr>
                                                )
                                            })
                                            }
                                            {/* Grand Total */}
                                            <tr >
                                                {/* service description */}
                                                <th style={{ fontWeight: 'bold', fontSize: 'small' }} className="text-start">
                                                    Grand Total
                                                </th>
                                                {/* sac/hsn */}
                                                <th className="text-start">
                                                </th>
                                                {/* basic amount */}
                                                <th className="text-end">
                                                    {formatToIndianRupee(basicAmount)}
                                                </th>
                                                {/* sgst rate*/}
                                                {!igstToggle && <th>

                                                </th>}
                                                {/* sgst amount*/}
                                                {!igstToggle && <th className="text-end">
                                                    {formatToIndianRupee(totalSgst)}
                                                </th>}
                                                {/* cgst rate*/}
                                                {!igstToggle && <th>

                                                </th>}
                                                {/* cgst amount*/}
                                                {!igstToggle && <th className="text-end">
                                                    {formatToIndianRupee(totalCgst)}
                                                </th>}
                                                {/* igst rate*/}
                                                {igstToggle && <th>

                                                </th>}
                                                {/* igst amount*/}
                                                {igstToggle && <th className="text-end">{formatToIndianRupee(totalIgst)}</th>}
                                                {/* gross amount */}
                                                <th className="text-end">
                                                    {formatToIndianRupee(TotalAmount)}
                                                    {/* parseFloat(each.basicAmount) + parseFloat((each.basicAmount / 100) * each.igst)) : formatToIndianRupee(parseFloat(each.basicAmount) + parseFloat((each.basicAmount / 100) * each.cgst) + parseFloat((each.basicAmount / 100) * each.sgst))} */}
                                                </th>
                                            </tr>
                                        </tbody>
                                    </Table>

                                    <div className="invoice-preview-total-con">
                                        <div className="invoice-preview-total-con-left">
                                            <span>
                                                <b>Total Invoice Amount In Words (Rupees) : </b>
                                            </span>
                                            <p >{totalAmountInWords}
                                            </p>
                                        </div>
                                        <div className="invoice-preview-total d-flex">
                                            <p className="invoice-preview-total-key" >Total : </p>
                                            {/* <span className="diagonal-line"></span> */}
                                            {/* <span></span> */}
                                            <p className="invoice-preview-total-value" >&nbsp;
                                                <img src='./images/icons/rupee.svg' alt="Rupee Symbol" />&nbsp;{formatToIndianRupee(TotalAmount)}</p>
                                        </div>
                                    </div>
                                    {userNotesToggle && <div className="invoice-user-notes-div">
                                        <p className="invoice-user-notes">User Notes</p>
                                        <p>{userNotes}</p>
                                    </div>}
                                </div>
                                <div className="signature">
                                    <p></p>
                                    <p>sign here</p>
                                </div>

                            </div>
                            {/* {!preview && !(invoiceStatus !== "rejected" && invoiceStatus!=='onhold' && invoiceStatus!== "draft") && (<div className="chat-feature">
                                <div className="chatbox">
                                    <div className="chat-user">
                                        <p className="name">User-name</p>
                                        <p>13-02-24 15:25</p>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor <span>@fin-man</span>, consectetur adipiscing elit. Maecenas vulputate,
                                        leo eu bibendum sollicitudin, risus velit posuere libero, vitae aliquet turpis
                                        sem eu massa. Proin vitae consequat ligula. Duis erat massa, imperdiet at turpis ac,
                                        luctus aliquet sapien. Nullam
                                    </p>
                                </div>
                                <div className="chatbox">
                                    <div className="chat-user">
                                        <p className="name">CEO</p>
                                        <p>13-02-24 15:25</p>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor <span>@fin-man</span>, consectetur adipiscing elit. Maecenas vulputate,
                                        leo eu bibendum sollicitudin, risus velit posuere libero, vitae aliquet turpis
                                        sem eu massa. Proin vitae consequat ligula. Duis erat massa, imperdiet at turpis ac,
                                        luctus aliquet sapien. Nullam
                                    </p>
                                </div>
                            </div>
                            )} */}
                            {/* to show payments and terms */}
                            {(paymentToggle && pdfDataForPayments && showPaymentDetails) && <PdfViewer base64Pdf={pdfDataForPayments} />}
                            {(termsToggle && pdfDataForTerms && showTermsDetails) && <PdfViewer base64Pdf={pdfDataForTerms} />}
                        </div>
                    </div>
                    {!preview && <div className="invoice-preview-buttons">
                        {downloadPath && <button onClick={() => {
                            handleDownload()
                        }} disabled={downloadPath === '' || downloadPath === null} style={{ opacity: downloadPath ? '100%' : '50%' }}>Download</button>}
                        {(invoiceStatus === "approved") && <button onClick={() => {
                            submitToGSTPortal(invoiceNo)
                        }} >Submit to GST</button>}
                        {(invoiceStatus=== "pdfgenerated" && downloadPath ) && <button onClick={() => {
                            sendToCustomer(invoiceNo)
                        }} >Send to Customer</button>}
                        {(invoiceStatus === "gstsubmitted") && <button onClick={() => {
                            handleGeneratePdf()
                        }}  >Generate PDF</button>}
                        {(invoiceStatus === "draft" || invoiceStatus === "onhold" || invoiceStatus === "rejected") && <button onClick={() => {
                            handleEditMiscInvoice()
                            setApprovalType('miscellaneous')
                        }}
                            // disabled
                            // style={{ opacity: '50%' }}
                        >Edit Misc Invoice</button>}
                        <button onClick={() => { setCurrentPage(1); setPreview(false); backFunction(); backPagination() }}> Back</button>
                        {(invoiceStatus === "draft" || invoiceStatus === "onhold" || invoiceStatus === "approved") && <button onClick={() => {
                            handleReject()
                            setApprovalType('miscellaneous')
                        }} >Reject</button>}
                        {(invoiceStatus === "draft" || invoiceStatus === "rejected" || invoiceStatus === "approved") && <button onClick={() => {
                            handleHold()
                            setApprovalType('miscellaneous')
                        }} >Hold</button>}
                        {!(invoiceStatus !== "rejected" && invoiceStatus !== 'onhold' && invoiceStatus !== "draft") && <button
                            onClick={() => {
                                editInvoice()
                                setApprovalType('miscellaneous')
                            }
                            }
                            style={{ opacity: checkSendForApprovalValidation() ? '100%' : '50%' }} disabled={!checkSendForApprovalValidation()}>Approve</button>}

                    </div>}
                    {preview && <div className="invoice-preview-buttons">
                        <button onClick={() => setPreview(false)}> Back</button>
                    </div>}
                </div>
                }
                {/* water invoice */}
                {currentPage === 4 && <div className="invoice_preview_container">

                    <div className="invoice-preview-con">
                        {/* <div className="invoice-preview-scrollable" style={{ margin: preview || (invoiceStatus !== "rejected" && invoiceStatus!=='onhold' && invoiceStatus!== "draft") ? 'auto' : '' }}> */}
                        <div className="invoice-preview-scrollable" style={{ margin: 'auto' }}>
                            <div className="invoice-preview-card">
                                <div className="invoice-preview-card-details">
                                    {companyLogo && <div className="invoice-logo-placeholder">
                                        <div>
                                            <img style={{ width: '15rem', height: '5rem' }} src={companyLogo} alt="company logo" />
                                        </div>
                                    </div>}
                                    <div className="d-flex justify-content-between w-100">
                                        <div className="invoice-header-address">
                                            <h5>{companyName?.toUpperCase()}</h5>
                                            <span ><b>Reg.Office:</b> {companyAddress}</span>
                                        </div>

                                        {
                                            QrCode?.length > 0 ?
                                                <QRCode style={{ marginTop: '-70px' }} value={QrCode} size={170} />
                                                :
                                                <div className="invoice-bar-code">
                                                    {invoiceStatus === "rejected" ? (
                                                        <p className="rejected-text">Rejected</p>
                                                    ) : (
                                                        <p className="qr-code-text">QR Code</p>
                                                    )}
                                                </div>
                                        }

                                    </div>
                                    <h5 style={{ textAlign: "center", margin: '-15px', width: '100%', fontWeight: 'bold' }}>Tax Invoice</h5>
                                    <h6 style={{ textAlign: "center", margin: '-15px', width: '100%', fontWeight: 'bold' }}>({serviceNameInInvoice})</h6>

                                    <div className="invoice-preview-inner-card-div">
                                        <div className="invoice-preview-inner-card">
                                            <div style={{ width: '100%' }}>
                                                <p>
                                                    <b style={{ fontSize: "18px" }}>Buyer </b>
                                                    <br /> <b>{customerName}</b><br />
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>Billed To</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerAddress}</span>
                                                    </div>
                                                    {toggleShippingAddress && <div className="invoice-left-data">
                                                        <span className="key"><b>Shipped To</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value">  {shippingAddress}</span>
                                                    </div>}
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>State Code</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerStateCode}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>GSTIN</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerGSTIN}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>PAN</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerPAN}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>CIN</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerCIN}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>Place Of Supply</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerPlaceOfSupply}</span>
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="invoice-address-right">
                                            <div >
                                                <br />
                                                <p> <b>Contract No.   </b> </p>
                                                <p><b>Invoice No.</b> </p>
                                                <p><b>State</b></p>
                                                <p><b>State Code</b> </p>
                                                <p><b>GSTIN</b></p>
                                                <p><b>PAN</b></p>
                                                <p><b>CIN</b></p>
                                                <br />
                                                <p><b>Invoice Date</b></p>
                                                <p><b>Due Date</b></p>

                                            </div>
                                            <div style={{ marginLeft: "20px" }}>
                                                <br />
                                                <p>: {contractNo}</p>
                                                <p>: {invoiceNo}</p>
                                                <p>: {parnterState}</p>
                                                <p>: {partnerStateCode}</p>
                                                <p>: {companyGST}</p>
                                                <p>: {partnerPAN}</p>
                                                <p>: {companyCIN}</p>

                                                <br />
                                                <p>: {ackDate}</p>
                                                <p>: {partnerDueDate}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="invoice-preview-card-details-bottom">
                                    <div className="invoice-preview-inner-card-2">
                                        <p><span><b>IRN :</b> </span>{IRN}<br /></p>
                                    </div>
                                </div>

                                <div className="invoice-preview-table-container">
                                    <Table className="invoice-preview-table">
                                        <thead>
                                            <tr>
                                                <th colSpan={1} rowSpan={2}><b>Particulars of Services</b></th>
                                                <th colSpan={1} rowSpan={2}><b>SAC / HSN</b></th>
                                                <th colSpan={2}><b>Bill Period / Reading</b></th>
                                                {showComputationDetails && <th colSpan={1}><b>Total Units</b></th>}
                                                {showComputationDetails && <th colSpan={1}><b>Rate</b></th>}
                                                {/* <th colSpan={1}><b>Meter Rent</b></th> */}
                                                <th colSpan={1}><b>Basic Amount</b></th>
                                                {!igstToggle && <th colSpan={2}><b>CGST</b></th>}
                                                {!igstToggle && <th colSpan={2}><b>SGST</b></th>}
                                                {igstToggle && <th colSpan={2}><b>IGST</b></th>}
                                                <th colSpan={1}><b>GROSS</b></th>
                                            </tr>
                                            <tr>
                                                <th>From / Op. Reading</th>
                                                <th>To / Cl. Reading</th>
                                                {showComputationDetails && <th>kL</th>}
                                                {showComputationDetails && <th>(INR / kL)</th>}
                                                <th>Amount (INR)</th>
                                                {/* <th>Amount (INR)</th> */}
                                                {!igstToggle && <th>Rate (%)</th>}
                                                {!igstToggle && <th>Amount (INR)</th>}
                                                {!igstToggle && <th>Rate (%)</th>}
                                                {!igstToggle && <th>Amount (INR)</th>}
                                                {igstToggle && <th>Rate (%)</th>}
                                                {igstToggle && <th>Amount (INR)</th>}
                                                <th>Amount (INR)</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {invoiceDetails?.map((item, index) => (
                                                <tr key={index}>
                                                    <th className="text-start">{item?.service_name_in_invoice?.toUpperCase()},<br />
                                                        {item?.service_identifier}</th>
                                                    <th className="text-start">{item?.hsn}</th>
                                                    <th style={{ textAlign: 'end' }}>
                                                        <div>
                                                            <p style={{ marginBottom: '-5px', textWrap: 'nowrap' }}>{formatDateView(item.from_date)}
                                                                <hr />
                                                                {formatToIndianRupee(item.initial_reading)}
                                                            </p>
                                                        </div>
                                                    </th>
                                                    <th style={{ textAlign: 'end' }}><div>
                                                        <p style={{ marginBottom: '-5px' }}>{formatDateView(item.to_date)}
                                                            <hr />
                                                            {formatToIndianRupee(item.final_reading)}
                                                        </p>
                                                    </div></th>
                                                    {showComputationDetails && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.consumption)}</th>}
                                                    {showComputationDetails && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.rate_per_unit)}</th>}
                                                    {/* <th>-</th> */}
                                                    <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.net_amount)}</th>
                                                    {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.cgst_rate)}</th>}
                                                    {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.cgst)}</th>}
                                                    {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.sgst_rate)}</th>}
                                                    {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.sgst)}</th>}
                                                    {igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.igst_rate)}</th>}
                                                    {igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.igst)}</th>}
                                                    <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.gross_amount)}</th>
                                                </tr>
                                            ))}
                                            {/* Grand Total */}
                                            <tr >
                                                {/* service description */}
                                                <th style={{ fontWeight: 'bold', fontSize: 'small' }} className="text-start">
                                                    Grand Total
                                                </th>
                                                {/* sac/hsn */}
                                                <th className="text-start">
                                                </th>
                                                <th className="text-start">
                                                </th>
                                                <th className="text-start">
                                                </th>
                                                <th className="text-start">
                                                </th>
                                                <th className="text-start">
                                                </th>
                                                {/* basic amount */}
                                                <th className="text-end">
                                                    {formatToIndianRupee(basicAmount)}
                                                </th>
                                                {/* sgst rate*/}
                                                {!igstToggle && <th>

                                                </th>}
                                                {/* sgst amount*/}
                                                {!igstToggle && <th className="text-end">
                                                    {formatToIndianRupee(totalSgst)}
                                                </th>}
                                                {/* cgst rate*/}
                                                {!igstToggle && <th>

                                                </th>}
                                                {/* cgst amount*/}
                                                {!igstToggle && <th className="text-end">
                                                    {formatToIndianRupee(totalCgst)}
                                                </th>}
                                                {/* igst rate*/}
                                                {igstToggle && <th>

                                                </th>}
                                                {/* igst amount*/}
                                                {igstToggle && <th className="text-end">{formatToIndianRupee(totalIgst)}</th>}
                                                {/* gross amount */}
                                                <th className="text-end">
                                                    {formatToIndianRupee(TotalAmount)}
                                                    {/* parseFloat(each.basicAmount) + parseFloat((each.basicAmount / 100) * each.igst)) : formatToIndianRupee(parseFloat(each.basicAmount) + parseFloat((each.basicAmount / 100) * each.cgst) + parseFloat((each.basicAmount / 100) * each.sgst))} */}
                                                </th>
                                            </tr>
                                        </tbody>
                                    </Table>

                                    <div className="invoice-preview-total-con">
                                        <div className="invoice-preview-total-con-left">
                                            <span>
                                                <b>Total Invoice Amount In Words (Rupees) : </b>
                                            </span>
                                            <p >{totalAmountInWords}
                                            </p>
                                        </div>
                                        <div className="invoice-preview-total d-flex ">
                                            <p className="invoice-preview-total-key">Total : </p>
                                            {/* <span className="diagonal-line"></span> */}
                                            {/* <span></span> */}
                                            <p className="invoice-preview-total-value">&nbsp;
                                                <img src='./images/icons/rupee.svg' alt="Rupee Symbol" />&nbsp;{formatToIndianRupee(TotalAmount)}</p>
                                        </div>
                                    </div>
                                    {userNotesToggle && <div className="invoice-user-notes-div">
                                        <p className="invoice-user-notes">User Notes</p>
                                        <p>{userNotes}</p>
                                    </div>}
                                </div>
                                <div className="signature">
                                    <p></p>
                                    <p>sign here</p>
                                </div>
                            </div>
                            {/* {!preview && !(invoiceStatus !== "rejected" && invoiceStatus!=='onhold' && invoiceStatus!== "draft") && <div className="chat-feature">
                                <div className="chatbox">
                                    <div className="chat-user">
                                        <p className="name">User-name</p>
                                        <p>13-02-24 15:25</p>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor <span>@fin-man</span>, consectetur adipiscing elit. Maecenas vulputate,
                                        leo eu bibendum sollicitudin, risus velit posuere libero, vitae aliquet turpis
                                        sem eu massa. Proin vitae consequat ligula. Duis erat massa, imperdiet at turpis ac,
                                        luctus aliquet sapien. Nullam
                                    </p>
                                </div>
                                <div className="chatbox">
                                    <div className="chat-user">
                                        <p className="name">CEO</p>
                                        <p>13-02-24 15:25</p>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor <span>@fin-man</span>, consectetur adipiscing elit. Maecenas vulputate,
                                        leo eu bibendum sollicitudin, risus velit posuere libero, vitae aliquet turpis
                                        sem eu massa. Proin vitae consequat ligula. Duis erat massa, imperdiet at turpis ac,
                                        luctus aliquet sapien. Nullam
                                    </p>
                                </div>
                            </div>} */}
                            {/* to show payments and terms */}
                            {(paymentToggle && pdfDataForPayments && showPaymentDetails) && <PdfViewer base64Pdf={pdfDataForPayments} />}
                            {(termsToggle && pdfDataForTerms && showTermsDetails) && <PdfViewer base64Pdf={pdfDataForTerms} />}
                        </div>
                    </div>
                    {!preview && <div className="invoice-preview-buttons">
                        {downloadPath && <button onClick={() => {
                            handleDownload()
                        }} disabled={downloadPath === '' || downloadPath === null} style={{ opacity: downloadPath ? '100%' : '50%' }}>Download</button>}
                        {(invoiceStatus === "draft" || invoiceStatus === "onhold" || invoiceStatus === "rejected") && <button onClick={() => {
                            handleRegenerate()
                            setApprovalType('Water')
                        }} >Regenerate</button>}
                        <button onClick={() => { setCurrentPage(1); setPreview(false); backFunction(); backPagination() }}> Back</button>
                        {(invoiceStatus === "draft" || invoiceStatus === "onhold" || invoiceStatus === "approved") && <button onClick={() => {
                            handleReject()
                            setApprovalType('Water')
                        }} >Reject</button>}
                        {(invoiceStatus === "draft" || invoiceStatus === "rejected" || invoiceStatus === "approved") && <button onClick={() => {
                            handleHold()
                            setApprovalType('Water')
                        }} >Hold</button>}
                        {!(invoiceStatus !== "rejected" && invoiceStatus !== 'onhold' && invoiceStatus !== "draft") && <button
                            onClick={() => {
                                editInvoice()
                                setApprovalType('Water')
                            }
                            }
                            style={{ opacity: checkSendForApprovalValidation() ? '100%' : '50%' }} disabled={!checkSendForApprovalValidation()}>Approve</button>}
                    </div>}
                    {preview && <div className="invoice-preview-buttons">
                        <button onClick={() => setPreview(false)}> Back</button>
                    </div>}
                </div>
                }
                {/* FMS invoice */}
                {currentPage === 6 && <div className="invoice_preview_container">

                    <div className="invoice-preview-con">

                        <div className="invoice-preview-scrollable" style={{ margin: 'auto' }}>
                            <div className="invoice-preview-card">
                                <div className="invoice-preview-card-details">
                                    {companyLogo && <div className="invoice-logo-placeholder">
                                        <div>
                                            <img style={{ width: '15rem', height: '5rem' }} src={companyLogo} alt="company logo" />
                                        </div>
                                    </div>
                                    }
                                    <div className="d-flex justify-content-between w-100">
                                        <div className="invoice-header-address">
                                            <h5>{companyName?.toUpperCase()}</h5>
                                            <span ><b>Reg.Office:</b> {companyAddress}</span>
                                        </div>
                                        {
                                            QrCode?.length > 0 ?
                                                <QRCode style={{ marginTop: '-70px' }} value={QrCode} size={170} />
                                                :
                                                <div className="invoice-bar-code">
                                                    {invoiceStatus === "rejected" ? (
                                                        <p className="rejected-text">Rejected</p>
                                                    ) : (
                                                        <p className="qr-code-text">QR Code</p>
                                                    )}
                                                </div>
                                        }

                                    </div>
                                    <h5 style={{ textAlign: "center", margin: '-15px', width: '100%', fontWeight: 'bold' }}>Tax Invoice</h5>
                                    <h6 style={{ textAlign: "center", margin: '-15px', width: '100%', fontWeight: 'bold' }}>({serviceNameInInvoice})</h6>
                                    <div className="invoice-preview-inner-card-div">
                                        <div className="invoice-preview-inner-card">
                                            <div style={{ width: '100%' }}>
                                                <p>
                                                    <b style={{ fontSize: "18px" }}>Buyer </b>
                                                    <br /> <b>{customerName}</b><br />
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>Billed To</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerAddress}</span>
                                                    </div>
                                                    {toggleShippingAddress && <div className="invoice-left-data">
                                                        <span className="key"><b>Shipped To</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value">  {shippingAddress}</span>
                                                    </div>}
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>State Code</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerStateCode}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>GSTIN</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerGSTIN}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>PAN</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerPAN}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>CIN</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerCIN}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>Place Of Supply</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerPlaceOfSupply}</span>
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="invoice-address-right">
                                            <div >
                                                <br />
                                                <p> <b>Contract No   </b> </p>
                                                <p><b>Invoice No</b> </p>
                                                <p><b>State</b></p>
                                                <p><b>State Code</b> </p>
                                                <p><b>GSTIN</b></p>
                                                <p><b>PAN</b></p>
                                                <p><b>CIN</b></p>
                                                <br />
                                                <p><b>Invoice Date</b></p>
                                                <p><b>Due Date</b></p>

                                            </div>
                                            <div style={{ marginLeft: "20px" }}>
                                                <br />
                                                <p>: {contractNo}</p>
                                                <p>: {invoiceNo}</p>
                                                <p>: {parnterState}</p>
                                                <p>: {partnerStateCode}</p>
                                                <p>: {companyGST}</p>
                                                <p>: {partnerPAN}</p>
                                                <p>: {companyCIN}</p>

                                                <br />
                                                <p>: {ackDate}</p>
                                                <p>: {partnerDueDate}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="invoice-preview-card-details-bottom">
                                    <div className="invoice-preview-inner-card-2">
                                        <p><span><b>IRN :</b> </span>{IRN}<br />
                                        </p>
                                    </div>
                                </div>

                                <div className="invoice-preview-table-container">
                                    <Table className="invoice-preview-table">
                                        <thead>
                                            <tr>
                                                <th colSpan={1} rowSpan={2}><b>Particulars of Services</b></th>
                                                <th colSpan={1} rowSpan={2}><b>SAC / HSN</b></th>
                                                <th colSpan={2}><b>Bill Period </b></th>
                                                {showComputationDetails && <th colSpan={1}><b>Total Area</b></th>}
                                                {showComputationDetails && <th colSpan={1}><b>Rate</b></th>}
                                                <th colSpan={1}><b>Basic Amount</b></th>
                                                {!igstToggle && <th colSpan={2}><b>CGST</b></th>}
                                                {!igstToggle && <th colSpan={2}><b>SGST</b></th>}
                                                {igstToggle && <th colSpan={2}><b>IGST</b></th>}
                                                <th colSpan={1}><b>GROSS</b></th>
                                            </tr>
                                            <tr>

                                                <th>From</th>
                                                <th>To</th>
                                                {showComputationDetails && <th>Sq. ft</th>}
                                                {showComputationDetails && <th> (INR / Sq. ft)</th>}
                                                <th>Amount <br />(INR)</th>
                                                {!igstToggle && <th>Rate <br /> (%)</th>}
                                                {!igstToggle && <th>Amount <br />(INR)</th>}
                                                {!igstToggle && <th>Rate <br />(%)</th>}
                                                {!igstToggle && <th>Amount<br /> (INR)</th>}
                                                {igstToggle && <th>Rate <br />(%)</th>}
                                                {igstToggle && <th>Amount <br />(INR)</th>}
                                                <th>Amount <br />(INR)</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {invoiceDetails?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <th className="text-start">{item?.service_name_in_invoice?.toUpperCase()},<br />
                                                            {item?.service_identifier}
                                                        </th>
                                                        <th className="text-start">{item?.hsn}</th>
                                                        <th style={{ textAlign: 'end', textWrap: 'nowrap' }}>{formatDateView(item.from_date)}</th>
                                                        <th style={{ textAlign: 'end', textWrap: 'nowrap' }}>
                                                            <div>
                                                                {formatDateView(item.to_date)}
                                                            </div>
                                                        </th>
                                                        {showComputationDetails && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.consumption)}</th>}
                                                        {showComputationDetails && <th style={{ textAlign: 'end' }}><div>
                                                            {formatToIndianRupee(item.rate_per_unit)}
                                                        </div></th>}
                                                        <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.net_amount)}</th>

                                                        {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.cgst_rate)}</th>}
                                                        {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.cgst)}</th>}
                                                        {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.sgst_rate)}</th>}
                                                        {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.sgst)}</th>}
                                                        {igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.igst_rate)}</th>}
                                                        {igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.igst)}</th>}
                                                        <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.gross_amount)}</th>
                                                    </tr>
                                                )
                                            })
                                            }
                                            {/* Grand Total */}
                                            <tr >
                                                {/* service description */}
                                                <th style={{ fontWeight: 'bold', fontSize: 'small' }} className="text-start">
                                                    Grand Total
                                                </th>
                                                {/* sac/hsn */}
                                                <th className="text-start">
                                                </th>
                                                <th className="text-start">
                                                </th>
                                                <th className="text-start">
                                                </th>
                                                {showComputationDetails && 
                                                <>
                                                    <th className="text-start">
                                                    </th>
                                                    <th className="text-start">
                                                    </th>
                                                </>}

                                                {/* basic amount */}
                                                <th className="text-end">
                                                    {formatToIndianRupee(basicAmount)}
                                                </th>
                                                {/* sgst rate*/}
                                                {!igstToggle && <th>

                                                </th>}
                                                {/* sgst amount*/}
                                                {!igstToggle && <th className="text-end">
                                                    {formatToIndianRupee(totalSgst)}
                                                </th>}
                                                {/* cgst rate*/}
                                                {!igstToggle && <th>

                                                </th>}
                                                {/* cgst amount*/}
                                                {!igstToggle && <th className="text-end">
                                                    {formatToIndianRupee(totalCgst)}
                                                </th>}
                                                {/* igst rate*/}
                                                {igstToggle && <th>

                                                </th>}
                                                {/* igst amount*/}
                                                {igstToggle && <th className="text-end">{formatToIndianRupee(totalIgst)}</th>}
                                                {/* gross amount */}
                                                <th className="text-end">
                                                    {formatToIndianRupee(TotalAmount)}
                                                    {/* parseFloat(each.basicAmount) + parseFloat((each.basicAmount / 100) * each.igst)) : formatToIndianRupee(parseFloat(each.basicAmount) + parseFloat((each.basicAmount / 100) * each.cgst) + parseFloat((each.basicAmount / 100) * each.sgst))} */}
                                                </th>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <div className="invoice-preview-total-con">
                                        <div className="invoice-preview-total-con-left">
                                            <span>
                                                <b>Total Invoice Amount In Words (Rupees) : </b>
                                            </span>
                                            <p >{totalAmountInWords}
                                            </p>
                                        </div>
                                        <div className="invoice-preview-total d-flex">
                                            <p className="invoice-preview-total-key" >Total : </p>
                                            {/* <span className="diagonal-line"></span> */}
                                            {/* <span></span> */}
                                            <p className="invoice-preview-total-value" >&nbsp;
                                                <img src='./images/icons/rupee.svg' alt="Rupee Symbol" />&nbsp;{formatToIndianRupee(TotalAmount)}</p>
                                        </div>
                                    </div>
                                    {userNotesToggle && <div className="invoice-user-notes-div">
                                        <p className="invoice-user-notes">User Notes</p>
                                        <p>{userNotes}</p>
                                    </div>}
                                </div>
                                <div className="signature">
                                    <p></p>
                                    <p>sign here</p>
                                </div>

                            </div>
                            {/* {!preview && !(invoiceStatus !== "rejected" && invoiceStatus!=='onhold' && invoiceStatus!== "draft") && (<div className="chat-feature">
                                <div className="chatbox">
                                    <div className="chat-user">
                                        <p className="name">User-name</p>
                                        <p>13-02-24 15:25</p>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor <span>@fin-man</span>, consectetur adipiscing elit. Maecenas vulputate,
                                        leo eu bibendum sollicitudin, risus velit posuere libero, vitae aliquet turpis
                                        sem eu massa. Proin vitae consequat ligula. Duis erat massa, imperdiet at turpis ac,
                                        luctus aliquet sapien. Nullam
                                    </p>
                                </div>
                                <div className="chatbox">
                                    <div className="chat-user">
                                        <p className="name">CEO</p>
                                        <p>13-02-24 15:25</p>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor <span>@fin-man</span>, consectetur adipiscing elit. Maecenas vulputate,
                                        leo eu bibendum sollicitudin, risus velit posuere libero, vitae aliquet turpis
                                        sem eu massa. Proin vitae consequat ligula. Duis erat massa, imperdiet at turpis ac,
                                        luctus aliquet sapien. Nullam
                                    </p>
                                </div>
                            </div>
                            )} */}
                            {/* to show payments and terms */}
                            {(paymentToggle && pdfDataForPayments && showPaymentDetails) && <PdfViewer base64Pdf={pdfDataForPayments} />}
                            {(termsToggle && pdfDataForTerms && showTermsDetails) && <PdfViewer base64Pdf={pdfDataForTerms} />}
                        </div>
                    </div>
                    {!preview && <div className="invoice-preview-buttons">
                        {downloadPath && <button onClick={() => {
                            handleDownload()
                        }} disabled={downloadPath === '' || downloadPath === null} style={{ opacity: downloadPath ? '100%' : '50%' }}>Download</button>}
                        {(invoiceStatus === "draft" || invoiceStatus === "onhold" || invoiceStatus === "rejected") && <button onClick={() => {
                            handleRegenerate()
                            setApprovalType('fms')
                        }} >Regenerate</button>}
                        <button onClick={() => { setCurrentPage(1); setPreview(false); backFunction(); backPagination() }}> Back</button>
                        {(invoiceStatus === "draft" || invoiceStatus === "onhold" || invoiceStatus === "approved") && <button onClick={() => {
                            handleReject()
                            setApprovalType('fms')
                        }} >Reject</button>}
                        {(invoiceStatus === "draft" || invoiceStatus === "rejected" || invoiceStatus === "approved") && <button onClick={() => {
                            handleHold()
                            setApprovalType('fms')
                        }} >Hold</button>}
                        {!(invoiceStatus !== "rejected" && invoiceStatus !== 'onhold' && invoiceStatus !== "draft") && <button
                            onClick={() => {
                                editInvoice()
                                setApprovalType('fms')
                            }
                            }
                            style={{ opacity: checkSendForApprovalValidation() ? '100%' : '50%' }} disabled={!checkSendForApprovalValidation()}>Approve</button>}

                    </div>}
                    {preview && <div className="invoice-preview-buttons">
                        <button onClick={() => setPreview(false)}> Back</button>
                    </div>}

                </div>
                }
                {/* SLF invoice */}
                {currentPage === 5 && <div className="invoice_preview_container">

                    <div className="invoice-preview-con">
                        {/* <div className="invoice-preview-scrollable" style={{ margin: preview || (invoiceStatus !== "rejected" && invoiceStatus!=='onhold' && invoiceStatus!== "draft") ? 'auto' : '' }}> */}
                        <div className="invoice-preview-scrollable" style={{ margin: 'auto' }}>
                            <div className="invoice-preview-card">
                                <div className="invoice-preview-card-details">
                                    {companyLogo && <div className="invoice-logo-placeholder">
                                        <div>
                                            <img style={{ width: '15rem', height: '5rem' }} src={companyLogo} alt="company logo" />
                                        </div>
                                    </div>}
                                    <div className="d-flex justify-content-between w-100">
                                        <div className="invoice-header-address">
                                            <h5
                                                className="invoice-header-title"
                                                style={{
                                                    fontSize: `${Math.max(40 - (companyName?.length ? companyName?.length * 0.3 : 1), 20)}px`
                                                }}
                                            >
                                                {companyName?.toUpperCase()}

                                            </h5>
                                            <span ><b>Reg.Office:</b> {companyAddress}</span>
                                        </div>
                                        {
                                            QrCode?.length > 0 ?
                                                <QRCode style={{ marginTop: '-70px' }} value={QrCode} size={170} />
                                                :
                                                <div className="invoice-bar-code">
                                                    {invoiceStatus === "rejected" ? (
                                                        <p className="rejected-text">Rejected</p>
                                                    ) : (
                                                        <p className="qr-code-text">QR Code</p>
                                                    )}
                                                </div>
                                        }

                                    </div>
                                    <h5 style={{ textAlign: "center", margin: '-15px', width: '100%', fontWeight: 'bold' }}>Tax Invoice</h5>
                                    <h6 style={{ textAlign: "center", margin: '-15px', width: '100%', fontWeight: 'bold' }}>({serviceNameInInvoice})</h6>
                                    <div className="invoice-preview-inner-card-div">
                                        <div className="invoice-preview-inner-card">
                                            <div style={{ width: '100%' }}>
                                                <p>
                                                    <b style={{ fontSize: "18px" }}>Buyer </b>
                                                    <br /> <b>{customerName}</b><br />
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>Billed To</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerAddress}</span>
                                                    </div>
                                                    {toggleShippingAddress && <div className="invoice-left-data">
                                                        <span className="key"><b>Shipped To</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value">  {shippingAddress}</span>
                                                    </div>}
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>State Code</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerStateCode}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>GSTIN</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerGSTIN}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>PAN</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerPAN}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>CIN</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerCIN}</span>
                                                    </div>
                                                    <div className="invoice-left-data">
                                                        <span className="key"><b>Place Of Supply</b></span>
                                                        <span style={{ marginRight: '3px' }}>:</span>
                                                        <span className="value"> {customerPlaceOfSupply}</span>
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="invoice-address-right">
                                            <div >
                                                <br />
                                                <p> <b>Contract No   </b> </p>
                                                <p><b>Invoice No</b> </p>
                                                <p><b>State</b></p>
                                                <p><b>State Code</b> </p>
                                                <p><b>GSTIN</b></p>
                                                <p><b>PAN</b></p>
                                                <p><b>CIN</b></p>
                                                <br />
                                                <p><b>Invoice Date</b></p>
                                                <p><b>Due Date</b></p>

                                            </div>
                                            <div style={{ marginLeft: "20px" }}>
                                                <br />
                                                <p>: {contractNo}</p>
                                                <p>: {invoiceNo}</p>
                                                <p>: {parnterState}</p>
                                                <p>: {partnerStateCode}</p>
                                                <p>: {companyGST}</p>
                                                <p>: {partnerPAN}</p>
                                                <p>: {companyCIN}</p>

                                                <br />
                                                <p>: {ackDate}</p>
                                                <p>: {partnerDueDate}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="invoice-preview-card-details-bottom">
                                    <div className="invoice-preview-inner-card-2">
                                        <p><span><b>IRN :</b> </span>{IRN}<br />
                                        </p>
                                    </div>
                                </div>

                                <div className="invoice-preview-table-container">
                                    <Table className="invoice-preview-table">
                                        <thead>
                                            <tr>
                                                <th colSpan={1} rowSpan={2}><b>Particulars of Services</b></th>
                                                <th colSpan={1} rowSpan={2}><b>SAC / HSN</b></th>
                                                <th colSpan={2}><b>Bill Period </b></th>
                                                {showComputationDetails && <th colSpan={1}><b>Total Area</b></th>}
                                                {showComputationDetails && <th colSpan={1}><b>Rate</b></th>}
                                                <th colSpan={1}><b>Basic Amount</b></th>
                                                {!igstToggle && <th colSpan={2}><b>CGST</b></th>}
                                                {!igstToggle && <th colSpan={2}><b>SGST</b></th>}
                                                {igstToggle && <th colSpan={2}><b>IGST</b></th>}
                                                <th colSpan={1}><b>GROSS</b></th>
                                            </tr>
                                            <tr>

                                                <th>From</th>
                                                <th>To</th>
                                                {showComputationDetails && <th>Sq. ft</th>}
                                                {showComputationDetails && <th> (INR / Sq. ft)</th>}
                                                <th>Amount <br />(INR)</th>
                                                {!igstToggle && <th>Rate <br /> (%)</th>}
                                                {!igstToggle && <th>Amount <br />(INR)</th>}
                                                {!igstToggle && <th>Rate <br />(%)</th>}
                                                {!igstToggle && <th>Amount<br /> (INR)</th>}
                                                {igstToggle && <th>Rate <br />(%)</th>}
                                                {igstToggle && <th>Amount <br />(INR)</th>}
                                                <th>Amount <br />(INR)</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {invoiceDetails?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <th className="text-start">{item?.service_name_in_invoice?.toUpperCase()},<br />
                                                            {item?.service_identifier}
                                                        </th>
                                                        <th className="text-start">{item?.hsn}</th>
                                                        <th style={{ textAlign: 'end' }}>{formatDateView(item.from_date)}</th>
                                                        <th style={{ textAlign: 'end' }}>
                                                            <div>
                                                                {formatDateView(item.to_date)}
                                                            </div>
                                                        </th>
                                                        {showComputationDetails && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.consumption)}</th>}
                                                        {showComputationDetails && <th style={{ textAlign: 'end' }}><div>
                                                            {formatToIndianRupee(item.rate_per_unit)}
                                                        </div></th>}
                                                        <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.net_amount)}</th>

                                                        {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.cgst_rate)}</th>}
                                                        {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.cgst)}</th>}
                                                        {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.sgst_rate)}</th>}
                                                        {!igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.sgst)}</th>}
                                                        {igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.igst_rate)}</th>}
                                                        {igstToggle && <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.igst)}</th>}
                                                        <th style={{ textAlign: 'end' }}>{formatToIndianRupee(item.gross_amount)}</th>
                                                    </tr>
                                                )
                                            })
                                            }
                                            {/* Grand Total */}
                                            <tr >
                                                {/* service description */}
                                                <th style={{ fontWeight: 'bold', fontSize: 'small' }} className="text-start">
                                                    Grand Total
                                                </th>
                                                {/* sac/hsn */}
                                                <th className="text-start">
                                                </th>
                                                <th className="text-start">
                                                </th>
                                                <th className="text-start">
                                                </th>
                                                <th className="text-start">
                                                </th>
                                                <th className="text-start">
                                                </th>
                                                {/* basic amount */}
                                                <th className="text-end">
                                                    {formatToIndianRupee(basicAmount)}
                                                </th>
                                                {/* sgst rate*/}
                                                {!igstToggle && <th>

                                                </th>}
                                                {/* sgst amount*/}
                                                {!igstToggle && <th className="text-end">
                                                    {formatToIndianRupee(totalSgst)}
                                                </th>}
                                                {/* cgst rate*/}
                                                {!igstToggle && <th>

                                                </th>}
                                                {/* cgst amount*/}
                                                {!igstToggle && <th className="text-end">
                                                    {formatToIndianRupee(totalCgst)}
                                                </th>}
                                                {/* igst rate*/}
                                                {igstToggle && <th>

                                                </th>}
                                                {/* igst amount*/}
                                                {igstToggle && <th className="text-end">{formatToIndianRupee(totalIgst)}</th>}
                                                {/* gross amount */}
                                                <th className="text-end">
                                                    {formatToIndianRupee(TotalAmount)}
                                                    {/* parseFloat(each.basicAmount) + parseFloat((each.basicAmount / 100) * each.igst)) : formatToIndianRupee(parseFloat(each.basicAmount) + parseFloat((each.basicAmount / 100) * each.cgst) + parseFloat((each.basicAmount / 100) * each.sgst))} */}
                                                </th>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <div className="invoice-preview-total-con">
                                        <div className="invoice-preview-total-con-left">
                                            <span>
                                                <b>Total Invoice Amount In Words (Rupees) : </b>
                                            </span>
                                            <p >{totalAmountInWords}
                                            </p>
                                        </div>
                                        <div className="invoice-preview-total d-flex">
                                            <p className="invoice-preview-total-key" >Total : </p>
                                            {/* <span className="diagonal-line"></span> */}
                                            {/* <span></span> */}
                                            <p className="invoice-preview-total-value" >&nbsp;
                                                <img src='./images/icons/rupee.svg' alt="Rupee Symbol" />&nbsp;{formatToIndianRupee(TotalAmount)}</p>
                                        </div>
                                    </div>
                                    {userNotesToggle && <div className="invoice-user-notes-div">
                                        <p className="invoice-user-notes">User Notes</p>
                                        <p>{userNotes}</p>
                                    </div>}
                                </div>
                                <div className="signature">
                                    <p></p>
                                    <p>sign here</p>
                                </div>

                            </div>
                            {/* {!preview && !(invoiceStatus !== "rejected" && invoiceStatus!=='onhold' && invoiceStatus!== "draft") && (<div className="chat-feature">
                                <div className="chatbox">
                                    <div className="chat-user">
                                        <p className="name">User-name</p>
                                        <p>13-02-24 15:25</p>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor <span>@fin-man</span>, consectetur adipiscing elit. Maecenas vulputate,
                                        leo eu bibendum sollicitudin, risus velit posuere libero, vitae aliquet turpis
                                        sem eu massa. Proin vitae consequat ligula. Duis erat massa, imperdiet at turpis ac,
                                        luctus aliquet sapien. Nullam
                                    </p>
                                </div>
                                <div className="chatbox">
                                    <div className="chat-user">
                                        <p className="name">CEO</p>
                                        <p>13-02-24 15:25</p>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor <span>@fin-man</span>, consectetur adipiscing elit. Maecenas vulputate,
                                        leo eu bibendum sollicitudin, risus velit posuere libero, vitae aliquet turpis
                                        sem eu massa. Proin vitae consequat ligula. Duis erat massa, imperdiet at turpis ac,
                                        luctus aliquet sapien. Nullam
                                    </p>
                                </div>
                            </div>
                            )} */}
                            {/* to show payments and terms */}
                            {(paymentToggle && pdfDataForPayments && showPaymentDetails) && <PdfViewer base64Pdf={pdfDataForPayments} />}
                            {(termsToggle && pdfDataForTerms && showTermsDetails) && <PdfViewer base64Pdf={pdfDataForTerms} />}
                        </div>
                    </div>
                    {!preview && <div className="invoice-preview-buttons">
                        {downloadPath && <button onClick={() => {
                            handleDownload()
                        }} disabled={downloadPath === '' || downloadPath === null} style={{ opacity: downloadPath ? '100%' : '50%' }}>Download</button>}
                        {(invoiceStatus === "draft" || invoiceStatus === "onhold" || invoiceStatus === "rejected") && <button onClick={() => {
                            handleRegenerate()
                            setApprovalType('slf')
                        }} >Regenerate</button>}
                        <button onClick={() => { setCurrentPage(1); setPreview(false); backFunction(); backPagination() }}> Back</button>
                        {(invoiceStatus === "draft" || invoiceStatus === "onhold" || invoiceStatus === "approved") && <button onClick={() => {
                            handleReject()
                            setApprovalType('slf')
                        }} >Reject</button>}
                        {(invoiceStatus === "draft" || invoiceStatus === "rejected" || invoiceStatus === "approved") && <button onClick={() => {
                            handleHold()
                            setApprovalType('slf')
                        }} >Hold</button>}
                        {!(invoiceStatus !== "rejected" && invoiceStatus !== 'onhold' && invoiceStatus !== "draft") && <button
                            onClick={() => {
                                editInvoice()
                                setApprovalType('slf')
                            }
                            }
                            style={{ opacity: checkSendForApprovalValidation() ? '100%' : '50%' }} disabled={!checkSendForApprovalValidation()}>Approve</button>}

                    </div>}
                    {preview && <div className="invoice-preview-buttons">
                        <button onClick={() => setPreview(false)}> Back</button>
                    </div>}

                </div>
                }
                <Modal
                    show={slfShow}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    className="slf-preview-modal"
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Fee Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="slf-preview-table-con">
                            <Table className="slf-preivew-table">
                                <thead>
                                    <tr>
                                        <th>Year</th>
                                        <th>Space</th>
                                        <th>License Fee</th>
                                        <th>Escalation Rate</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Locking Period</th>
                                        <th>Payment Term</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {slfDetails?.map((each, index) => {
                                        return (
                                            <tr className="d-flex" key={index}>
                                                <th>{each.year}</th>
                                                <th>{each.space}</th>
                                                <th>{each.license_fee}</th>
                                                <th>{each.escation}</th>
                                                <th>{each.start_date}</th>
                                                <th>{each.end_date}</th>
                                                <th>{each.locking_period}</th>
                                                <th>{each.Payement}</th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* for rejection pop up */}
                <Modal keyboard={false} backdrop="static" show={rejectModal} centered className="popper-modal">
                    <Modal.Body className="text-center mt-4">
                        <>
                            <h5>Do you reject this invoice?</h5>
                            <p style={{ textAlign: 'start', marginBottom: '-1px' }}>Please mention reason for rejection</p>
                            <textarea
                                name=""
                                id=""
                                value={rejectReason}
                                onChange={e => {
                                    const value = e.target.value.trimStart(); // Remove leading spaces
                                    const regex = /^[a-zA-Z0-9 .,!?@#%^&*()_+=-]*$/; // Allow only standard keyboard characters
                                    if (regex.test(value)) {
                                        if (value.length <= 250) {
                                            setRejectReason(value);
                                            setRejectReasonError("");
                                        } else {
                                            setRejectReasonError("Cannot exceed 250 characters");
                                        }
                                    } else {
                                        setRejectReasonError("Invalid characters entered");
                                    }
                                }}
                                style={{ width: '100%', borderRadius: '8px', borderColor: 'grey', outline: "none", padding: '10px' }}
                                rows="5"
                            ></textarea>
                        </>
                    </Modal.Body>
                    <Modal.Footer className="footer d-flex justify-content-center">
                        <Button style={{ width: `45%` }} variant="secondary" className="secondary" onClick={() => {
                            setRejectReason('');
                            setRejectReasonError('');
                            setRejectModal(false);
                        }}>
                            Close
                        </Button>
                        <Button style={{ width: `45%`, opacity: rejectReason.length > 0 ? '100%' : '50%' }} variant="secondary" className="secondary"
                            disabled={rejectReason.length <= 0}
                            onClick={() => {
                                const trimmedReason = rejectReason.trim(); // Trim leading and trailing spaces on submit
                                if (!trimmedReason) {
                                    setRejectReasonError("Reason cannot be empty");
                                } else {
                                    setRejectReasonError("");
                                    setRejectModal(false);
                                    rejectAnInvoice(trimmedReason); // Proceed with the valid reason
                                }
                            }}
                        >
                            Reject
                        </Button>
                    </Modal.Footer>
                </Modal>
                {(loading || isLoading) && <FormLoader />}
                {contractApiLoader && <FormLoader />}
            </div>
            <Footer />
        </>
    )
}

export default FinanceManagerInvoice