import { base_url } from "../../utilities";
import axios from "../../axios-interceptor";
import { v4 as uuidv4 } from 'uuid';
import { CEO_GROUP, COMMERCIAL_HEAD_GROUP, FACILITY_MANAGER_GROUP, FINANCE_MANAGER_GROUP, TECHNICIAN_GROUP } from "../../Config";

export const GET_CH_ALL_DEPARTMENTS_SUCCESS = "GET_CH_ALL_DEPARTMENTS_SUCCESS";
export const GET_CH_ALL_DEPARTMENTS_FAILURE = "GET_CH_ALL_DEPARTMENTS_FAILURE";

export const PUT_CH_ADD_NEW_CONTRACT_SUCCESS = "PUT_CH_ADD_NEW_CONTRACT_SUCCESS";
export const PUT_CH_ADD_NEW_CONTRACT_FAILURE = "PUT_CH_ADD_NEW_CONTRACT_FAILURE";

export const PUT_CH_LICENSES_AND_PERMITS_SUCCESS = "PUT_CH_LICENSES_AND_PERMITS_SUCCESS";
export const PUT_CH_LICENSES_AND_PERMITS_FAILURE = "PUT_CH_LICENSES_AND_PERMITS_FAILURE";

export const PUT_CH_ADD_NEW_LICENSE_PERMIT_SUCCESS = "PUT_CH_ADD_NEW_LICENSE_PERMIT_SUCCESS";
export const PUT_CH_ADD_NEW_LICENSE_PERMIT_FAILURE = "PUT_CH_ADD_NEW_LICENSE_PERMIT_FAILURE";

export const PUT_CH_ADD_NEW_FMSCONTRACT_SUCCESS = "PUT_CH_ADD_NEW_FMSCONTRACT_SUCCESS";
export const PUT_CH_ADD_NEW_FMSCONTRACT_FAILURE = "PUT_CH_ADD_NEW_FMSCONTRACT_FAILURE";

export const PUT_CH_ADD_NEW_FMSCONTACT_SUCCESS = "PUT_CH_ADD_NEW_FMSCONTACT_SUCCESS";
export const PUT_CH_ADD_NEW_FMSCONTACT_FAILURE = "PUT_CH_ADD_NEW_FMSCONTACT_FAILURE";

export const PUT_GST_SUBMIT_SUCCESS = "PUT_GST_SUBMIT_SUCCESS";
export const PUT_GST_SUBMIT_FAILURE = "PUT_GST_SUBMIT_FAILURE";

export const GET_GST_LOGS_SUCCESS = "GET_GST_LOGS_SUCCESS";
export const GET_GST_LOGS_FAILURE = "GET_GST_LOGS_FAILURE";

export const GET_EMAIL_LOGS_SUCCESS = "GET_EMAIL_LOGS_SUCCESS";
export const GET_EMAIL_LOGS_FAILURE = "GET_EMAIL_LOGS_FAILURE";

export const SET_ERROR_FALSE = "SET_ERROR_FALSE";

export const PUT_CH_SUBMIT_CONTRACT_SUCCESS = "PUT_CH_SUBMIT_CONTRACT_SUCCESS";
export const PUT_CH_SUBMIT_CONTRACT_FAILURE = "PUT_CH_SUBMIT_CONTRACT_FAILURE";

export const PUT_INVOICE_DATA_SUCCESS = "PUT_INVOICE_DATA_SUCCESS";
export const PUT_INVOICE_DATA_FAILURE = "PUT_INVOICE_DATA_FAILURE";

export const REGENERATE_INVOICE_DATA_SUCCESS = "REGENERATE_INVOICE_DATA_SUCCESS";
export const REGENERATE_INVOICE_DATA_FAILURE = "REGENERATE_INVOICE_DATA_FAILURE";

export const UPDATE_CH_DRAFT_CONTRACT_SUCCESS = "UPDATE_CH_DRAFT_CONTRACT_SUCCESS";
export const UPDATE_CH_DRAFT_CONTRACT_FAILURE = "UPDATE_CH_DRAFT_CONTRACT_FAILURE";

export const UPDATE_CH_FMSCONTRACT_SUCCESS = "UPDATE_CH_FMSCONTRACT_SUCCESS";
export const UPDATE_CH_FMSCONTRACT_FAILURE = "UPDATE_CH_FMSCONTRACT_FAILURE";

export const GET_CH_CONTRACTS_SUCCESS = "GET_CH_CONTRACTS_SUCCESS";
export const GET_CH_CONTRACTS_FAILURE = "GET_CH_CONTRACTS_FAILURE";

export const GET_CH_FMSCONTRACTS_SUCCESS = "GET_CH_FMSCONTRACTS_SUCCESS";
export const GET_CH_FMSCONTRACTS_FAILURE = "GET_CH_FMSCONTRACTS_FAILURE";


export const GET_CH_CONTRACTS_SUCCESS_FOR_DROPDOWN = "GET_CH_CONTRACTS_SUCCESS_FOR_DROPDOWN";
export const GET_CH_CONTRACTS_FAILURE_FOR_DROPDOWN = "GET_CH_CONTRACTS_FAILURE_FOR_DROPDOWN";

export const GET_CH_CONTRACTS_SUCCESS_FOR_DROPDOWN_TECHNICIAN = "GET_CH_CONTRACTS_SUCCESS_FOR_DROPDOWN_TECHNICIAN";
export const GET_CH_CONTRACTS_FAILURE_FOR_DROPDOWN_TECHNICIAN = "GET_CH_CONTRACTS_FAILURE_FOR_DROPDOWN_TECHNICIAN";

export const GET_CH_FMSCONTRACTS_SUCCESS_FOR_DROPDOWN = "GET_CH_FMSCONTRACTS_SUCCESS_FOR_DROPDOWN";
export const GET_CH_FMSCONTRACTS_FAILURE_FOR_DROPDOWN = "GET_CH_FMSCONTRACTS_FAILURE_FOR_DROPDOWN";

export const PUT_TC_SUMBIT_METER_READING_SUCCESS = "PUT_TC_SUMBIT_METER_READING_SUCCESS";
export const PUT_TC_SUMBIT_METER_READING_FAILURE = "PUT_TC_SUMBIT_METER_READING_FAILURE";

export const PUT_TC_SUMBIT_METER_IMAGE_SUCCESS = "PUT_TC_SUMBIT_METER_IMAGE_SUCCESS";
export const PUT_TC_SUMBIT_METER_IMAGE_FAILURE = "PUT_TC_SUMBIT_METER_IMAGE_FAILURE";

export const GET_CH_DRAFT_CONTRACTS_SUCCESS = "GET_CH_DRAFT_CONTRACTS_SUCCESS";
export const GET_CH_DRAFT_CONTRACTS_FAILURE = "GET_CH_DRAFT_CONTRACTS_FAILURE";

export const PUT_ADD_MISC_INVOICE_SUCCESS = "PUT_ADD_MISC_INVOICE_SUCCESS";
export const PUT_ADD_MISC_INVOICE_FAILURE = "PUT_ADD_MISC_INVOICE_FAILURE";

export const GET_CH_DRAFT_FMSCONTRACTS_SUCCESS = "GET_CH_DRAFT_CONTRACTS_SUCCESS";
export const GET_CH_DRAFT_FMSCONTRACTS_FAILURE = "GET_CH_DRAFT_CONTRACTS_FAILURE";

export const PUT_CH_ADD_NEW_CONTRACT_DELETE_SUCCESS = "PUT_CH_ADD_NEW_CONTRACT_DELETE_SUCCESS";

export const GET_CH_ALL_METERS_SUCCCESS = "GET_CH_ALL_METERS_SUCCCESS";
export const GET_CH_ALL_METERS_FAILURE = "GET_CH_ALL_METERS_FAILURE"

export const PUT_CH_ADD_NEW_FACILITY_SUCCESS = 'PUT_CH_ADD_NEW_FACILITY_SUCCESS'
export const PUT_CH_ADD_NEW_FACILITY_FAILURE = 'PUT_CH_ADD_NEW_FACILITY_FAILURE'


export const FIN_MAN_GET_ALL_METER_READINGS_SUCCESS = 'FIN_MAN_GET_ALL_METER_READINGS_SUCCESS'
export const FIN_MAN_GET_ALL_METER_READINGS_FAILURE = 'FIN_MAN_GET_ALL_METER_READINGS_FAILURE'

export const PUT_CH_ADD_NEW_METER_SUCCESS = 'PUT_CH_ADD_NEW_METER_SUCCESS'
export const PUT_CH_ADD_NEW_METER_FAILURE = 'PUT_CH_ADD_NEW_METER_FAILURE'

export const PUT_CH_UPDATE_METER_SUCCESS = 'PUT_CH_UPDATE_METER_SUCCESS'
export const PUT_CH_UPDATE_METER_FAILURE = 'PUT_CH_UPDATE_METER_FAILURE'


export const PUT_CH_UPDATE_FACILITY_SUCCESS = 'PUT_CH_UPDATE_FACILITY_SUCCESS'
export const PUT_CH_UPDATE_FACILITY_FAILURE = 'PUT_CH_UPDATE_FACILITY_FAILURE'

export const TOTAL_NUMBER_OF_RECORDS = 'TOTAL_NUMBER_OF_RECORDS'
export const CREATE_NEW_FIELD = "CREATE_NEW_FIELD"
export const GET_ALL_FIELDS = "GET_ALL_FIELDS"
export const FETCHING_ERROR = "FETCHING_ERROR"
export const SET_LOADER = "SET_LOADER"
export const SHOW_MODAL = "SHOW_MODAL"
export const DELETE_FIELD = "DELETE_FIELD"
export const DELETE_FIELD_ERROR = "DELETE_FIELD_ERROR"
export const CREATE_NEW_FIELD_ERROR = "CREATE_NEW_FIELD_ERROR"
export const FILTER_FIELDS = "FILTER_FIELDS"


export const CREATE_NEW_PANEL = "CREATE_NEW_PANEL"
export const CREATE_PANEL_ERROR = "CREATE_PANEL_ERROR"
export const GET_ALL_PANELS = "GET_ALL_PANELS"
export const FILTER_PANELS = "FILTER_PANELS"
export const DELETE_PANEL = "DELETE_PANEL"
export const DELETE_PANEL_ERROR = "DELETE_PANEL_ERROR"

export const CREATE_NEW_TEMPLATE = "CREATE_NEW_TEMPLATE"
export const CREATE_NEW_TEMPLATE_ERROR = "CREATE_NEW_TEMPLATE_ERROR"
export const GET_ALL_TEMPLATES = "GET_ALL_TEMPLATES"

export const GET_ALL_FORMS = "GET_ALL_FORMS"

export const CREATION_SUCCESS = "CREATION_SUCCESS"
export const CREATION_ERROR = "CREATION_ERROR"

export const GET_CH_UTILITY_RATES_SUCCESS = "GET_CH_UTILITY_RATES_SUCCESS";
export const GET_CH_UTILITY_RATES_FAILURE = "GET_CH_UTILITY_RATES_FAILURE";

export const UPDATE_COMPLETE_CONTRACT_SUCCESS = "UPDATE_COMPLETE_CONTRACT_SUCCESS";
export const UPDATE_COMPLETE_CONTRACT_FAILURE = "UPDATE_COMPLETE_CONTRACT_FAILURE";

export const FETCH_FACILITIES_SUCCESS = "FETCH_FACILITIES_SUCCESS";
export const FETCH_FACILITIES_FAILURE = "FETCH_FACILITIES_FAILURE";
export const FETCH_FACILITIES_SUCCESS_FOR_NAV = "FETCH_FACILITIES_SUCCESS_FOR_NAV";
export const FETCH_FACILITIES_FAILURE_FOR_NAV = "FETCH_FACILITIES_FAILURE_FOR_NAV";

export const COMMERCIAL_HEAD_UPLOAD_DOCUMENT_SUCCESS = "COMMERCIAL_HEAD_UPLOAD_DOCUMENT_SUCCESS";
export const COMMERCIAL_HEAD_UPLOAD_DOCUMENT_FAILURE = "COMMERCIAL_HEAD_UPLOAD_DOCUMENT_FAILURE";

export const GET_CH_BILLING_ENTITIES_SUCCESS = "GET_CH_BILLING_ENTITIES_SUCCESS";
export const GET_CH_BILLING_ENTITIES_FAILURE = "GET_CH_BILLING_ENTITIES_FAILURE";

export const COMMERCIAL_HEAD_GET_DOCUMENTS_SUCCESS = "COMMERCIAL_HEAD_GET_DOCUMENTS_SUCCESS";
export const COMMERCIAL_HEAD_GET_DOCUMENTS_FAILURE = "COMMERCIAL_HEAD_GET_DOCUMENTS_FAILURE";

export const COMMERCIAL_HEAD_GET_FACILITY_DOCUMENTS_SUCCESS = "COMMERCIAL_HEAD_GET_FACILITY_DOCUMENTS_SUCCESS";
export const COMMERCIAL_HEAD_GET_FACILITY_DOCUMENTS_FAILURE = "COMMERCIAL_HEAD_GET_FACILITY_DOCUMENTS_FAILURE";

export const COMMERCIAL_HEAD_UPLOAD_FACILITY_DOCUMENTS_SUCCESS = "COMMERCIAL_HEAD_UPLOAD_FACILITY_DOCUMENTS_SUCCESS";
export const COMMERCIAL_HEAD_UPLOAD_FACILITY_DOCUMENTS_FAILURE = "COMMERCIAL_HEAD_UPLOAD_FACILITY_DOCUMENTS_FAILURE";

export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";

export const GET_ALL_INVOICES = "GET_ALL_INVOICES";
export const GET_ALL_INVOICES_ERROR = "GET_ALL_INVOICES_ERROR";
export const NEXT_PAGE_INVOICES = "NEXT_PAGE_INVOICES";
export const SET_PAGINATION_COUNT_TO = "SET_PAGINATION_COUNT_TO";
export const SET_PAGINATION_COUNT_INCREASE = "SET_PAGINATION_COUNT_INCREASE";
export const SET_PAGINATION_COUNT_DECREASE = "SET_PAGINATION_COUNT_DECREASE";
export const DOCUMENT_LOADER_TRIGGER_TRUE = "DOCUMENT_LOADER_TRIGGER_TRUE";
export const DOCUMENT_LOADER_TRIGGER_FALSE = "DOCUMENT_LOADER_TRIGGER_FALSE";

export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR";
export const SEARCH_TERM = "SEARCH_TERM";

export const GET_ALL_SUPERVISOR_LIST = "GET_ALL_SUPERVISOR_LIST";
export const GET_ALL_SUPERVISOR_LIST_ERROR = "GET_ALL_SUPERVISOR_LIST_ERROR";

export const AUTHENTICATE_USER_SUCCESS = "AUTHENTICATE_USER_SUCCESS";
export const AUTHENTICATE_USER_FAILURE = "AUTHENTICATE_USER_FAILURE";

export const FAC_MAN_GET_ALL_UNASSIGNED_METERS_SUCCESS = "FAC_MAN_GET_ALL_UNASSIGNED_METERS_SUCCESS";
export const FAC_MAN_GET_ALL_UNASSIGNED_METERS_FAILURE = "FAC_MAN_GET_ALL_UNASSIGNED_METERS_FAILURE";

export const FAC_MAN_GET_ASSIGNED_METERS_SUCCESS = "FAC_MAN_GET_ASSIGNED_METERS_SUCCESS";
export const FAC_MAN_GET_ASSIGNED_METERS_FAILURE = "FAC_MAN_GET_ASSIGNED_METERS_FAILURE";
export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";
export const UPDATE_USER_STATUS_FAILURE = "UPDATE_USER_STATUS_FAILURE";

export const GET_HSN_SUCCESS = "GET_HSN_SUCCESS";
export const GET_HSN_FAILURE = "GET_HSN_FAILURE";

export const UPDATE_HSN_SUCCESS = "UPDATE_HSN_SUCCESS";
export const UPDATE_HSN_FAILURE = "UPDATE_HSN_FAILURE";

export const GET_TAX_RATES_SUCCESS = "GET_TAX_RATES_SUCCESS";
export const GET_TAX_RATES_FAILURE = "GET_TAX_RATES_FAILURE";

export const UPDATE_TAX_RATES_SUCCESS = "UPDATE_TAX_RATES_SUCCESS";
export const UPDATE_TAX_RATES_FAILURE = "UPDATE_TAX_RATES_FAILURE";

export const GET_ALL_PARTNER_COMPANIES_SUCCESS = "GET_ALL_PARTNER_COMPANIES_SUCCESS";
export const GET_ALL_PARTNER_COMPANIES_FAILURE = "GET_ALL_PARTNER_COMPANIES_FAILURE";

export const GET_PARTNER_COMPANY_DETAILS_SUCCESS = "GET_PARTNER_COMPANY_DETAILS_SUCCESS";
export const GET_PARTNER_COMPANY_DETAILS_FAILURE = "GET_PARTNER_COMPANY_DETAILS_FAILURE";

export const UPDATE_PARTNER_COMPANY_DETAILS_SUCCESS = "UPDATE_PARTNER_COMPANY_DETAILS_SUCCESS";
export const UPDATE_PARTNER_COMPANY_DETAILS_FAILURE = "UPDATE_PARTNER_COMPANY_DETAILS_FAILURE";

export const CREATE_PARTNER_COMPANY_SUCCESS = "CREATE_PARTNER_COMPANY_SUCCESS";
export const CREATE_PARTNER_COMPANY_FAILURE = "CREATE_PARTNER_COMPANY_FAILURE";

export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILURE = "SET_PASSWORD_FAILURE";

export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE";

export const UPLOAD_TERMS_AND_CONDITIONS_SUCCESS = "UPLOAD_TERMS_AND_CONDITIONS_SUCCESS";
export const UPLOAD_TERMS_AND_CONDITIONS_FAILURE = "UPLOAD_TERMS_AND_CONDITIONS_FAILURE";

export const UPLOAD_PAYMENT_TERMS_SUCCESS = "UPLOAD_PAYMENT_TERMS_SUCCESS";
export const UPLOAD_PAYMENT_TERMS_FAILURE = "UPLOAD_PAYMENT_TERMS_FAILURE";

export const PAGINATION_LOADER_TRIGGER_TRUE = "PAGINATION_LOADER_TRIGGER_TRUE";
export const PAGINATION_LOADER_TRIGGER_FALSE = "PAGINATION_LOADER_TRIGGER_FALSE";

export const CONTRACT_API_LOADER_TRIGGER_TRUE = "CONTRACT_API_LOADER_TRIGGER_TRUE";
export const CONTRACT_API_LOADER_TRIGGER_FALSE = "CONTRACT_API_LOADER_TRIGGER_FALSE";

export const UPLOAD_LOGO_SUCCESS = "UPLOAD_LOGO_SUCCESS";
export const UPLOAD_LOGO_FAILURE = "UPLOAD_LOGO_FAILURE";

export const ADD_NEW_SERVICE_SUCCESS = "ADD_NEW_SERVICE_SUCCESS";
export const ADD_NEW_SERVICE_FAILURE = "ADD_NEW_SERVICE_FAILURE";

export const GET_ALL_SERVICES_SUCCESS = "GET_ALL_SERVICES_SUCCESS";
export const GET_ALL_SERVICES_FAILURE = "GET_ALL_SERVICES_FAILURE";

export const GET_SERVICE_DETAILS_SUCCESS = "GET_SERVICE_DETAILS_SUCCESS";
export const GET_SERVICE_DETAILS_FAILURE = "GET_SERVICE_DETAILS_FAILURE";

export const UPDATE_SERVICE_DETAILS_SUCCESS = "UPDATE_SERVICE_DETAILS_SUCCESS";
export const UPDATE_SERVICE_DETAILS_FAILURE = "UPDATE_SERVICE_DETAILS_FAILURE";

export const GET_HSN_CODES_SUCCESS = "GET_HSN_CODES_SUCCESS";
export const GET_HSN_CODES_FAILURE = "GET_HSN_CODES_FAILURE";

export const UPLOAD_DSC_SUCCESS = "UPLOAD_DSC_SUCCESS";
export const UPLOAD_DSC_FAILURE = "UPLOAD_DSC_FAILURE";

export const GET_ALL_METER_READINGS_BY_METER_ID_SUCCESS = "GET_ALL_METER_READINGS_BY_METER_ID_SUCCESS";
export const GET_ALL_METER_READINGS_BY_METER_ID_FAILURE = "GET_ALL_METER_READINGS_BY_METER_ID_FAILURE";

export const UPDATE_METER_READING_SUCCESS = 'UPDATE_METER_READING_SUCCESS';
export const UPDATE_METER_READING_FAILURE = 'UPDATE_METER_READING_FAILURE';
export const UPDATE_METER_READING_API_LOADER_TRIGGER_TRUE = 'UPDATE_METER_READING_API_LOADER_TRIGGER_TRUE';
export const UPDATE_METER_READING_API_LOADER_TRIGGER_FALSE = 'UPDATE_METER_READING_API_LOADER_TRIGGER_FALSE';

export const GET_ALL_ASSETS_LIST_SUCCESS = "GET_ALL_ASSETS_LIST_SUCCESS";
export const GET_ALL_ASSETS_LIST_FAILURE = "GET_ALL_ASSETS_LIST_FAILURE";

export const GET_ASSET_CATEGORY_SUCCESS = "GET_ASSET_CATEGORY_SUCCESS";
export const GET_ASSET_CATEGORY_FAILURE = "GET_ASSET_CATEGORY_FAILURE";

export const GET_EVENT_CATEGORIES_SUCCESS = "GET_EVENT_CATEGORIES_SUCCESS";
export const GET_EVENT_CATEGORIES_FAILURE = "GET_EVENT_CATEGORIES_FAILURE";

export const GET_ASSETS_BY_CATEGORY_SUCCESS = "GET_ASSETS_BY_CATEGORY_SUCCESS";
export const GET_ASSETS_BY_CATEGORY_FAILURE = "GET_ASSETS_BY_CATEGORY_FAILURE";

export const CREATE_SCHEDULE_EVENT_SUCCESS = "CREATE_SCHEDULE_EVENT_SUCCESS";
export const CREATE_SCHEDULE_EVENT_FAILURE = "CREATE_SCHEDULE_EVENT_FAILURE";

export const GET_FACMAN_FROM_USERS = "GET_FACMAN_FROM_USERS";
export const GET_FACMAN_FROM_USERS_ERROR = "GET_FACMAN_FROM_USERS_ERROR";

export const GET_ASSET_STATUS_LIST_SUCCESS = "GET_ASSET_STATUS_LIST_SUCCESS";
export const GET_ASSET_STATUS_LIST_FAILURE = "GET_ASSET_STATUS_LIST_FAILURE";

export const GET_ASSET_CATEGORY_LIST_SUCCESS = "GET_ASSET_CATEGORY_LIST_SUCCESS";
export const GET_ASSET_CATEGORY_LIST_FAILURE = "GET_ASSET_CATEGORY_LIST_FAILURE";

export const GET_ASSET_TYPE_LIST_SUCCESS = "GET_ASSET_TYPE_LIST_SUCCESS";
export const GET_ASSET_TYPE_LIST_FAILURE = "GET_ASSET_TYPE_LIST_FAILURE";

export const CREATING_NEW_ASSET_SUCCESS = "CREATING_NEW_ASSET_SUCCESS";
export const CREATING_NEW_ASSET_FAILURE = "CREATING_NEW_ASSET_FAILURE";

export const UPDATING_ASSET_SUCCESS = "UPDATING_ASSET_SUCCESS";
export const UPDATING_ASSET_FAILURE = "UPDATING_ASSET_FAILURE";

export const READ_AN_EVENT_SUCCESS = "READ_AN_EVENT_SUCCESS";
export const READ_AN_EVENT_FAILURE = "READ_AN_EVENT_FAILURE";

export const GET_ALL_COMPANIES_SUCCESS = "GET_ALL_COMPANIES_SUCCESS";
export const GET_ALL_COMPANIES_FAILURE = "GET_ALL_COMPANIES_FAILURE";

export const CREATE_NEW_COMPANY_SUCCESS = "CREATE_NEW_COMPANY_SUCCESS";
export const CREATE_NEW_COMPANY_FAILURE = "CREATE_NEW_COMPANY_FAILURE";

export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";

export const GET_ALL_COMPANIES_DROPDOWN_SUCCESS = "GET_ALL_COMPANIES_DROPDOWN_SUCCESS";
export const GET_ALL_COMPANIES_DROPDOWN_FAILURE = "GET_ALL_COMPANIES_DROPDOWN_FAILURE";

export const CREATE_NEW_AMC_SUCCESS = "CREATE_NEW_AMC_SUCCESS";
export const CREATE_NEW_AMC_FAILURE = "CREATE_NEW_AMC_FAILURE";

export const GET_AMC_RENEWAL_LIST_SUCCESS = "GET_AMC_RENEWA_LIST_SUCCESS";
export const GET_AMC_RENEWAL_LIST_FAILURE = "GET_AMC_RENEWAL_LIST_FAILURE";

export const GET_AMC_CATEGORY_LIST_SUCCESS = "GET_AMC_CATEGORY_LIST_SUCCESS";
export const GET_AMC_CATEGORY_LIST_FAILURE = "GET_AMC_CATEGORY_LIST_FAILURE";

export const GET_ALL_AMC_FOR_ASSET_SUCCESS = "GET_ALL_AMC_FOR_ASSET_SUCCESS";
export const GET_ALL_AMC_FOR_ASSET_FAILURE = "GET_ALL_AMC_FOR_ASSET_FAILURE";

export const GET_SPICIFIC_AMC_DETAILS_SUCCESS = "GET_SPICIFIC_AMC_DETAILS_SUCCESS";
export const GET_SPICIFIC_AMC_DETAILS_FAILURE = "GET_SPICIFIC_AMC_DETAILS_FAILURE";


export const readanCreatedEvent = (id, eventId) => {
    return (dispatch) => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true
        })
        let condition
        if(eventId && eventId !== 'All'){
            condition = JSON.stringify({ facility_id: id, event_category_id:eventId })
        }else{
            condition = JSON.stringify({ facility_id: id })
        }
        axios.get(base_url +`events`, {
            params: {
                filter_condition:condition
            }}
        )
            .then((response) => {
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false
                })
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: READ_AN_EVENT_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: READ_AN_EVENT_SUCCESS,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false
                })
                dispatch({
                    type: READ_AN_EVENT_FAILURE,
                    payload: error,
                });
            });
    }
}

export const getFacmanfromUsers = (group_name) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.get(base_url + `authorization/getUserByGroup?group_name=${group_name}`)
            .then((res) => {
                if (res.status === 200) {
                    if (res?.data?.data[0] === 'error') {
                        dispatch({
                            type: GET_FACMAN_FROM_USERS_ERROR,
                            payload: res,
                        });
                    }
                    else {
                        dispatch({
                            type: SET_LOADER,
                            payload: false
                        })
                        dispatch({
                            type: GET_FACMAN_FROM_USERS,
                            payload: res.data.data
                        })
                    }
                }
            })
            .catch((err) => {
                console.log(err)
                dispatch({
                    type: GET_FACMAN_FROM_USERS_ERROR,
                    payload: err.response.data.message
                })
                dispatch({
                    type: GET_FACMAN_FROM_USERS,
                    payload: []
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

export const scheduleNewEvent = (data) => {
    return (dispatch) => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true
        })
        axios.post(base_url + "events", data)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: CREATE_SCHEDULE_EVENT_SUCCESS,
                        payload: "event is created successfully"
                    })
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                        payload: false
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: CREATE_SCHEDULE_EVENT_FAILURE,
                    payload: err.response.data.message
                })
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false
                })
            })
    }
}

export const getAmcForAsset = (data) => {
    return (dispatch) => {
        dispatch({
            type : CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload : true
        })
        axios.get(base_url + `facilities/amc?page_number=${data.page_number}&items_per_page=${data.items_per_page}&&filter_condition=%7B%22asset_id%22%3A%20%22${data.asset_id}%22%7D`)
            .then((response) => {
        

                const assets = response.data;
                // const data = assets.map((item) => item.asset_name) 
                dispatch({
                    type : CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload : false,
                })
                dispatch ({

                    type: GET_ALL_AMC_FOR_ASSET_SUCCESS,
                    payload: assets,               
            })
        })
        .catch((error) => {
            dispatch({
                type : CONTRACT_API_LOADER_TRIGGER_TRUE,
                payload : false
            })
            dispatch({
                type: GET_ALL_AMC_FOR_ASSET_FAILURE,
                payload: error,
            });
        });
    }
}

export const getSpicificAmcDetails = (value) => {
    return (dispatch) => {
        dispatch({
            type : CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload : true
        })

        axios.get(base_url + `facilities/amc&filter_condition=${encodeURIComponent(value)}`, {
            params: {
                request_type: 'dropdown',
                filter_condition: JSON.stringify({ asset_category: value })
            }
        })
            .then((response) => {
                const assets = response.data.data;
                // const data = assets.map((item) => item.asset_name) 
                dispatch({
                    type : CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload : false,
                })
                dispatch ({

                    type: GET_SPICIFIC_AMC_DETAILS_SUCCESS,
                    payload: assets,               
            })
        })
        .catch((error) => {
            dispatch({
                type : CONTRACT_API_LOADER_TRIGGER_TRUE,
                payload : false
            })
            dispatch({
                type: GET_SPICIFIC_AMC_DETAILS_FAILURE,
                payload: error,
            });
        });
    }
}

export const getAssetsByCategory = (value) => {
    return (dispatch) => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true
        })

        axios.get(base_url + `facilities/assets`, {
            params: {
                request_type: 'dropdown',
                filter_condition: JSON.stringify({ asset_category: value })
            }
        })
            .then((response) => {
                const assets = response.data.data;
                // const data = assets.map((item) => item.asset_name) 
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false,
                })
                dispatch({

                    type: GET_ASSETS_BY_CATEGORY_SUCCESS,
                    payload: assets,
                })
            })
            .catch((error) => {
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false
                })
                dispatch({
                    type: GET_ASSETS_BY_CATEGORY_FAILURE,
                    payload: error,
                });
            });
    }
}


export const getEventCategories = () => {
    return (dispatch) => {
        axios
            .get(base_url + "events/eventCategory")
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_EVENT_CATEGORIES_FAILURE,
                        payload: response,
                    });
                }
                else {
                    const data = response.data.data.map((item) => item.category_name)
                    dispatch({
                        type: GET_EVENT_CATEGORIES_SUCCESS,
                        payload: response.data.data
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_EVENT_CATEGORIES_FAILURE,
                    payload: error,
                });
            });
    }
}




export const updatingExistingAsset = (data) => {
    let currentUrl = "facilities/assets";
    return (dispatch) => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios.put(base_url + currentUrl, data)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: UPDATING_ASSET_FAILURE,
                        payload: response,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                        payload: false,
                    });
                }
                else {
                    dispatch({
                        type: UPDATING_ASSET_SUCCESS,
                        payload: response.data.data,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                        payload: false,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: UPDATING_ASSET_FAILURE,
                    payload: error,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false,
                });
            });

    };
}



export const getAssetCategories = () => {
    return (dispatch) => {
        axios
            .get(base_url + "listOfValues", {
                params: {
                    filter_condition: JSON.stringify({ lov_code: "ASSET_CATEGORY" })
                }
            })
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_ASSET_CATEGORY_FAILURE,
                        payload: response,
                    });
                }
                else {
                    const data = response.data.data.map((item) => item.lov_value)
                    dispatch({
                        type: GET_ASSET_CATEGORY_SUCCESS,
                        payload: data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_ASSET_CATEGORY_FAILURE,
                    payload: error,
                });
            });
    }
}


export const creatingNewAsset = (data) => {
    
    let currentUrl = "facilities/assets";
    return (dispatch) => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios.post(base_url + currentUrl, data)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: CREATING_NEW_ASSET_FAILURE,
                        payload: response,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                        payload: false,
                    });
                }
                else {
                    dispatch({
                        type: CREATING_NEW_ASSET_SUCCESS,
                        payload: response.data.data.asset_id,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                        payload: false,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: CREATING_NEW_ASSET_FAILURE,
                    payload: error,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false,
                });
            });

    };
}

export const createNewAmc = (data) => {
    let currentUrl = "";
    currentUrl = `facilities/amc`

    return (dispatch) => {

        axios.post(base_url + currentUrl, data)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: CREATE_NEW_AMC_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: CREATE_NEW_AMC_SUCCESS,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_NEW_AMC_FAILURE,
                    payload: error,
                });
            });

    };
};

export const getAmcRenewalPeriod = () => {
    let currentUrl = "listOfValues?filter_condition=%7B%22lov_code%22%3A%20%22AMC_RENEWAL%22%7D";
    return (dispatch) => {
        axios.get(base_url + currentUrl)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_AMC_RENEWAL_LIST_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: GET_AMC_RENEWAL_LIST_SUCCESS,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_AMC_RENEWAL_LIST_FAILURE,
                    payload: error,
                });
            });

    };
}

export const getAmcCategory = () => {
    let currentUrl = "listOfValues?filter_condition=%7B%22lov_code%22%3A%20%22AMC_CATEGORY%22%7D";
    return (dispatch) => {
        axios.get(base_url + currentUrl)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_AMC_CATEGORY_LIST_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: GET_AMC_CATEGORY_LIST_SUCCESS,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_AMC_CATEGORY_LIST_FAILURE,
                    payload: error,
                });
            });

    };
}

export const getAssetTypeList = () => {
    let currentUrl = "listOfValues?filter_condition=%7B%22lov_code%22%3A%20%22ASSET_TYPE%22%7D";
    return (dispatch) => {
        axios.get(base_url + currentUrl)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_ASSET_TYPE_LIST_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: GET_ASSET_TYPE_LIST_SUCCESS,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_ASSET_TYPE_LIST_FAILURE,
                    payload: error,
                });
            });

    };
}

export const getAssetCategoryList = () => {
    let currentUrl = "listOfValues?filter_condition=%7B%22lov_code%22%3A%20%22ASSET_CATEGORY%22%7D";
    return (dispatch) => {
        axios.get(base_url + currentUrl)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_ASSET_CATEGORY_LIST_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: GET_ASSET_CATEGORY_LIST_SUCCESS,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_ASSET_CATEGORY_LIST_FAILURE,
                    payload: error,
                });
            });

    };
}

export const getAssetStatusList = () => {
    let currentUrl = "listOfValues?filter_condition=%7B%22lov_code%22%3A%20%22ASSET_STATUS%22%7D";
    return (dispatch) => {
        axios.get(base_url + currentUrl)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_ASSET_STATUS_LIST_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: GET_ASSET_STATUS_LIST_SUCCESS,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_ASSET_STATUS_LIST_FAILURE,
                    payload: error,
                });
            });

    };
}

export const updateCompanyDetails = (data) => {
    let currentUrl = "";
    currentUrl = `facilities/companies`
   
    return (dispatch) => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios.put(base_url + currentUrl, data)
            .then((response) => {
                handlePaginationLoaderFalse()
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: UPDATE_COMPANY_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: UPDATE_COMPANY_SUCCESS,
                        payload: response.data.data,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                        payload: false,
                    });
                   
                }
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_COMPANY_FAILURE,
                    payload: error.response.data.message,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
            });

    };
};

export const createNewCompany = (data) => {
    let currentUrl = "";
    currentUrl = `facilities/companies`
   
    return (dispatch) => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios.post(base_url + currentUrl, data)
            .then((response) => {
                handlePaginationLoaderFalse()
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: CREATE_NEW_COMPANY_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: CREATE_NEW_COMPANY_SUCCESS,
                        payload: response.data.data,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                        payload: false,
                    });
                   
                }
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_NEW_COMPANY_FAILURE,
                    payload: error.response.data.message,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
            });

    };
};




export const getAllCompaniesListDropdown = (data) => {
    let currentUrl = "";
    
        currentUrl = `facilities/companies?request_type=dropdown`
    
    return (dispatch) => {
        // dispatch({
        //     type: CONTRACT_API_LOADER_TRIGGER_TRUE,
        //     payload: true,
        // });
        axios.get(base_url + currentUrl)
            .then((response) => {
                handlePaginationLoaderFalse()
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_ALL_COMPANIES_DROPDOWN_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: GET_ALL_COMPANIES_DROPDOWN_SUCCESS,
                        payload: response.data.data,
                    });
                    // dispatch({
                    //     type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    //     payload: false,
                    // });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_ALL_COMPANIES_DROPDOWN_FAILURE,
                    payload: error,
                });
                // dispatch({
                //     type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                //     payload: false,
                // });
            });

    };
};

export const getAllCompaniesList = (data) => {
    let currentUrl = "";
    
        currentUrl = `facilities/companies?page_number=${data.page_number}&items_per_page=${data.items_per_page}&search_text=${data.serach_text}`
    
    return (dispatch) => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios.get(base_url + currentUrl)
            .then((response) => {
                handlePaginationLoaderFalse()
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_ALL_COMPANIES_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: GET_ALL_COMPANIES_SUCCESS,
                        payload: response.data,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                        payload: false,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_ALL_COMPANIES_FAILURE,
                    payload: error,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
            });

    };
};

export const facilityManagerGetAllAssets = (data) => {
    let currentUrl = "";
    if (data) {
        currentUrl = `facilities/assets?page_number=${data.page_number}&items_per_page=${data.items_per_page}&search_text=${data.serach_text}&filter_condition=%7B%22facility_id%22%3A%20%22${data.facility_id}%22%7D`
    }
    else {
        currentUrl = "facilities/assets"
    }
    return (dispatch) => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios.get(base_url + currentUrl)
            .then((response) => {
                handlePaginationLoaderFalse()
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_ALL_ASSETS_LIST_FAILURE,
                        payload: response,
                    });

                }
                else {
                    dispatch({
                        type: GET_ALL_ASSETS_LIST_SUCCESS,
                        payload: response.data,
                    });
                }
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_ALL_ASSETS_LIST_FAILURE,
                    payload: error,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
            });

    };
};

export const updateMeterReadinginMeterReports = (data) => {
    return (dispatch) => {
        // dispatch({
        //     type: UPDATE_METER_READING_SUCCESS,
        //     payload: true,
        // });

        axios
            .put(base_url + "facilities/meterReadings/singleMeterReadingsEdit", data)
            .then((response) => {
                if (response.data.status === "success") {
                    dispatch({
                        type: UPDATE_METER_READING_SUCCESS,
                        payload: response.data.data,
                    });
                    dispatch({
                        type: UPDATE_METER_READING_API_LOADER_TRIGGER_FALSE,
                        payload: false,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_METER_READING_FAILURE,
                    payload: error,
                });
                dispatch({
                    type: UPDATE_METER_READING_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
            });
    }
}



export const GetMeterReadingsByMeterId = (filters) => {
    return (dispatch) => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios
            .get(base_url + "facilities/meterReadings?filter_condition=%7B%22meter_id%22%3A%22" + filters.meter_id + "%22%2C%22start_date%22%3A%22" + filters.start_date + "%22%2C%22end_date%22%3A%22" + filters.end_date + "%22%2C%22reading_type%22%3A%22" + filters.reading_type + "%22%7D")
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_ALL_METER_READINGS_BY_METER_ID_FAILURE,
                        payload: response,
                    });
                } else {
                    dispatch({
                        type: GET_ALL_METER_READINGS_BY_METER_ID_SUCCESS,
                        payload: response.data.data,
                    });
                }
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
                return response.data.data;
            })
            .catch((error) => {
                dispatch({
                    type: GET_ALL_METER_READINGS_BY_METER_ID_FAILURE,
                    payload: error,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
                throw error;
            });
    };
};


export const handlePaginationLoaderTrue = () => {
    return (dispatch) => {
        dispatch({
            type: PAGINATION_LOADER_TRIGGER_TRUE,
            payload: true,
        });

    }
}

export const handlePaginationLoaderFalse = () => {
    return (dispatch) => {
        dispatch({
            type: PAGINATION_LOADER_TRIGGER_FALSE,
            payload: false,
        });

    }
}

export const commercialHeadUploadFacilityDocument = (data) => {

    return (dispatch) => {
        axios
            .post(base_url + "documents", data)
            .then((response) => {
                dispatch({
                    type: DOCUMENT_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: COMMERCIAL_HEAD_UPLOAD_FACILITY_DOCUMENTS_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: COMMERCIAL_HEAD_UPLOAD_FACILITY_DOCUMENTS_SUCCESS,
                        payload: response.data.data,
                    });
                    dispatch(commercialHeadGetFacilityDocuments(data.files[0].facility_id))

                }
            })
            .catch((error) => {
                dispatch({
                    type: DOCUMENT_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
                dispatch({
                    type: COMMERCIAL_HEAD_UPLOAD_FACILITY_DOCUMENTS_FAILURE,
                    payload: error,
                });
            });

    }
}



export const commercialHeadGetFacilityDocuments = (id) => {
    return (dispatch) => {
        let id_type = "facility_id";
        axios
            .get(base_url + "documents/listDocumentsByID?filter_condition=%7B%22id_type%22%3A%20%22" + id_type + "%22%2C%22id_value%22%3A%22" + id + "%22%2C%22service%22%3A%22facilities%22%7D")
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: COMMERCIAL_HEAD_GET_FACILITY_DOCUMENTS_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: COMMERCIAL_HEAD_GET_FACILITY_DOCUMENTS_SUCCESS,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: COMMERCIAL_HEAD_GET_FACILITY_DOCUMENTS_FAILURE,
                    payload: error,
                });
            });
    }

}

export const commercialHeadUploadLicenseDocument = (data) => {

    return (dispatch) => {
        axios
            .post(base_url + "documents", data)
            .then((response) => {
                dispatch({
                    type: DOCUMENT_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: COMMERCIAL_HEAD_UPLOAD_FACILITY_DOCUMENTS_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: COMMERCIAL_HEAD_UPLOAD_FACILITY_DOCUMENTS_SUCCESS,
                        payload: response.data.data,
                    });
                    dispatch(commercialHeadGetLicenseDocuments(response.data.data[0].id_value))

                }
            })
            .catch((error) => {
                dispatch({
                    type: DOCUMENT_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
                dispatch({
                    type: COMMERCIAL_HEAD_UPLOAD_FACILITY_DOCUMENTS_FAILURE,
                    payload: error,
                });
            });

    }
}



export const commercialHeadGetLicenseDocuments = (id) => {
    return (dispatch) => {
        let id_type = "licenses_permits";
        dispatch({
            type: DOCUMENT_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios
            .get(base_url + "documents/listDocumentsByID?filter_condition=%7B%22id_type%22%3A%20%22" + id_type + "%22%2C%22id_value%22%3A%22" + id + "%22%2C%22service%22%3A%22facilities%22%7D")
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: COMMERCIAL_HEAD_GET_FACILITY_DOCUMENTS_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: COMMERCIAL_HEAD_GET_FACILITY_DOCUMENTS_SUCCESS,
                        payload: response.data.data,
                    });
                    dispatch({
                        type: DOCUMENT_LOADER_TRIGGER_FALSE,
                        payload: false,
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: COMMERCIAL_HEAD_GET_FACILITY_DOCUMENTS_FAILURE,
                    payload: error,
                });
                dispatch({
                    type: DOCUMENT_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
            });
    }

}

export const commercialHeadGetDocuments = (data) => {
    return (dispatch) => {
        axios
            .get(base_url + "documents/listDocumentsByID?filter_condition=%7B%22id_type%22%3A%20%22" + "contract_name" + "%22%2C%22id_value%22%3A%22" + data.contract_name + "%22%2C%22service%22%3A%22customers%22%7D")
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: COMMERCIAL_HEAD_GET_DOCUMENTS_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: COMMERCIAL_HEAD_GET_DOCUMENTS_SUCCESS,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: COMMERCIAL_HEAD_GET_DOCUMENTS_FAILURE,
                    payload: error,
                });
            });
    }

}

export const handleDocumentLoaderTrue = () => {

    return (dispatch) => {
        dispatch({
            type: DOCUMENT_LOADER_TRIGGER_TRUE,
            payload: true,
        });

    }
}



export const handleDocumentLoaderFalse = () => {
    return (dispatch) => {
        dispatch({
            type: DOCUMENT_LOADER_TRIGGER_FALSE,
            payload: false,
        });

    }
}

export const commercialHeadUploadDocument = (data) => {

    return (dispatch) => {
        axios
            .post(base_url + "documents", data)
            .then((response) => {
                dispatch({
                    type: DOCUMENT_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: COMMERCIAL_HEAD_UPLOAD_DOCUMENT_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch(commercialHeadGetDocuments({ contract_name: data.files[0].contract_name }))
                    dispatch({
                        type: COMMERCIAL_HEAD_UPLOAD_DOCUMENT_SUCCESS,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: DOCUMENT_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
                dispatch({
                    type: COMMERCIAL_HEAD_UPLOAD_DOCUMENT_FAILURE,
                    payload: error,
                });
            });

    }
}


export const commercialEditCompleteContract = (data) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_COMPLETE_CONTRACT_SUCCESS,
            payload: null,
        })
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios
            .put(base_url + "customers/contracts", data)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: UPDATE_COMPLETE_CONTRACT_FAILURE,
                        payload: response,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                        payload: false,
                    });
                }
                else {
                    dispatch({
                        type: UPDATE_COMPLETE_CONTRACT_SUCCESS,
                        payload: response.data.status,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                        payload: false,
                    });
                }
                //   window.location.reload() 
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_COMPLETE_CONTRACT_FAILURE,
                    payload: error,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
            });
    }
}

export const commercialHeadGetUtilityRates = (id) => {
    return (dispatch) => {
        axios
            .get(base_url + "facilities/utilityRates?filter_condition=%7B%22facility_id%22%3A%20%22" + id + "%22%2C%20%22send_all%22%3A%20true%7D")
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_CH_UTILITY_RATES_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: GET_CH_UTILITY_RATES_SUCCESS,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_CH_UTILITY_RATES_FAILURE,
                    payload: error,
                });
            });
    }
}


export const getGstSubmissionLogs = (data) => {
    return (dispatch) => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        const start_date = data.start_date
        const end_date = data.end_date
        axios.get(base_url + "gstPortal/externalRequestLogs", {
            params: {
                start_date: start_date,
                end_date: end_date
            }
        })
            .then((response) => {
                dispatch({
                    type: GET_GST_LOGS_SUCCESS,
                    payload: response.data.data,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false,
                });
            })
            .catch((error) => {
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false,
                });
                dispatch({
                    type: GET_GST_LOGS_FAILURE,
                    payload: error,
                });
            })
    }
}

export const getEmailLogs = (data) => {
    return (dispatch) => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        const start_date = data.start_date
        const end_date = data.end_date
        // https://q73ion9zv2.execute-api.us-east-2.amazonaws.com/dev/api/email/emailsLog
        axios.get(base_url + "email/emailsLog", {
            params: {
                start_date: start_date,
                end_date: end_date
            }
        })
            .then((response) => {
                dispatch({
                    type: GET_EMAIL_LOGS_SUCCESS,
                    payload: response.data.data,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false,
                });
            })
            .catch((error) => {
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false,
                });
                dispatch({
                    type: GET_EMAIL_LOGS_FAILURE,
                    payload: error,
                });
            })
    }
}

export const commercialHeadGetAllFacilities = (data) => {

    return (dispatch) => {
        let currentUrl = "";
        if (data) {
            const { page, limit, search, state, city } = data;
            let filterCondition = ''
            if (state === '' && city === '') {
                filterCondition = ''
            }
            else if (state !== '' && city === '') {
                filterCondition = `&filter_condition=%7B%22state%22%3A%20%22${state}%22%7D`
            }
            else if (state === '' && city !== '') {
                filterCondition = `&filter_condition=%7B%22city%22%3A%20%22${city}%22%7D`
            }
            else {
                filterCondition = `&filter_condition=%7B%22state%22%3A%20%22${state}%22%2C%22city%22%3A%20%22${city}%22%7D`
            }
            currentUrl = `facilities?page_number=${page}&items_per_page=${limit}&search_text=${search}${filterCondition}`
        }
        else {
            currentUrl = "facilities"
        }
        axios
            .get(base_url + currentUrl)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: FETCH_FACILITIES_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: FETCH_FACILITIES_SUCCESS,
                        payload: response.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_FACILITIES_FAILURE,
                    payload: error,
                });
            });
    }
}

export const commercialHeadGetAllFacilitiesForNav = (data) => {
    return (dispatch) => {
        axios
            .get(base_url + "facilities?request_type=dropdown")
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: FETCH_FACILITIES_FAILURE_FOR_NAV,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: FETCH_FACILITIES_SUCCESS_FOR_NAV,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_FACILITIES_FAILURE_FOR_NAV,
                    payload: error,
                });
            });
    }
}

export const commercialHeadGetDepartments = () => {

    return (dispatch) => {
        axios
            .get(base_url + "customers/departments")
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_CH_ALL_DEPARTMENTS_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: GET_CH_ALL_DEPARTMENTS_SUCCESS,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_CH_ALL_DEPARTMENTS_FAILURE,
                    payload: error,
                });
            });
    }
}

export const commercialHeadGetAllMeters = (data) => {
    const temp = jsonToQueryString(data)
    const currentUrl = "facilities/meters?" + temp
    return (dispatch) => {
        axios
            .get(base_url + currentUrl)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_CH_ALL_METERS_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: GET_CH_ALL_METERS_SUCCCESS,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_CH_ALL_METERS_FAILURE,
                    payload: error,
                });
            });
    }
}


export const commercialHeadUpdateDraftContract = (data) => {

    return (dispatch) => {
        dispatch({
            type: UPDATE_CH_DRAFT_CONTRACT_SUCCESS,
            payload: null,
        })
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios
            .put(base_url + "customers/draftContracts", data)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: UPDATE_CH_DRAFT_CONTRACT_FAILURE,
                        payload: response,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                        payload: false,
                    });
                }
                else {
                    dispatch({
                        type: UPDATE_CH_DRAFT_CONTRACT_SUCCESS,
                        payload: response.data.status,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                        payload: false,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_CH_DRAFT_CONTRACT_FAILURE,
                    payload: error,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
            });

    }
}

export const commercialHeadUpdateFMSContract = (data) => {

    return (dispatch) => {
        dispatch({
            type: UPDATE_CH_FMSCONTRACT_SUCCESS,
            payload: null,
        })
        dispatch({
            type: PUT_CH_ADD_NEW_FMSCONTACT_SUCCESS,
            payload: null,
        });
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios
            .put(base_url + "customers/fmsContracts", data)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: UPDATE_CH_FMSCONTRACT_FAILURE,
                        payload: response,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                        payload: false,
                    });
                }
                else {
                    dispatch({
                        type: UPDATE_CH_FMSCONTRACT_SUCCESS,
                        payload: response.data.status,
                    });
                    dispatch({
                        type: PUT_CH_ADD_NEW_FMSCONTACT_SUCCESS,
                        payload: response.data.data.contacts,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                        payload: false,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_CH_FMSCONTRACT_FAILURE,
                    payload: error,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
            });

    }
}

export const commercialHeadAddNewContractDelete = () => {
    return (dispatch) => {
        dispatch({
            type: PUT_CH_ADD_NEW_CONTRACT_DELETE_SUCCESS,
            payload: null,
        });


    }
}

export const commercialHeadAddNewContract = (data) => {
    return (dispatch) => {
        dispatch({
            type: PUT_CH_ADD_NEW_CONTRACT_SUCCESS,
            payload: null,
        });
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios
            .post(base_url + "customers/draftContracts", data)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: PUT_CH_ADD_NEW_CONTRACT_FAILURE,
                        payload: response,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                        payload: false,
                    })

                }
                else {
                    dispatch({
                        type: PUT_CH_ADD_NEW_CONTRACT_SUCCESS,
                        payload: response.data.data.draft_id,
                    });
                    setTimeout(() => {
                        dispatch({
                            type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                            payload: false,
                        });
                    }, 3000);
                }
            })
            .catch((error) => {
                dispatch({
                    type: PUT_CH_ADD_NEW_CONTRACT_FAILURE,
                    payload: error,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
            });

    }
}

export const commercialHeadAddNewFMSContract = (data) => {
    return (dispatch) => {
        dispatch({
            type: PUT_CH_ADD_NEW_FMSCONTRACT_SUCCESS,
            payload: null,
        });

        dispatch({
            type: PUT_CH_ADD_NEW_FMSCONTACT_SUCCESS,
            payload: null,
        });
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios
            .post(base_url + "customers/fmsContracts", data)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: PUT_CH_ADD_NEW_FMSCONTRACT_FAILURE,
                        payload: response,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                        payload: false,
                    });
                }
                else {
                    dispatch({
                        type: PUT_CH_ADD_NEW_FMSCONTRACT_SUCCESS,
                        payload: response.data.data.contract_id,
                    });
                    dispatch({
                        type: PUT_CH_ADD_NEW_FMSCONTACT_SUCCESS,
                        payload: response.data.data.contacts,
                    });
                    setTimeout(() => {
                        dispatch({
                            type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                            payload: false,
                        });
                    }, 3000)
                }

            })
            .catch((error) => {
                dispatch({
                    type: PUT_CH_ADD_NEW_FMSCONTRACT_FAILURE,
                    payload: error,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
            });

    }
}

export const commercialHeadUpdateFacility = (data) => {

    return (dispatch) => {
        axios
            .put(base_url + "facilities", data)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: PUT_CH_UPDATE_FACILITY_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: PUT_CH_UPDATE_FACILITY_SUCCESS,
                        payload: response.data.status,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: PUT_CH_UPDATE_FACILITY_FAILURE,
                    payload: error,
                });
            });

    }
}

export const commercialHeadSubmitContract = (data) => {

    return (dispatch) => {
        dispatch({
            type: PUT_CH_SUBMIT_CONTRACT_SUCCESS,
            payload: null,
        })
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios
            .post(base_url + "customers/contracts", data)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: PUT_CH_SUBMIT_CONTRACT_FAILURE,
                        payload: response,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                        payload: false,
                    });
                }
                else {
                    dispatch({
                        type: PUT_CH_SUBMIT_CONTRACT_SUCCESS,
                        payload: response.data,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                        payload: false,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: PUT_CH_SUBMIT_CONTRACT_FAILURE,
                    payload: error,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
            });

    }
}

export const technicianSubmitMeterReadings = (data) => {

    return (dispatch) => {
        dispatch({
            type: PUT_TC_SUMBIT_METER_READING_SUCCESS,
            payload: null,
        })
        axios
            .post(base_url + "facilities/meterReadings", data)
            .then((response) => {
                dispatch({
                    type: PUT_TC_SUMBIT_METER_READING_SUCCESS,
                    payload: response.data.status,
                });
            })
            .catch((error) => {
                dispatch({
                    type: PUT_TC_SUMBIT_METER_READING_FAILURE,
                    payload: error,
                });
            });

    }
}

export const technicianUpdateMeterReadings = (data) => {

    return (dispatch) => {
        dispatch({
            type: PUT_TC_SUMBIT_METER_READING_SUCCESS,
            payload: null,
        });
        axios
            .put(base_url + "facilities/meterReadings", data)
            .then((response) => {
                dispatch({
                    type: PUT_TC_SUMBIT_METER_READING_SUCCESS,
                    payload: response.data.status,
                });
            })
            .catch((error) => {
                dispatch({
                    type: PUT_TC_SUMBIT_METER_READING_FAILURE,
                    payload: error,
                });
            });

    }
}

export const LicensesAndPermitsAPI = (data) => {
    return (dispatch) => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        })
        axios
            .get(base_url + "facilities/licensesPermits", data)
            .then((response) => {
                dispatch({
                    type: PUT_CH_LICENSES_AND_PERMITS_SUCCESS,
                    payload: response.data,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                })
                dispatch({
                    type: PAGINATION_LOADER_TRIGGER_TRUE,
                    payload: false,
                });

            })
            .catch((error) => {
                dispatch({
                    type: PUT_CH_LICENSES_AND_PERMITS_FAILURE,
                    payload: error,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                })
                dispatch({
                    type: PAGINATION_LOADER_TRIGGER_TRUE,
                    payload: false,
                });

            });

    }
}

export const addNewLicensePermit = (data) => {
    return (dispatch) => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        })
        axios
            .post(base_url + "facilities/licensesPermits", data)
            .then((response) => {
                dispatch({
                    type: PUT_CH_ADD_NEW_LICENSE_PERMIT_SUCCESS,
                    payload: response.data.data,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                })
            })
            .catch((error) => {
                dispatch({
                    type: PUT_CH_ADD_NEW_LICENSE_PERMIT_FAILURE,
                    payload: error,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                })
            });
    }
}

export const commercialHeadAddNewFacility = (data) => {

    return (dispatch) => {
        axios
            .post(base_url + "facilities", data)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: PUT_CH_ADD_NEW_FACILITY_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: PUT_CH_ADD_NEW_FACILITY_SUCCESS,
                        payload: response.data.status,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: PUT_CH_ADD_NEW_FACILITY_FAILURE,
                    payload: error,
                });
            });

    }
}


export const facilityManagaerAddNewMeter = (data) => {

    return (dispatch) => {
        axios
            .post(base_url + "facilities/meters", data)
            .then((response) => {
                dispatch({
                    type: PUT_CH_ADD_NEW_METER_SUCCESS,
                    payload: response.data.status,
                });
            })
            .catch((error) => {
                dispatch({
                    type: PUT_CH_ADD_NEW_METER_FAILURE,
                    payload: error,
                });
            });

    }
}

export const facilityManagaerUpdateMeter = (data) => {

    return (dispatch) => {

        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios
            .put(base_url + "facilities/meters", data)
            .then((response) => {
                dispatch({
                    type: PUT_CH_UPDATE_METER_SUCCESS,
                    payload: response.data.status,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
            })
            .catch((error) => {
                dispatch({
                    type: PUT_CH_UPDATE_METER_FAILURE,
                    payload: error,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false,
                });
            });

    }
}

export const clearCommercialHeadDocumentContent = () => {
    return (dispatch) => {
        dispatch({
            type: COMMERCIAL_HEAD_GET_DOCUMENTS_SUCCESS,
            payload: [],
        });
    }
}

export const technicianSubmitMeterImage = (data) => {

    return (dispatch) => {
        axios
            .post(base_url + "facilities/meterReadings", data)
            .then((response) => {
                dispatch({
                    type: PUT_TC_SUMBIT_METER_IMAGE_SUCCESS,
                    payload: response.data.status,
                });
            })
            .catch((error) => {
                dispatch({
                    type: PUT_TC_SUMBIT_METER_IMAGE_FAILURE,
                    payload: error,
                });
            });

    }
}

export const commercialHeadGetContracts = (data) => {
    let currentUrl = "";
    if (data) {
        currentUrl = `customers/contracts?page_number=${data.page_number}&items_per_page=${data.items_per_page}&search_text=${data.serach_text}&filter_condition=%7B%22facility_id%22%3A%20%22${data.facility_id}%22%7D`
    }
    else {
        currentUrl = "customers/contracts"
    }
    return (dispatch) => {
        axios.get(base_url + currentUrl)
            .then((response) => {
                handlePaginationLoaderFalse()
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_CH_CONTRACTS_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: GET_CH_CONTRACTS_SUCCESS,
                        payload: response.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_CH_CONTRACTS_FAILURE,
                    payload: error,
                });
            });

    };
};

export const commercialHeadGetFMSContracts = (data) => {

    let currentUrl = `customers/fmsContracts?page_number=${data.page_number}&items_per_page=${data.items_per_page}&search_text=${data.serach_text}&filter_condition=%7B%22facility_id%22%3A%20%22${data.facility_id}%22%2C%20%22contract_status%22%3A%22${data.status}%22%7D`

    return (dispatch) => {
        axios.get(base_url + currentUrl)
            .then((response) => {
                handlePaginationLoaderFalse()
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_CH_FMSCONTRACTS_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: GET_CH_FMSCONTRACTS_SUCCESS,
                        payload: response.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_CH_FMSCONTRACTS_FAILURE,
                    payload: error,
                });
            });

    };
};

export const commercialHeadGetContractsForDropdown = () => {
    // const facility_id = data.facility_id;
    // const show_xRefTableColumns = true
    return (dispatch) => {
        axios.get(base_url + `customers/contracts?request_type=dropdown`)
            .then((response) => {
                axios.get(base_url + `customers/draftContracts?request_type=dropdown`)
                    .then((response2) => {
                        handlePaginationLoaderFalse()
                        const responseArray = response.data.data.concat(response2.data.data)
                        dispatch({
                            type: GET_CH_CONTRACTS_SUCCESS_FOR_DROPDOWN,
                            payload: responseArray,
                        });
                    })
                    .catch((error) => {
                        dispatch({
                            type: GET_CH_DRAFT_CONTRACTS_FAILURE,
                            payload: error,
                        });
                    });

            })
            .catch((error) => {
                dispatch({
                    type: GET_CH_CONTRACTS_FAILURE_FOR_DROPDOWN,
                    payload: error,
                });
            });

    };
};

export const commercialHeadGetCompleteContractsForDropdown = (facility_id) => {
    return (dispatch) => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios.get(base_url + `customers/contracts`,{
            params: {
                request_type: 'dropdown',
                all_invoice_types : true,
                required_columns : 'contract_name,contract_id,contract_type,contract_number',
                filter_condition : JSON.stringify({
                    facility_id: facility_id,
                    contract_status: 'active'
                }),
            }
        })  
            .then((response) => {
                const responseArray = response.data.data
                dispatch({
                    type: GET_CH_CONTRACTS_SUCCESS_FOR_DROPDOWN,
                    payload: responseArray,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_CH_CONTRACTS_FAILURE_FOR_DROPDOWN,
                    payload: error,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false,
                });
            });
    };
};



export const commercialHeadGetContractsForDropdownTechnician = (data) => {
    const facility_id = data.facility_id;
    const show_xRefTableColumns = true

    return (dispatch) => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        const filterCondition = JSON.stringify({
            facility_id: facility_id,
            show_xRefTableColumns: show_xRefTableColumns
        });
        axios.get(`${base_url}customers/contracts?request_type=dropdown&filter_condition=${encodeURIComponent(filterCondition)}`)
            .then((response) => {
                dispatch({
                    type: GET_CH_CONTRACTS_SUCCESS_FOR_DROPDOWN_TECHNICIAN,
                    payload: response.data.data,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_CH_CONTRACTS_FAILURE_FOR_DROPDOWN_TECHNICIAN,
                    payload: error,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false,
                });
            });

    };
};
export const commercialHeadGetFmsContractsForDropdown = () => {
    return (dispatch) => {
        axios.get(base_url + `customers/fmsContracts?request_type=dropdown`)
            .then((response) => {

                handlePaginationLoaderFalse()
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_CH_FMSCONTRACTS_FAILURE_FOR_DROPDOWN,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: GET_CH_FMSCONTRACTS_SUCCESS_FOR_DROPDOWN,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_CH_FMSCONTRACTS_FAILURE_FOR_DROPDOWN,
                    payload: error,
                });
            });

    };
};

export const commercialHeadGetDraftContracts = (data) => {
    let currentUrl = "";
    if (data) {
        currentUrl = `customers/draftContracts?page_number=${data.page_number}&items_per_page=${data.items_per_page}&search_text=${data.serach_text}&filter_condition=%7B%22facility_id%22%3A%20%22${data.facility_id}%22%7D`
    }
    else {
        currentUrl = "customers/draftContracts"
    }
    return (dispatch) => {
        axios.get(base_url + currentUrl)
            .then((response) => {
                handlePaginationLoaderFalse()
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_CH_DRAFT_CONTRACTS_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: GET_CH_DRAFT_CONTRACTS_SUCCESS,
                        payload: response.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_CH_DRAFT_CONTRACTS_FAILURE,
                    payload: error,
                });
            });
    };
};

export const commercialHeadGetFMSDraftContracts = (data) => {
    let currentUrl = "";
    if (data) {
        currentUrl = `customers/fmsContracts?contract_status=draft&page_number=${data.page_number}&items_per_page=${data.items_per_page}&search_text=${data.serach_text}&filter_condition=%7B%22facility_id%22%3A%20%22${data.facility_id}%22%7D`
    }
    else {
        currentUrl = "customers/draftContracts"
    }
    return (dispatch) => {
        axios.get(base_url + currentUrl)
            .then((response) => {
                handlePaginationLoaderFalse()
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_CH_DRAFT_FMSCONTRACTS_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: GET_CH_DRAFT_FMSCONTRACTS_SUCCESS,
                        payload: response.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_CH_DRAFT_FMSCONTRACTS_FAILURE,
                    payload: error,
                });
            });
    };
};
//Create a new Field (Form Builder)

export const createField = (data) => {
    return (dispatch) => {

        dispatch({
            type: SET_LOADER,
            payload: true
        })

        let obj = {
            field_id: data.id,
            field_name: data.fieldName,
            field_type: data.fieldType,
            field_label: data.fieldName,
            field_value: data.fieldName,
            description: data.description,
            json_data: JSON.stringify(data.items)
        }
        axios.post(base_url + 'formBuilder/field', obj)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: CREATION_SUCCESS,
                        payload: "Field Created Successfully"
                    })
                }
            })
            .then(() => {
                dispatch({
                    type: SHOW_MODAL,
                    payload: true
                })
            })
            .then(() => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
            .catch((err) => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: CREATION_ERROR,
                    payload: err?.response?.data?.message
                })

            })
    }
}

export const getAllFields = () => {
    return async (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.get(base_url + "formBuilder/field")
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: GET_ALL_FIELDS,
                        payload: [...(res.data.data), {
                            type: "field",
                            field_type: "Table",
                            field_label: "Table",
                            field_name: "Table",
                            json_data: JSON.stringify({ type: "table", label: "Table", id: uuidv4(), content: "Table", rows: 3, columns: 3, columnNames: ["column1", "column2", "column3"], columnsType: ["text", "text", "text"], tableData: [[{ type: "text", content: "text" }, { type: "text", content: "text" }, { type: "text", content: "text" }], [{ type: "text", content: "text" }, { type: "text", content: "text" }, { type: "text", content: "text" }], [{ type: "text", content: "text" }, { type: "text", content: "text" }, { type: "text", content: "text" }]] }),
                            description: "Table Field"
                        }]

                    })
                }
            }).then(() => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
            .catch((err) => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: FETCHING_ERROR,
                    payload: err?.response?.data?.message
                })
            })
    }
}

export const deleteField = (id) => {
    return (dispatch) => {
        let obj = {
            "field_id": id
        }
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.delete(base_url + "formBuilder/field", { data: obj })
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: DELETE_FIELD,
                        payload: "Field Deleted Successfully"
                    })
                }
            })
            .then(() => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: SHOW_MODAL,
                    payload: false
                })
                dispatch({
                    type: FILTER_FIELDS,
                    payload: id
                })
            })
            .catch((err) => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: SHOW_MODAL,
                    payload: false
                })
                dispatch({
                    type: DELETE_FIELD_ERROR,
                    payload: err.response.data.message
                })
            })
    }
}

// createing a panel

export const createNewPanel = (data) => {
    return (dispatch) => {
        let obj = {
            panel_id: data.id,
            panel_name: data.panelName,
            panel_type: data.panelName,
            description: data.description,
            json_data: JSON.stringify(data.items)
        }
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.post(base_url + "formBuilder/panel", obj)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: CREATION_SUCCESS,
                        payload: "Panel Created Successfully"
                    })
                }
            })
            .then(() => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
            .catch((err) => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: CREATION_ERROR,
                    payload: err.response.data.message
                })
            })
    }
}

// Get all panels list

export const getAllPanels = () => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.get(base_url + "formBuilder/panel")
            .then((res) => {
                dispatch({
                    type: GET_ALL_PANELS,
                    payload: res.data.data
                })
            }).then(() => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
            .catch((err) => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: FETCHING_ERROR,
                    payload: err?.response?.data?.message
                })
            })
    }
}

// delete panel

export const deletePanel = (id) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.delete(base_url + "formBuilder/panel", { data: { panel_id: id } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: DELETE_PANEL,
                        payload: "Panel Deleted Successfully"
                    })
                    dispatch({
                        type: SHOW_MODAL,
                        payload: false
                    })
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
            })
            .then(() => {
                dispatch({
                    type: FILTER_PANELS,
                    payload: id
                })
            })
            .catch((err) => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: SHOW_MODAL,
                    payload: false
                })
                dispatch({
                    type: DELETE_PANEL_ERROR,
                    payload: err.response.data.message
                })
            })

    }
}

// Create a New Template

export const createNewTemplate = (data) => {
    return (dispatch) => {
        let obj = {
            template_id: data.id,
            template_name: data.templateName,
            template_type: data.templateName,
            description: data.description,
            json_data: JSON.stringify(data.items)
        }
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.post(base_url + "formBuilder/template", obj)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: CREATION_SUCCESS,
                        payload: "Template Created Successfully"
                    })
                }
            })
            .then(() => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
            .catch((err) => {
                dispatch({
                    type: CREATION_ERROR,
                    payload: err?.response?.data?.message
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

// fetch all templates
export const getAllTemplates = () => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.get(base_url + "formBuilder/template")
            .then((res) => {
                dispatch({
                    type: GET_ALL_TEMPLATES,
                    payload: res.data.data
                })
            }).then(() => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
            .catch((err) => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: FETCHING_ERROR,
                    payload: err.response.data.message
                })
            })
    }
}

export const commercialHeadGetBillingEntities = () => {
    return (dispatch) => {
        axios
            .get(base_url + "customers/partnerCompanies")
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: GET_CH_BILLING_ENTITIES_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: GET_CH_BILLING_ENTITIES_SUCCESS,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_CH_BILLING_ENTITIES_FAILURE,
                    payload: error,
                });
            });
    }
}



export const addUser = (user_details) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.post(base_url + "authorization/addUser", user_details)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: ADD_USER_SUCCESS,
                        payload: res.data.data.message
                    })
                }
            }).then(() => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            }).catch((err) => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: ADD_USER_FAILURE,
                    payload: err.response.data.message
                })
            })
    }
}

const invoiceQueryStringParameters = (params) => {
    const queryParams = new URLSearchParams();
    queryParams.append('page_number', params.page);
    queryParams.append('items_per_page', params.limit);
    const filterCondition = {
        status: params.invoiceType,
        invoice_year: params.fullYear,
        invoice_month: params.monthNumber,
        facility_id: params.facility_id
    };
    queryParams.append('filter_condition', JSON.stringify(filterCondition));
    if (params.searchText) {
        queryParams.append('search_text', params.searchText);
    }

    if (params.invoiceSelected) {
        queryParams.append('invoice_type', params.invoiceSelected);
    }
    return queryParams.toString();
}

export const getAllInvoices = (data) => {
    return (dispatch) => {
        const queryParams = invoiceQueryStringParameters(data);
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.get(base_url + `invoices/invoicesByStatus?${queryParams}`)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                    if (res?.data?.data[0] === 'error') {
                        dispatch({
                            type: GET_ALL_INVOICES_ERROR,
                            payload: res,
                        });
                    }
                    else {
                        dispatch({
                            type: GET_ALL_INVOICES,
                            payload: res.data
                        })
                        dispatch({
                            type: TOTAL_NUMBER_OF_RECORDS,
                            payload: res.data?.info?.total_no_of_records
                            // payload : res.data.data.page_number
                        })
                    }
                }
            })
            .catch((err) => {
                dispatch({
                    type: GET_ALL_INVOICES_ERROR,
                    payload: err.response.data.message
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

export const createNewMiscInvoice = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.post(base_url + "invoices/addMiscInvoice", data)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: PUT_ADD_MISC_INVOICE_SUCCESS,
                        payload: res?.data.data
                    })
                }
            }).then(() => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            }).catch((err) => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: PUT_ADD_MISC_INVOICE_FAILURE,
                    payload: err.response.data.message
                })
            })
    }
}

export const editAMiscInvoice = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.put(base_url + "invoices/addMiscInvoice", data)
            .then((res) => {
                if(res.status === 200){
                    dispatch({
                        type: PUT_ADD_MISC_INVOICE_SUCCESS,
                        payload: res?.data.data
                    })
                }
            }).then(() => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            }).catch((err) => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: PUT_ADD_MISC_INVOICE_FAILURE,
                    payload: err.response.data.message
                })
            })
    }
}

export const putInvoiceData = (data) => {
    return (dispatch) => {
        // dispatch({
        //     type: SET_LOADER,
        //     payload: true
        // })
        axios.put(base_url + "invoices/invoicesByStatus", data)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: PUT_INVOICE_DATA_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: PUT_INVOICE_DATA_SUCCESS,
                        payload: response.data.status,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: PUT_INVOICE_DATA_FAILURE,
                    payload: error,
                });
            });
    }
}

export const regenerateAnInvoice = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.post(base_url + "invoices/regenerateParticularInvoice", data)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: REGENERATE_INVOICE_DATA_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: REGENERATE_INVOICE_DATA_SUCCESS,
                        payload: response.data.status,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: REGENERATE_INVOICE_DATA_FAILURE,
                    payload: error,
                });
            });
    }
}



export const authenticateUser = (user_details) => {
    const getGroupName = (group) => {
        switch (group) {
            case COMMERCIAL_HEAD_GROUP:
                return "Commercial Head";
            case TECHNICIAN_GROUP:
                return "Technician";
            case FINANCE_MANAGER_GROUP:
                return "Finance Manager";
            case FACILITY_MANAGER_GROUP:
                return "Facility Manager";
            case CEO_GROUP:
                return "CEO";
            default:
                return "";
        }
    }
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.post(base_url + "authorization/validateUser", user_details)
            .then((res) => {
                if (res.status === 200) {
                    localStorage.setItem("isLoggedIn", true)
                    localStorage.setItem("group", getGroupName(res.data.data.group))
                    localStorage.setItem("Access_Token", res.data.data.tokens.AccessToken)
                    localStorage.setItem("Refresh_Token", res.data.data.tokens.RefreshToken)
                    localStorage.setItem("name", res.data.data.name)
                    localStorage.setItem("email", res.data.data.email)
                    localStorage.setItem("username", res.data.data.username)
                    localStorage.setItem("phone_number", res.data.data.phone_number)
                    dispatch({
                        type: AUTHENTICATE_USER_SUCCESS,
                        payload: res.data.data.message
                    })
                }
            }).then(() => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
            .catch((err) => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: AUTHENTICATE_USER_FAILURE,
                    payload: err.response.data.message
                })
            })
    }
}
function jsonToQueryString(json) {
    const params = new URLSearchParams();

    for (const [key, value] of Object.entries(json)) {
        if (typeof value === 'object' && value !== null) {
            params.append(key, JSON.stringify(value));
        } else {
            params.append(key, value);
        }
    }

    return params.toString();
}
export const facmanGetUnassignedMeters = (data) => {
    const temp = jsonToQueryString(data)
    let currentUrl = "facilities/meters?" + temp
    return (dispatch) => {
        axios
            .get(base_url + currentUrl)
            .then((response) => {
                dispatch({
                    type: FAC_MAN_GET_ALL_UNASSIGNED_METERS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FAC_MAN_GET_ALL_UNASSIGNED_METERS_FAILURE,
                    payload: error,
                });
            });
    }
}



export const facmanGetAssignedMeters = (data) => {

    return (dispatch) => {

        const temp = jsonToQueryString(data)
        let currentUrl = "customers/contracts/contractMeterRef?" + temp
        axios
            .get(base_url + currentUrl)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: FAC_MAN_GET_ASSIGNED_METERS_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: FAC_MAN_GET_ASSIGNED_METERS_SUCCESS,
                        payload: response.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: FAC_MAN_GET_ASSIGNED_METERS_FAILURE,
                    payload: error,
                });
            });
    }
}

export const getAllUsers = (group_name) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.get(base_url + `authorization/getUserByGroup?group_name=${group_name}`)
            .then((res) => {
                if (res.status === 200) {
                    if (res?.data?.data[0] === 'error') {
                        dispatch({
                            type: GET_ALL_USERS_ERROR,
                            payload: res,
                        });
                    }
                    else {
                        dispatch({
                            type: SET_LOADER,
                            payload: false
                        })
                        dispatch({
                            type: GET_ALL_USERS,
                            payload: res.data.data
                        })
                    }
                }
            })
            .catch((err) => {
                
                dispatch({
                    type: GET_ALL_USERS_ERROR,
                    payload: err.response.data.message
                })
                dispatch({
                    type: GET_ALL_USERS,
                    payload: []
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

export const getFacilityManagerList = (group_name) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.get(base_url + `authorization/getUserByGroup?group_name=${group_name}`)
            .then((res) => {
                if (res.status === 200) {
                    if (res?.data?.data[0] === 'error') {
                        dispatch({
                            type: GET_ALL_SUPERVISOR_LIST_ERROR,
                            payload: res,
                        });
                    }
                    else {
                        dispatch({
                            type: SET_LOADER,
                            payload: false
                        })
                        dispatch({
                            type: GET_ALL_SUPERVISOR_LIST,
                            payload: res.data.data
                        })
                    }
                }
            })
            .catch((err) => {
                console.log(err)
                dispatch({
                    type: GET_ALL_SUPERVISOR_LIST_ERROR,
                    payload: err.response.data.message
                })
                dispatch({
                    type: GET_ALL_SUPERVISOR_LIST,
                    payload: []
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

export const updateUserStatus = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.post(base_url + "authorization/status", data)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: UPDATE_USER_STATUS_SUCCESS,
                        payload: res.data.data.message
                    })
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
            })
            .catch((err) => {
                
                dispatch({
                    type: UPDATE_USER_STATUS_FAILURE,
                    payload: err.response.data.message
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

export const getHSNCode = (service_name, month, year) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.get(base_url + `invoices/hsnCode?month=${month}&year=${year}&service_name=${service_name}`)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: GET_HSN_SUCCESS,
                        payload: res.data.data.hsn_code
                    })
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: GET_HSN_FAILURE,
                    payload: err.response.data.message
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

export const updateHSNCode = (service_data) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        const data = {
            service_name: service_data.selectedService,
            hsn_code: service_data.hsnCode,
            month: service_data.month,
            year: service_data.year
        }
        axios.post(base_url + `invoices/hsnCode`, data)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: UPDATE_HSN_SUCCESS,
                        payload: "HSN Code updated successfully"
                    })
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: UPDATE_HSN_FAILURE,
                    payload: err.response.data.message
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

export const getTaxRates = (service_name, month, year) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.get(base_url + `invoices/taxRates?service_name=${service_name}&effective_from_month=${month}&effective_from_year=${year}`)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: GET_TAX_RATES_SUCCESS,
                        payload: res.data.data
                    })
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: GET_TAX_RATES_FAILURE,
                    payload: err.response.data.message
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

export const updateTaxRates = (tax_data) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.post(base_url + `invoices/taxRates`, tax_data)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: UPDATE_TAX_RATES_SUCCESS,
                        payload: "Tax Rates updated successfully"
                    })
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: UPDATE_TAX_RATES_FAILURE,
                    payload: err.response.data.message
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

export const setUserPassword = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.post(base_url + "authorization/setPassword", data)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                    dispatch({
                        type: SET_PASSWORD_SUCCESS,
                        payload: res.data.data.message
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: SET_PASSWORD_FAILURE,
                    payload: err.response.data.message
                })
            })
    }
}

export const getAllPartnerCompanies = () => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.get(base_url + `customers/partnerCompanies`)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: GET_ALL_PARTNER_COMPANIES_SUCCESS,
                        payload: res.data.data
                    })
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: GET_ALL_PARTNER_COMPANIES_FAILURE,
                    payload: err.response.data.message
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

export const getPartnerCompanyDetails = (id) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.get(base_url + `customers/partnerCompanies?filter_condition=%7B%22partner_company_id%22%3A%20%22${id}%22%7D`)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: GET_PARTNER_COMPANY_DETAILS_SUCCESS,
                        payload: res.data.data[0]
                    })
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: GET_PARTNER_COMPANY_DETAILS_FAILURE,
                    payload: err.response.data.message
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

export const updatePartnerCompanyDetails = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        let postal_code = data?.registered_address?.postal_code
        let obj = {
            ...data, registered_address: {
                ...data.registered_address,
                postal_code: postal_code.toString()
            }
        }
        axios.put(base_url + `customers/partnerCompanies`, obj)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: UPDATE_PARTNER_COMPANY_DETAILS_SUCCESS,
                        payload: "has been updated successfully"
                    })
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: UPDATE_PARTNER_COMPANY_DETAILS_FAILURE,
                    payload: err.response.data.message
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

export const createPartnerCompany = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.post(base_url + `customers/partnerCompanies`, data)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: CREATE_PARTNER_COMPANY_SUCCESS,
                        payload: "has been created successfully"
                    })
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: CREATE_PARTNER_COMPANY_FAILURE,
                    payload: err.response.data.message
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

export const uploadTermsAndConditions = (data) => {
    return (dispatch) => {
        dispatch({
            type: "SET_LOADER",
            payload: true
        })
        axios.post(base_url + "documents", data)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: "SET_LOADER",
                        payload: false
                    })
                    dispatch({
                        type: UPLOAD_TERMS_AND_CONDITIONS_SUCCESS,
                        payload: response?.data?.data[0]?.key
                    })

                }
            })
            .catch((error) => {
                dispatch({
                    type: "SET_LOADER",
                    payload: false
                })
                dispatch({
                    type: UPLOAD_TERMS_AND_CONDITIONS_FAILURE,
                    payload: error.response.data.message
                })
            })
    }
}

export const uploadPaymentTerms = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.post(base_url + "documents", data)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                    dispatch({
                        type: UPLOAD_PAYMENT_TERMS_SUCCESS,
                        payload: response?.data?.data[0]?.key
                    })

                }
            })
            .catch((error) => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: UPLOAD_PAYMENT_TERMS_FAILURE,
                    payload: error.response.data.message
                })
            })
    }
}

export const finmanGetMeterReadings = (data) => {

    return (dispatch) => {
        axios
            .get(base_url + "facilities/meterReadings/meterReadingsContracts?request_type=dropdown&filter_condition=%7B%22facility_id%22%3A%22" + data["facility_id"] + "%22%2C%22start_date%22%3A%22" + data["start_date"] + "%22%2C%22end_date%22%3A%22" + data["end_date"] + "%22%2C%22meter_type%22%3A%22" + data["meter_type"] + "%22%7D")
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: FIN_MAN_GET_ALL_METER_READINGS_FAILURE,
                        payload: response,
                    });
                }
                else {
                    dispatch({
                        type: FIN_MAN_GET_ALL_METER_READINGS_SUCCESS,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: FIN_MAN_GET_ALL_METER_READINGS_FAILURE,
                    payload: error,
                });
            })
    }
}

export const uploadLogo = (data) => {

    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios
            .post(base_url + "documents", data)
            .then((response) => {
                dispatch({
                    type: UPLOAD_LOGO_SUCCESS,
                    payload: response?.data?.data[0].key,
                });
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            }
            )
            .catch((error) => {
                dispatch({
                    type: UPLOAD_LOGO_FAILURE,
                    payload: false,
                });
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            });

    }
}
export const uploadDscFile = (data) => {

    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios
            .post(base_url + "documents", data)
            .then((response) => {
                dispatch({
                    type: UPLOAD_DSC_SUCCESS,
                    payload: response?.data?.data[0].key,
                });
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            }
            )
            .catch((error) => {
                dispatch({
                    type: UPLOAD_DSC_FAILURE,
                    payload: false,
                });
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            });

    }
}

export const addNewService = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.post(base_url + "customers/serviceTypes", data)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: ADD_NEW_SERVICE_SUCCESS,
                        payload: "service has been added successfully"
                    })
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADD_NEW_SERVICE_FAILURE,
                    payload: err.response.data.message
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

export const getAllServices = () => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.get(base_url + "customers/serviceTypes")
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: GET_ALL_SERVICES_SUCCESS,
                        payload: res.data.data
                    })
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: GET_ALL_SERVICES_FAILURE,
                    payload: err.response.data.message
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

export const getServiceDetails = (id) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.get(base_url + `customers/serviceTypes?filter_condition=%7B%22service_type_id%22%3A%20%22${id}%22%7D`)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: GET_SERVICE_DETAILS_SUCCESS,
                        payload: res.data.data
                    })
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: GET_SERVICE_DETAILS_FAILURE,
                    payload: err.response.data.message
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

export const updateService = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.put(base_url + "customers/serviceTypes", data)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: UPDATE_SERVICE_DETAILS_SUCCESS,
                        payload: res.data.data.message
                    })
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: UPDATE_SERVICE_DETAILS_FAILURE,
                    payload: err.response.data.message
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

// Creating a form

export const createNewForm = (data) => {
    return (dispatch) => {
        let obj = {
            "form_id": data.id,
            "form_name": data.formName,
            "form_type": data.formType,
            "parent_template_id": data.templateId,
            "description": data.description,
            "json_data": JSON.stringify(data.items)
        }
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.post(base_url + "formBuilder/form", obj)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: CREATION_SUCCESS,
                        payload: `Form Created Successfully`
                    })
                }
            })
            .then(() => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
            .catch((err) => {
                dispatch({
                    type: CREATION_ERROR,
                    payload: err?.response?.data?.message
                })
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
}

export const submitToGSTSubmissionPortal = (data) => {
    return (dispatch) => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true
        })
        axios.post(base_url + "invoices/submitToGSTPortal", data)
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch({
                        type: PUT_GST_SUBMIT_SUCCESS,
                        payload: res.data.data
                    })
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                        payload: false
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: PUT_GST_SUBMIT_FAILURE,
                    payload: err.response.data.message
                })
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_FALSE,
                    payload: false
                })

            })
    }
}




// Fetch all forms

export const getAllForms = () => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.get(base_url + "formBuilder/form")
            .then((res) => {
                dispatch({
                    type: GET_ALL_FORMS,
                    payload: res.data.data
                })
            }).then(() => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
            .catch((err) => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: FETCHING_ERROR,
                    payload: err?.response?.data?.message
                })
            })
    }
}