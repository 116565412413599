import React, { useEffect, useState } from 'react'
import WorkLog from '../Reusable_Components/Work_Log/WorkLog.jsx';
import { IoAddOutline } from 'react-icons/io5';
import NavBarTop from '../Reusable_Components/NavBarTop.jsx';
import LeftNavBar from '../Reusable_Components/LeftNavBar.jsx';
import Search from '../Reusable_Components/Search.jsx';
import Dropdown from '../Reusable_Components/Dropdown.jsx';
import axios from "../../axios-interceptor.js";
import { base_url } from '../../utilities.jsx';
import Loader from '../Reusable_Components/loader.jsx';
import AlertPopUp from '../Reusable_Components/Alert.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { GET_ALL_USERS_ERROR, getAllUsers, getFacilityManagerList } from '../../Redux/Actions/Actions.jsx';
import { Modal } from 'react-bootstrap';
import UploadComponent from '../Reusable_Components/UploadComponent.jsx';
import { timeElapsed } from '../Reusable_Components/ReusableFunctions.js';
import ViewForm from '../FormBuilder/ViewForm.jsx';
import { TbCircleXFilled } from 'react-icons/tb';
import Spinner from 'react-bootstrap/Spinner';
import { FaRegEdit } from 'react-icons/fa';

const facilitiesList = [
    {
        name: "ACL",
        id: 1,
        backgroundColor: "rgba(198, 237, 201)",
        color: "red"
    },
    {
        name: "ACLC",
        id: 2,
        backgroundColor: "rgba(226, 181, 227)",
        color: "green"
    },
    {
        name: "ACLC-2A",
        id: 3,
        backgroundColor: "rgba(96, 211, 230, 0.7)",
        color: "blue"
    },
    {
        name: "ACLC-2B",
        id: 4,
        backgroundColor: "rgba(228, 175, 118,0.7)",
        color: "yellow"
    },
]

let data = {
    "PPM": [
        {
            id: 1,
            eventName: "Perform PPM on DG-1",
            scheduledDate: "05-11-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "10:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "todo",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 2,
            eventName: "Perform PPM on DG-2",
            scheduledDate: "01-11-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "12:00 PM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "todo",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Rajesh"
        },
        {
            id: 3,
            eventName: "Perform PPM on DG-3",
            scheduledDate: "01-11-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "02:00 PM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC",
            status: "todo",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Dinesh Karri"
        },
        {
            id: 4,
            eventName: "Perform Fire Drill at ACLC-2A",
            scheduledDate: "01-11-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2A",
            status: "inprogress",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Dinesh Dunna"
        },
        {
            id: 5,
            eventName: "Perform PPM on Dry transformer-1",
            scheduledDate: "01-11-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "inprogress",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Akhil Yerramilli"
        },
        {
            id: 6,
            eventName: "Perform PPM on Dry transformer-5",
            scheduledDate: "05-11-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2B",
            status: "approval",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Bharat Ram"
        },
        {
            id: 7,
            eventName: "Perform Meter Readings",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "completed",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Yashwanth palika"
        }
    ],
    "SOP": [
        {
            id: 8,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "10:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "todo",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Raj"
        },
        {
            id: 9,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "12:00 PM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC",
            status: "todo",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Rajesh"
        },
        {
            id: 10,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "02:00 PM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2A",
            status: "todo",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Dinesh Karri"
        },
        {
            id: 11,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2B",
            status: "inprogress",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Dinesh Dunna"
        },
        {
            id: 12,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "approval",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Akhil Yerramilli"
        },
        {
            id: 13,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACLC-2A",
            status: "approval",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Akhil Yerramilli"
        },
        {
            id: 14,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "approval",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Akhil Yerramilli"
        },
        {
            id: 15,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "approval",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Akhil Yerramilli"
        },
        {
            id: 16,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "completed",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Bharat Ram"
        },
        {
            id: 17,
            eventName: "Fire-Alarm - SOP",
            scheduledDate: "20-10-2024",
            scheduleTimeDesc: "Monthly on the fourth Thursday",
            scheduledTime: "11:00 AM",
            remainder: "One day before",
            supervisor: "Amit",
            sop: "Diesel Generator SOP",
            form: "Diesel Generator Checklist",
            facility: "ACL",
            status: "completed",
            comments: [{ name: "Raj", comment: "Need to check the fuel levels", time: "2024-07-09 10:50:44.425000+00:00" }],
            assignedTo: "Yashwanth palika"
        }
    ]
}

const assignee_list = [
    {
        name: "Raj"
    },
    {
        name: "Rajesh"
    },
    {
        name: "Dinesh Karri"
    },
    {
        name: "Dinesh Dunna"
    },
    {
        name: "Akhil Yerramilli"
    },
    {
        name: "Bharat Ram"
    },
    {
        name: "Yashwanth palika"
    },
    {
        name: "Pavani Sreemukhi"
    },
    {
        name: "Yashwanth kumar"
    }
]

let form_items = [
    {
        "id": "d9a29570-11b3-4d68-9e17-ef1572410455",
        "items": [
            {
                "type": "text",
                "label": "Dry Transformer",
                "id": "39081466-54eb-4a4f-a525-958080fcaf0c",
                "content": "Dry Transformer",
                "size": "h2"
            }
        ]
    },
    {
        "id": "d34afb27-3754-45a2-a24f-32b8327d2ee3",
        "items": [
            {
                "type": "input",
                "label": "Asset",
                "id": "a109b53f-095a-437d-81a2-9bf8a7421f1e",
                "content": "Asset"
            },
            {
                "type": "input",
                "label": "Work Group",
                "id": "4ddc1c84-0d84-430a-a2bf-e8a067220edf",
                "content": "Work Group"
            }
        ]
    },
    {
        "id": "bd742047-2d2d-44a9-ae90-a240f09cff47",
        "items": [
            {
                "type": "input",
                "label": "Rating/Make",
                "id": "cc1f6e12-a8d3-4cd8-b333-3655aafc66ed",
                "content": "Rating/Make"
            },
            {
                "type": "input",
                "label": "Frequency",
                "id": "72f08bc9-ff7c-4519-a396-6fb3089ae872",
                "content": "Frequency"
            }
        ]
    },
    {
        "id": "6ddf16da-8b25-4c5a-aa47-6ab12ca3ad82",
        "items": [
            {
                "type": "input",
                "label": "Location",
                "id": "c5811d4c-14b3-463c-ad55-7defbc110294",
                "content": "Location"
            },
            {
                "type": "date",
                "label": "Date",
                "id": "e6adba84-e15d-4c73-8e5b-696f16996e1f",
                "content": "Date"
            }
        ]
    },
    {
        "id": "f59d7035-c9c4-44bc-b9f8-2dca69a6e86d",
        "items": [
            {
                "type": "text",
                "label": "Fill up the checklist with TICK or CROSS  against each activity and write the test parameters",
                "id": "4cfe1945-3739-49df-80ee-b71e808fd0e0",
                "content": "Fill up the checklist with TICK or CROSS  against each activity and write the test parameters",
                "size": "h6"
            }
        ]
    },
    {
        "id": "7cf185f4-99cd-4196-8535-171946b4ccd9",
        "items": [
            {
                "type": "table",
                "label": "Table",
                "id": "73161e63-e88e-4a99-9378-37c2621d5f67",
                "content": "Table",
                "rows": 8,
                "columns": 3,
                "columnNames": [
                    "B. Performance checklist",
                    "Status",
                    "Remarks"
                ],
                "columnsType": [
                    "text",
                    "toggle",
                    "input"
                ],
                "tableData": [
                    [
                        {
                            "type": "text",
                            "content": "1.Facility Manager to ensure that the PPM does not affect site operations"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        }
                    ],
                    [
                        {
                            "type": "text",
                            "content": "2.Isolate the incoming source and apply LOTO"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        }
                    ],
                    [
                        {
                            "type": "text",
                            "content": "3.Make sure that supply isnot available at the i/p terminal of the equipment."
                        },
                        {
                            "type": "text",
                            "content": "text"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        }
                    ],
                    [
                        {
                            "type": "text",
                            "content": "4.Use of insulated hand gloves while working."
                        },
                        {
                            "type": "toggle",
                            "content": ""
                        },
                        {
                            "type": "input",
                            "content": ""
                        }
                    ],
                    [
                        {
                            "type": "text",
                            "content": "5.Use of Hard hats, Safety shoes & safety goggles"
                        },
                        {
                            "type": "toggle",
                            "content": ""
                        },
                        {
                            "type": "input",
                            "content": ""
                        }
                    ],
                    [
                        {
                            "type": "text",
                            "content": "6.Use of Hard hats, Safety shoes & safety goggles"
                        },
                        {
                            "type": "toggle",
                            "content": ""
                        },
                        {
                            "type": "input",
                            "content": ""
                        }
                    ],
                    [
                        {
                            "type": "text",
                            "content": "7.Use of Hard hats, Safety shoes & safety goggles"
                        },
                        {
                            "type": "toggle",
                            "content": ""
                        },
                        {
                            "type": "input",
                            "content": ""
                        }
                    ],
                    [
                        {
                            "type": "text",
                            "content": "8.Other Special PPEs (Please specify if any)"
                        },
                        {
                            "type": "toggle",
                            "content": ""
                        },
                        {
                            "type": "input",
                            "content": ""
                        }
                    ]
                ]
            }
        ]
    },
    {
        "id": "71b98e09-88bb-4e0a-bfce-4012957a340b",
        "items": [
            {
                "type": "table",
                "label": "Inspection and test results",
                "id": "28a697dc-da73-4c86-8b2d-00ba8bb3985e",
                "content": "Inspection and test results",
                "rows": 2,
                "columns": 4,
                "columnNames": [
                    "Inspection and Test Results",
                    "RY",
                    "YB",
                    "BR"
                ],
                "columnsType": [
                    "text",
                    "input",
                    "input",
                    "input"
                ],
                "tableData": [
                    [
                        {
                            "type": "text",
                            "content": "Voltage"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        }
                    ],
                    [
                        {
                            "type": "text",
                            "content": "Running AMP"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        },
                        {
                            "type": "text",
                            "content": "text"
                        }
                    ]
                ]
            }
        ]
    }
]

const colors = [
    "#DF4451",
    "#5E40A1",
    "#F7BC26",
    "#0F2D80",
    "#15BDFA",
    "#369C2A",
]

export default function FacilityManagerWorkLog({ Heading }) {

    const dispatch = useDispatch();

    const group = localStorage.getItem("group");

    const technicianList = useSelector(state => state.usersList);
    const supervisorList = useSelector(state => state.supervisorList);
    const usersListError = useSelector(state => state.usersListError);

    const [columnNames, setColumnNames] = useState([])
    const [tasks, setTasks] = useState([])
    const [filteredTasks, setFilteredTasks] = useState([])
    const [assignee, setAssignee] = useState([])
    const [remainingAssignee, setRemainingAssignee] = useState([])
    const [selectedAssignee, setSelectedAssignee] = useState([])
    const [selectedRemainingAssignee, setSelectedRemainingAssignee] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [fromDate, setFromDate] = useState(() => {
        const date = new Date();
        date.setDate(date.getDate());
        return date.toISOString().split('T')[0];
    });
    const [toDate, setToDate] = useState(() => {
        const date = new Date();
        date.setDate(date.getDate() + 7);
        return date.toISOString().split('T')[0];
    });
    const [selectedFacility, setSelectedFacility] = useState('all')
    const [sections, setSections] = useState([])
    const [loader, setLoader] = useState(false)
    const [loading, setLoading] = useState(false)
    const [facilitiesList, setFacilitiesList] = useState([])
    const [getTasksError, setGetTasksError] = useState(null)
    const [selectedTask, setSelectedTask] = useState(null)
    const [editDate, setEditDate] = useState(false)
    const [editAssignee, setEditAssignee] = useState(false)
    const [editSupervisor, setEditSupervisor] = useState(false)
    const [viewForm, setViewForm] = useState(false)
    const [comments, setComments] = useState({
        name: "",
        comment: "",
        date: ""
    });
    const [formData, setFormData] = useState({})
    const [error, setError] = useState(null)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const handleFormData = (key, value) => {
        let newFormData = formData
        newFormData[key] = value
        setFormData({ ...newFormData })
    }

    const tableData = (key, row, tableName, value) => {
        let newFormData = { ...formData }
        let tableData = newFormData[tableName] ? newFormData[tableName] : []
        tableData[row] = { ...tableData[row], [key]: value }
        // tableData[row][key] = value
        newFormData[tableName] = tableData

        setFormData(newFormData)
    }

    useEffect(() => {
        setColumnNames(['scheduled', 'inprogress', 'approval', 'completed']);
    }, [fromDate, toDate]);

    const fetchAllTasks = (selectedTaskObj) => {
        setLoader(true)
        let url = (localStorage.getItem("group") === "Technician") ? `events/tasks?filter_condition=%7B%22task_start_time%22%3A%22${fromDate}%22%2C%22task_end_time%22%3A%22${toDate}%22%2C%22assignee%22%3A%22${localStorage.getItem("username")}%22%7D` : `events/tasks?filter_condition=%7B%22task_start_time%22%3A%22${fromDate}%22%2C%22task_end_time%22%3A%22${toDate}%22%7D`
        axios.get(base_url + url)
            .then((response) => {
                setSections(Object.keys(response?.data?.data[0])?.map((each) => {
                    const selectedTaskCategory = Object.keys(response?.data?.data[0]).find(category =>
                        response?.data?.data[0][category].some(task => task.event_task_id === selectedTaskObj?.event_task_id)
                    );
                    return {
                        tab: each,
                        isOpen: selectedTaskCategory === each ? true : false
                    }
                }))
                setTasks(response?.data?.data[0]);
                setFilteredTasks(response?.data?.data[0]);
                let assigneeList = [];
                Object.values(response?.data?.data[0]).forEach((tasks) => {
                    tasks.forEach((task) => {
                        task.assignee ? assigneeList.push({ name: task.assignee }) : assigneeList.push({ name: "Unassigned" });
                    });
                });
                const uniqueAssigneeList = Array.from(new Set(assigneeList.map(a => a.name))).map(name => {
                    return assigneeList.find(a => a.name === name);
                });
                uniqueAssigneeList.length > 5 ? setAssignee(uniqueAssigneeList.slice(0, 5)) : setAssignee(uniqueAssigneeList);
                uniqueAssigneeList.length > 5 && setRemainingAssignee(uniqueAssigneeList.slice(5));


                const facilityNames = Object.values(response?.data?.data[0]).flat().map(task => task.facility_name && task.facility_name);
                const uniqueFacilityNames = Array.from(new Set(facilityNames)).map(name => {
                    return { name };
                });
                setFacilitiesList(uniqueFacilityNames);
                group !== "Technician" && dispatch(getAllUsers("Technician"));
                group !== "Technician" && dispatch(getFacilityManagerList("Facility Manager"));
                setLoader(false)
            })
            .catch((error) => {
                console.log(error);
                setGetTasksError(error.response?.data?.message);
                setLoader(false)
            });
    }

    useEffect(() => {
        fetchAllTasks()

    }, [fromDate, toDate]);

    const getInitials = (name) => {
        return name?.split(' ')
            .map(word => word[0].toUpperCase())
            .join('');
    };

    const randomColor = (name) => {
        const initials = getInitials(name);
        const charCode = initials?.charCodeAt(0);
        const colorIndex = charCode % colors.length;
        return colors[colorIndex];
    }

    const filterAssignee = (assignee) => {
        let updatedSelectedAssignee;
        if (selectedAssignee.includes(assignee?.name)) {
            updatedSelectedAssignee = selectedAssignee.filter(selected => selected !== assignee?.name);
        } else {
            updatedSelectedAssignee = [...selectedAssignee, assignee?.name];
        }

        setSelectedAssignee(updatedSelectedAssignee);
        if (updatedSelectedAssignee.length === 0) {
            setFilteredTasks(tasks);
        } else {
            const filtered = Object.keys(tasks).reduce((acc, key) => {
                acc[key] = tasks[key].filter(task => updatedSelectedAssignee.includes(task.assignee) || (task.assignee === null && updatedSelectedAssignee.includes("Unassigned")));
                return acc;
            }, {});
            setFilteredTasks(filtered);
        }
    };

    const handleSearchTasks = (e) => {
        const searchText = e.target.value;
        setSearchText(searchText);
        const filtered = Object.keys(tasks).reduce((acc, key) => {
            acc[key] = tasks[key].filter(task => task.task_name.toLowerCase().includes(searchText.toLowerCase()));
            return acc;
        }, {});
        setFilteredTasks(filtered);
    };

    const handleChangeFromDate = (e) => {
        setFromDate(e.target.value);
    };

    const handleChangeToDate = (e) => {
        setToDate(e.target.value);
    };

    const handleFacilityChange = (e) => {
        const facility = e.target.value;
        setSelectedFacility(facility);
    };

    const handelSectionChange = (tab) => {
        setSections(sections.map((section) => {
            if (section.tab === tab) {
                return { ...section, isOpen: !(section.isOpen) }
            } else {
                return section
            }
        }))
    }

    const handleUpdateStatus = (task, status) => {
        setFilteredTasks((prevTasks) => {
            const updatedTasks = { ...prevTasks };
            Object.keys(updatedTasks).forEach(categoryKey => {
                updatedTasks[categoryKey] = updatedTasks[categoryKey].map(t => {
                    if (t.event_task_id === task.event_task_id) {
                        return { ...t, task_status: status };
                    }
                    return t;
                });
            });
            return updatedTasks;
        });
        // const data = {
        //     "event_task_id": task.event_task_id,
        //     "access_role_name": localStorage.getItem("group") === "Facility Manager" ? "FACILITY MANAGER" : localStorage.getItem("Technician") && "TECHNICIAN",
        //     "workflow_id": "1",
        //     "current_status": task.task_status.toUpperCase(),
        //     "new_status": status.toUpperCase(),
        //     "task_start_time": task.task_start_time,
        //     "task_end_time": task.task_end_time,
        // }
        // axios.put(base_url + `events/tasks`, data)
        //     .then((res) => {
        //         console.log(res)
        //     })
        //     .catch((error) => {
        //         console.log(error)
        //     }
        //     )
    }

    const showTaskModal = (task) => {
        setSelectedTask(task);
    }

    const handleUpdateTask = (obj, updateState) => {
        setLoading(true)
        const updatedTask = { ...selectedTask, ...obj };
        delete updatedTask.facilityColor;
        delete updatedTask.comments;
        axios.put(base_url + 'events/tasks', updatedTask)
            .then((res) => {
                setSelectedTask(updatedTask)
                updateState(false)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setError(err.response?.data?.message)
                setLoader(false)
                setLoading(false)
            }
            )
    }

    const handleShowConfirmationModal = () => {
        setShowConfirmationModal(true)
    }


    const handleDeleteTask = () => {
        setShowConfirmationModal(false)
        setLoader(true)
        axios.delete(base_url + 'events/tasks', { data: { event_task_id: selectedTask?.event_task_id } })
            .then((res) => {
                setSelectedTask(null)
                setFilteredTasks(
                    Object.keys(tasks).reduce((acc, key) => {
                        acc[key] = tasks[key].filter(task => task.event_task_id !== selectedTask?.event_task_id);
                        return acc;
                    }, {})
                )
                setLoader(false)
            })
            .catch((err) => {
                console.log(err)
                setError(err.response?.data?.message)
                setLoader(false)
            })
    }

    return (
        <>
            <NavBarTop />
            <LeftNavBar />
            <div className='tasks-screen-container'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-2'>
                    <div className='d-flex align-items-center gap-3'>
                        <h3 className="invoice-heading" >{Heading ? Heading : "Work Log"}</h3>
                    </div>
                    {group !== "Technician" ? <div className='scheduler-dropdown'>
                        <IoAddOutline style={{ height: "20px", width: "20px", borderWidth: "1px" }} />
                        <select className='worklog-dropdown'>
                            <option value="" hidden>Schedule Event</option>
                            <option value="monthly">Monthly</option>
                            <option value="quarterly">Quarterly</option>
                            <option value="yearly">Yearly</option>
                        </select>
                    </div>
                        :
                        <div className='d-flex gap-2'>
                            <div className='d-flex flex-column'>
                                <label>From date</label>
                                <input type='date' max={toDate} value={fromDate} onChange={handleChangeFromDate} className='fin_man_date_range' />
                            </div>
                            <div className='d-flex flex-column'>
                                <label>To date</label>
                                <input type='date' min={fromDate} value={toDate} onChange={handleChangeToDate} className='fin_man_date_range' />
                            </div>
                        </div>
                    }
                </div>
                <div className='d-flex mb-2 justify-content-between align-items-end gap-2 flex-wrap'>
                    {group !== "Technician" && <Search value={searchText} handleSearch={handleSearchTasks} />}

                    {group !== "Technician" && facilitiesList && <Dropdown
                        selectedOption={selectedFacility}
                        handleChange={handleFacilityChange}
                        options={facilitiesList}
                        name='Facility'
                        className='dropdown-list'
                        handleChangeTaskStatus={handleUpdateStatus}
                        defaultOption={'All'}
                    />}

                    {group !== "Technician" && <div className='d-flex gap-2'>
                        <div className='d-flex flex-column'>
                            <label>From date</label>
                            <input type='date' max={toDate} value={fromDate} onChange={handleChangeFromDate} className='fin_man_date_range' />
                        </div>
                        <div className='d-flex flex-column'>
                            <label>To date</label>
                            <input type='date' min={fromDate} value={toDate} onChange={handleChangeToDate} className='fin_man_date_range' />
                        </div>
                    </div>}
                    {group !== "Technician" && assignee.length > 0 && <div className='d-flex'>
                        {assignee.map((assignee, index) => {
                            return (
                                <div key={index} className={`worklog-assignee ${selectedAssignee?.some(selected => selected === assignee?.name) ? 'selected-worklog-assignee' : ''}`}
                                    style={{ marginLeft: index !== 0 ? '-8px' : '0', backgroundColor: assignee?.name === "Unassigned" ? "gray" : randomColor(assignee?.name) }}
                                    onClick={() => filterAssignee(assignee)}
                                    title={assignee?.name}
                                >
                                    <span>{getInitials(assignee?.name)}</span>
                                </div>
                            )
                        })}
                        {remainingAssignee.length > 0 && <div className='d-flex flex-column'>
                            <div className={`worklog-assignee ${selectedRemainingAssignee ? 'selected-worklog-assignee' : ''}`} style={{ marginLeft: '-8px', backgroundColor: "#E1E1E1", color: "black" }}
                                onClick={() => setSelectedRemainingAssignee(!selectedRemainingAssignee)}
                            >
                                <span>+{remainingAssignee.length}</span>
                            </div>
                            <div className='remaining-assignee' onMouseLeave={() => setSelectedRemainingAssignee(false)}>
                                {selectedRemainingAssignee && <div className='remaining-assignee-list'  >
                                    {selectedRemainingAssignee && remainingAssignee.map((assignee, index) => {
                                        return (
                                            <div className='d-flex align-items-center gap-2 C-pointer'
                                                onClick={() => filterAssignee(assignee)}
                                            >
                                                <div>
                                                    <input type='checkbox' checked={selectedAssignee?.some(selected => selected === assignee?.name)} />
                                                </div>
                                                <div key={index} className='worklog-assignee' style={{ width: "30px", height: "30px", backgroundColor: randomColor(assignee.name) }}
                                                >
                                                    <span>{getInitials(assignee?.name)}</span>
                                                </div>
                                                <div style={{ fontSize: "12px" }}>
                                                    <span>{assignee.name}</span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>}
                            </div>
                        </div>}
                    </div>}
                </div>
                <WorkLog
                    height={window.innerHeight - 250}
                    columns={columnNames}
                    data={filteredTasks}
                    facilitiesList={facilitiesList}
                    sections={sections}
                    handelSectionChange={handelSectionChange}
                    handleChangeTaskStatus={handleUpdateStatus}
                    showTaskModal={showTaskModal}
                />
            </div>
            {loader && <Loader />}
            {getTasksError && <AlertPopUp
                alertShow={getTasksError}
                alertType="error"
                alertMsg="Error"
                alertValue={getTasksError}
                alertButtons={[{
                    name: "Close", action: () => setGetTasksError(null)
                }]} />}
            {selectedTask && <Modal backdrop="static" show={selectedTask} onHide={() => setSelectedTask(null)} centered>
                <Modal.Body style={{ maxHeight: "90vh", overflow: "auto" }}>
                    <div className='d-flex flex-column gap-2'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <h4>{selectedTask?.task_name}</h4>
                            </div>
                            <div className='d-flex gap-2'>
                                {group !== "Technician" && <img src='./images/icons/DeleteIcon.svg' alt='delete' onClick={handleShowConfirmationModal} />}
                                <img src='./images/icons/WrongIcon.svg' alt='close' role='button' onClick={() => {
                                    setSelectedTask(null)
                                    group !== "Technician" && fetchAllTasks(selectedTask)
                                    setEditDate(false)
                                    setEditAssignee(false)
                                    setEditSupervisor(false)
                                }} />
                            </div>
                        </div>
                        <div style={{ border: "1px solid lightgray" }} className='d-flex flex-column gap-2 p-2'>
                            <div className='d-flex align-items-center'>
                                <span style={{ width: "30%", fontWeight: "600" }}>Status</span>
                                <Dropdown options={
                                    columnNames.map(status => {
                                        return { name: status.charAt(0).toUpperCase() + status.slice(1), backgroundColor: "white", value: status }
                                    })
                                } selectedOption={selectedTask?.status}
                                    handleChange={(e) => {
                                        handleUpdateStatus(selectedTask, e.target.value)
                                        // setShowTask(false)
                                    }}
                                    className='dropdown-list'
                                />
                            </div>
                            <div className='d-flex align-items-center'>
                                <span style={{ width: "30%", fontWeight: "600" }}>Scheduled Date</span>
                                <div>
                                    <div className='d-flex align-items-center C-pointer'>
                                        {editDate ? <input type="date" className='fin_man_date_range' onChange={(e) => handleUpdateTask({ task_start_time: e.target.value }, setEditDate)} value={selectedTask?.task_start_time.split(" ")[0]} /> : <span>{selectedTask?.task_start_time.split(" ")[0]}</span>}
                                        {editDate ? <div className='d-flex gap-2 mx-3'>
                                            {!loading ? <TbCircleXFilled
                                                style={{ color: 'red', height: '30px' }}
                                                onClick={() => setEditDate(false)}
                                            /> :
                                                <div>
                                                    <Spinner animation="border" role="status" style={{ height: "20px", width: "20px", borderWidth: "1px" }}>
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            }</div> : group !== "Technician" && <FaRegEdit className='mx-3' alt='edit' onClick={() => setEditDate(true)} />}
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center C-pointer'>
                                <span style={{ width: "30%", fontWeight: "600" }}>Assignee</span>
                                {editAssignee ?
                                    <div>
                                        <select className='form-control' value={selectedTask?.assignee} onChange={(e) => handleUpdateTask({ assignee: e.target.value === "unassigned" ? null : e.target.value }, setEditAssignee)}>
                                            <option value="" hidden>Select Assignee</option>
                                            {technicianList && technicianList.map((assignee, index) => {
                                                return <option key={index} value={assignee.username}>{assignee.username}</option>
                                            })}
                                            <option value={"unassigned"}>Unassigned</option>
                                        </select>
                                    </div>
                                    : <span>{!(selectedTask?.assignee) ? "unassigned" : selectedTask?.assignee}</span>}
                                {editAssignee ? <div className='d-flex gap-2 mx-3'>
                                    {!loading ? <TbCircleXFilled
                                        style={{ color: 'red', height: '30px' }}
                                        onClick={() => setEditAssignee(false)}
                                    /> :
                                        <div>
                                            <Spinner animation="border" role="status" style={{ height: "20px", width: "20px", borderWidth: "1px" }}>
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </div>}
                                </div> : group !== "Technician" && <FaRegEdit className='mx-3' alt='edit' onClick={() => setEditAssignee(true)} />}
                            </div>
                            <div className='d-flex align-items-center C-pointer'>
                                <span style={{ width: "30%", fontWeight: "600" }}>Supervisor</span>
                                {editSupervisor ?
                                    <div>
                                        <select className='form-control' value={selectedTask?.supervisor} onChange={(e) => handleUpdateTask({ supervisor: e.target.value === "unassigned" ? null : e.target.value }, setEditSupervisor)}>
                                            <option value="" hidden>Select Supervisor</option>
                                            {supervisorList && supervisorList.map((supervisor, index) => {
                                                return <option key={index} value={supervisor.username}>{supervisor.username}</option>
                                            })}
                                            <option value="unassigned">Unassigned</option>
                                        </select>
                                    </div>
                                    : <span>{!(selectedTask?.supervisor) ? "unassigned" : selectedTask?.supervisor}</span>}
                                {editSupervisor ? <div className='d-flex gap-2 mx-3'>
                                    {!loading ? <TbCircleXFilled
                                        style={{ color: 'red', height: '30px' }}
                                        onClick={() => setEditSupervisor(false)}
                                    /> :
                                        <div>
                                            <Spinner animation="border" role="status" style={{ height: "20px", width: "20px", borderWidth: "1px" }}>
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </div>
                                    }
                                </div> : group !== "Technician" && <FaRegEdit className='mx-3' alt='edit' onClick={() => setEditSupervisor(true)} />}
                            </div>
                            <div className='d-flex align-items-center'>
                                <span style={{ width: "30%", fontWeight: "600" }}>Facility</span>
                                <span>{selectedTask?.facility_name}</span>
                            </div>
                            <div className='d-flex align-items-center'>
                                <span style={{ width: "30%", fontWeight: "600" }}>SOP</span>
                                <span style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }} >DG PPM SOP</span>
                            </div>
                            <div className='d-flex align-items-center'>
                                <span style={{ width: "30%", fontWeight: "600" }}>FORM</span>
                                <span style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }} onClick={() => {
                                    setViewForm(true)
                                }}>DG PPM Checklist</span>
                            </div>
                        </div>
                        <div>
                            <span style={{ width: "30%", fontWeight: "600" }}>Attachments</span>
                            <UploadComponent
                                onFilesSelected={(files) => console.log(files)}
                                acceptedFileTypes={['image/*', 'application/pdf']}
                                idType={"task"}
                                maxNumberOfFiles={5}
                                multiple={true}
                                uploadedFileData={[]}
                            />
                        </div>
                        <div className='d-flex flex-column gap-2'>
                            <span style={{ width: "30%", fontWeight: "600" }}>Comments</span>
                            <div className='comments-section'>
                                {selectedTask?.comments?.map((comment, index) => (
                                    <div className='d-flex gap-2'>
                                        <div key={index} className={`worklog-assignee`}
                                            style={{ marginLeft: '0px', backgroundColor: randomColor(comment?.name) }}
                                        >
                                            <span>{getInitials(comment?.name)}</span>
                                        </div>
                                        <div key={index}>
                                            <b className='comment-author'>{comment?.name} </b> <span>{timeElapsed(comment?.time)}</span>
                                            <div
                                                className='comment-text'
                                                dangerouslySetInnerHTML={{ __html: comment?.comment.replace(/\n/g, '<br />') }}
                                            ></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <textarea
                                className='form-control'
                                placeholder='Add a comment...'
                                rows='3'
                                value={comments?.comment}
                                onChange={(e) => {
                                    setComments({ name: localStorage.getItem("username"), comment: e.target.value, date: new Date().toLocaleDateString('en-GB') })
                                }}
                            />
                            <button
                                className='btn btn-secondary mt-2'
                                onClick={() => {
                                    setSelectedTask({
                                        ...selectedTask,
                                        comments: [...(selectedTask.comments || []), comments]
                                    });
                                    setComments({
                                        name: "",
                                        comment: "",
                                        date: ""
                                    })
                                }}
                                disabled={comments?.comment === ""}
                            >
                                Add Comment
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >}
            {showConfirmationModal && <AlertPopUp
                alertShow={showConfirmationModal}
                alertType="warning"
                alertMsg="Warning"
                alertValue="Are you sure you want to delete this task?"
                alertButtons={[{
                    name: "Yes", action: handleDeleteTask
                },
                {
                    name: "No", action: () => setShowConfirmationModal(false),
                    secondary: true
                }]}
            />
            }
            {
                viewForm &&
                <ViewForm closeForm={() => setViewForm(false)} form_items={form_items} handleFormData={handleFormData} tableData={tableData} />
            }
            {error && <AlertPopUp
                alertShow={error}
                alertType="error"
                alertMsg="Error"
                alertValue={error}
                alertButtons={[{
                    name: "Close", action: () => setError(null)
                }]} />
            }
            {usersListError && <AlertPopUp
                alertShow={usersListError}
                alertType="error"
                alertMsg="Error"
                alertValue={usersListError}
                alertButtons={[{
                    name: "Close", action: () => dispatch({
                        type: GET_ALL_USERS_ERROR,
                        payload: null
                    })
                }]}
            />
            }
        </>
    )
}
