import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from "react-router-dom";
import './App.css';
import { Provider } from 'react-redux';
import store from './Redux/Store';
// import Login from './Components/ReusableComponents/Login';
import FIinanceManagerMeterReports from './Components/Finance_Manager/FIinanceManagerMeterReports';
import FinanceManagerPayments from "./Components/Finance_Manager/FinanceManagerPayments";

import NavBarTop from "./Components/Reusable_Components/NavBarTop";
import LeftNavBar from "./Components/Reusable_Components/LeftNavBar";
import FinanceManagerInvoice from "./Components/Finance_Manager/FinanceManagerInvoice";
import FinanceManagerDashboard from "./Components/Finance_Manager/FinanceManagerDashboard";
import FinanceManagerCalender from "./Components/Finance_Manager/FinanceManagerCalender";
import FinanceManagerUsers from "./Components/Finance_Manager/FinanceManagerUsers";
import CommercialHeadDashboard from "./Components/Commercial_Head/CommercialHeadDashboard";
import CommercialHeadUsers from "./Components/Commercial_Head/CommercialHeadUsers";
import CommercialHeadAddFacility from "./Components/Commercial_Head/CommercialHeadAddFacility";
import WaterMeterReadings from "./Components/Technician/waterMeterReadings";
import ElectricityMeterReadings from "./Components/Technician/electricityMeterReadings";
import TechnicianDashboard from "./Components/Technician/TechnicianDashboard";
import Form from "./Components/FormBuilder/Form";
import FormBuilder from "./Components/FormBuilder/FormBuilder";
import LoginPage from "./Components/Login/loginPage";
import FileUpload from "./Components/Reusable_Components/FileUpload";
import CommercialHeadUsersList from "./Components/Commercial_Head/CommercialHeadUsersList";
import SetPassword from "./Components/AddUser/SetPassword";
import FacilityManagerMeterMaster from "./Components/Facility_Manager/FacilityManagerMeterMaster";
import FacilityManagerAssetsList from "./Components/Facility_Manager/FacilityManagerAssetsList.jsx";

import AdminControls from "./Components/Admin_Controls/AdminControls";
import PartnerCompanies from "./Components/Partner_Companies/PartnerCompanies";
import Unauthorized from "./Components/Reusable_Components/Unauthorized";
import AccessDenied from "./Components/Reusable_Components/AccessDenided";
import PageNotFound from "./Components/Reusable_Components/PageNotFound";
import CommercialHeadFMS from "./Components/Commercial_Head/CommercialHeadFMS";
import { CEO_GROUP, COMMERCIAL_HEAD_GROUP, FACILITY_MANAGER_GROUP, FINANCE_MANAGER_GROUP, TECHNICIAN_GROUP } from "./Config";

import { getGroupNameVisaVi } from "./Components/Reusable_Components/ReusableFunctions";
import { ACLS } from "./Config";
import Services from "./Components/Admin_Controls/Services";

import PrivacyPolicy from "./Components/Login/privacypolicy";
import TermsAndConditions from "./Components/Login/termsandconditions";
// import AppFooter from "./Components/Reusable_Components/fotter";
import Footer from "./Components/Login/Footer.jsx"
import TechnicianChecklist from "./Components/Technician/TechnicianChecklist.jsx";
import GstSubmissionLogs from "./Components/Admin_Controls/GstSubmissionLogs.jsx";
import EmailLogs from "./Components/Admin_Controls/EmailLogs.jsx";
import LicensesAndPermits from "./Components/Admin_Controls/LicensesAndPermits.jsx";
import FacilityManagerWorkLog from "./Components/Facility_Manager/FacilityManagerWorkLog.jsx";
import CompaniesListPage from "./Components/Facility_Manager/CompaniesListPage.jsx";


const ProtectedRoute = ({ path, element }) => {
  const allowedRoutes = ACLS[getGroupNameVisaVi(localStorage.getItem('group'))]?.map((item) => item.ROUTE);

  if (allowedRoutes?.includes(path)) {
    return element;
  } else {
    return <Navigate to="/accessDenied" />;
  }
};

function App() {

  return (
    <Provider store={store}>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/fileupload" element={<FileUpload />} />
            <Route path="/form/:name" element={<Form />} />
            <Route path="/formbuilder" element={<FormBuilder />} />
            <Route path="/setpassword/:id" element={<SetPassword />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/accessDenied" element={<AccessDenied />} />
            <Route path="/privacypolicy" component={<PrivacyPolicy />} />
            <Route path="/termsandconditions" element={<TermsAndConditions />} />
            {/* <Route path="/technicianChecklist" element={<TechnicianChecklist />} /> */}
            {/* <Route path="/financeManagerCalendar" element={<FinanceManagerCalender />} /> */}
            {/* <Route path="/worklog" element={<WorkLaw />} /> */}
            {/* <Route path="/technicianChecklist" element={<ProtectedRoute path="/technicianChecklist" element={<TechnicianChecklist />} />} /> */}
            <Route path="*" element={<PageNotFound />} />
            <Route path="/financeManagerCalender" element={<ProtectedRoute path="/financeManagerCalender" element={<FinanceManagerCalender />} />} />

            {/* ======= Commercial head ====================== */}
            <Route path="/commercialHeadDashboard" element={<ProtectedRoute path="/commercialHeadDashboard" element={<CommercialHeadDashboard />} />} />
            <Route path="/commercialHeadContracts" element={<ProtectedRoute path="/commercialHeadContracts" element={<CommercialHeadUsers />} />} />
            <Route path="/commercialHeadAddFacility" element={<ProtectedRoute path="/commercialHeadAddFacility" element={<CommercialHeadAddFacility />} />} />
            <Route path="/users" element={<ProtectedRoute path="/users" element={<CommercialHeadUsersList />} />} />
            <Route path="/commercialHeadFMSContracts" element={<ProtectedRoute path="/commercialHeadFMSContracts" element={<CommercialHeadFMS />} />} />
            {/* ========== Technician ====================== */}

            <Route path="/waterMeterReadings" element={<ProtectedRoute path="/waterMeterReadings" element={<WaterMeterReadings />} />} />

            <Route path="/electricityMeterReadings" element={<ProtectedRoute path="/electricityMeterReadings" element={<ElectricityMeterReadings />} />} />
            <Route path="/technicianDashboard" element={<ProtectedRoute path="/technicianDashboard" element={<TechnicianDashboard />} />} />

            {/* =============== Finance Manager ====================== */}
            <Route path="/financeManagerInvoices" element={<ProtectedRoute path="/financeManagerInvoices" element={<FinanceManagerInvoice />} />} />
            <Route path="/financeManagerCalender" element={<ProtectedRoute path="/financeManagerCalender" element={<FinanceManagerCalender />} />} />
            <Route path="/financeManagerMeterReports" element={<ProtectedRoute path="/financeManagerMeterReports" element={<FIinanceManagerMeterReports />} />} />
            <Route path="/financeManagerPayments" element={<ProtectedRoute path="/financeManagerPayments" element={<FinanceManagerPayments />} />} />
            <Route path="/financeManagerDashboard" element={<ProtectedRoute path="/financeManagerDashboard" element={<FinanceManagerDashboard />} />} />
            <Route path="/financeManagerUsers" element={<ProtectedRoute path="/financeManagerUsers" element={<FinanceManagerUsers />} />} />


            {/* ============ CEO ====================== */}
            <Route path="/adminControls" element={<ProtectedRoute path="/adminControls" element={<AdminControls />} />} />
            <Route path="/ourCompanies" element={<ProtectedRoute path="/ourCompanies" element={<PartnerCompanies />} />} />
            <Route path="/emailLogs" element={<ProtectedRoute path="/emailLogs" element={<EmailLogs />} />} />
            <Route path="/gstSubmissionLogs" element={<ProtectedRoute path="/gstSubmissionLogs" element={<GstSubmissionLogs />} />} />
            <Route path="/services" element={<ProtectedRoute path="/services" element={<Services />} />} />
            <Route path='/licensesAndPermits' element={<ProtectedRoute path='/licensesAndPermits' element={<LicensesAndPermits />} />} />

            {/* ================== Facility Manager ======================  */}
            <Route path="/FacilityManagerMeterMaster" element={<ProtectedRoute path="/FacilityManagerMeterMaster" element={<FacilityManagerMeterMaster />} />} />
            <Route path="/FacilityManagerAssetsList" element={<ProtectedRoute path="/FacilityManagerAssetsList" element={<FacilityManagerAssetsList />} />} />
            <Route path="/companiesListPage" element={<ProtectedRoute path="/companiesListPage" element={<CompaniesListPage />} />} />
            <Route path="/facilityManagerWorklog" element={<ProtectedRoute path="/facilityManagerWorklog" element={<FacilityManagerWorkLog />} />} />
            {/* ================== Login ======================  */}



          </Routes>
        </Router>
      </div>
    </Provider>
  );
}

export default App;


